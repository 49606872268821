<template>
    <v-container
        fluid
        class="pa-4 mt-auto contact px-0 px-sm-4"
        :style="{
            backgroundImage: backgroundRender,
        }"
    >
        <v-card max-width="1200px" class="pa-0 mx-auto">
            <v-img
                height="30vh"
                src="../assets/bg.jpg"
                gradient="to bottom, rgba(255,255,255,.0), rgba(255,255,255,1)"
            >
            </v-img>
            <v-row class="pa-0">
                <v-col class="pa-0">
                    <v-card-title
                        primary-title
                        class="
                            justify-center
                            text-subtitle-1
                            text-md-h5
                            text-lg-h4
                            text-sm-h6
                            text-center
                        "
                    >
                        Vielen Dank!
                    </v-card-title>
                    <v-card-text class="pt-0 text-subtitle-1 text-center">
                        Deine Anfrage wurde erfolgreich verschickt. Das Studio
                        wird sich zeitnah bei Dir melden.
                    </v-card-text>
                </v-col>
            </v-row>
        </v-card>
        <v-container
            style="justify-content: center; display: flex"
            class="mt-4"
        >
            <HomeButtonComponent></HomeButtonComponent>
        </v-container>
    </v-container>
</template>

<script>
import HomeButtonComponent from '../components/configurator/home_button.vue';
export default {
    components: {
        HomeButtonComponent,
    },
    data: () => ({
        backgroundColor1: '',
        backgroundColor2: '',
        backgroundRender: '',
        colorArray1: [
            'rgba(255, 173, 173, .75)',
            'rgba(160, 196, 255, .75)',
            'rgba(255, 214, 165, .75)',
            'rgba(202, 255, 191, .75)',
            'rgba(105, 48, 195, .75)',
        ],
        colorArray2: [
            'rgba(253, 255, 182, .8)',
            'rgba(189, 178, 255, .8)',
            'rgba(155, 246, 255, .8)',
            'rgba(160, 196, 255, .8)',
            'rgba(255, 183, 3, .8)',
        ],
    }),

    methods: {},
    created() {
        this.backgroundColor1 = this.colorArray1[Math.floor(Math.random() * 5)];
        this.backgroundColor2 = this.colorArray2[Math.floor(Math.random() * 5)];
        this.backgroundRender =
            'linear-gradient(' +
            this.backgroundColor1 +
            ',' +
            this.backgroundColor2 +
            '), url(' +
            'https://bandrecording-hamburg.de/assets/backgrounds/' +
            Math.floor(Math.random() * 7 + 1) +
            '.jpg)';
    },
};
</script>

<!-- Design rules for the template. -->
<style scoped>
.contact {
    margin: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: top;
    background-position-x: center;
    width: 100%;
    min-height: 100vh;
}
</style>
