<template>
    <v-container
        fluid
        class="pa-0 ma-0"
        style="height: 75vh; max-width: 100%; position: fixed"
    >
        <v-row
            class="justify-center align-center ma-4"
            style="padding-top: 25vh"
        >
            <h1
                style="
                    z-index: 2;
                    text-align: center;
                    font-family: 'Nunito Sans', sans-serif !important;
                "
                class="text-h5 white--text text-uppercase text-sm-h2 font-weight-black"
            >
                <!--                Ursprünglicher Text. TODO: Besseren Text finden-->
                Deine individuelle Studioaufnahme

                <!--                Deutsche Ideen:-->
                <!--               Dein Bandrecording im Studiosound-->
                <!--                Deine Band im besten Sound-->
                <!--                Wir nehmen euch gerne auf-->
                <!--             Legendäre Studioaufnahmen für Deine Band-->

                <!--                Englische Ideen:-->
                <!--                Suit up your sound-->
                <!--               Record the s*** out of it-->
                <!--                Bring your sound to the next level-->
                <!--                Take your sound to the next stage-->
            </h1>
        </v-row>
        <v-row class="pa-0 mt-12 justify-center align-center">
            <v-col cols="auto" v-if="showReset">
                <ResetButtonComponent></ResetButtonComponent>
            </v-col>
            <v-col cols="auto">
                <v-btn
                    color="primary"
                    elevation="5"
                    x-large
                    to="/configurator"
                    class="mx-auto"
                >
                    {{ showReset ? 'Fortsetzen!' : 'Jetzt konfigurieren' }}
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import ResetButtonComponent from '../configurator/reset_button.vue';

export default {
    inject: ['recordingData'],
    components: { ResetButtonComponent },
    methods: {},
    computed: {
        showReset: function () {
            // shows the reset butoton if there is any data in recordingType
            return !this.recordingData.data.recordingType ? false : true;
        },
    },
};
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@800&display=swap');
</style>
