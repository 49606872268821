<template>
    <v-btn
        color="primary"
        outlined
        style="background-color: #fff"
        align="center"
        x-large
        @click="home()"
        elevation="5"
        class="mx-auto"
    >
        Zurück zu Bandrecording Hamburg
    </v-btn>
</template>

<script>
export default {
    inject: ['configuratorState', 'recordingData'],
    methods: {
        home() {
            // reset the configurator and go to the first page
            if (this.$route.path != '/') {
                this.$router.push('/');
            }
        },
    },
};
</script>

<style></style>
