<template>
    <v-app>
        <!-- width 100vw prevents the app bar flexbox from moving around in webkit browsers TODO: see if this does not break anything else-->
        <v-app-bar color="white" app width="100vw">
            <v-row>
                <v-col class="d-flex justify-start align-center">
                    <v-container fluid class="pa-0 ma-0">
                        <v-app-bar-nav-icon
                            @click="drawer = true"
                        ></v-app-bar-nav-icon>
                    </v-container>
                </v-col>
                <v-col class="d-flex justify-center align-center">
                    <v-toolbar-title class="text-center">
                        <v-container fluid class="pa-0 ma-0">
                            <router-link to="/">
                                <v-img
                                    lazy-src="@/assets/bandrec-hh-logo.svg"
                                    width="160"
                                    height="54"
                                    src="@/assets/bandrec-hh-logo.svg"
                                    class="hidden-xs-only"
                                ></v-img>
                                <v-img
                                    lazy-src="@/assets/bandrec-hh-logo-short.svg"
                                    width="80"
                                    height="48"
                                    src="@/assets/bandrec-hh-logo-short.svg"
                                    class="hidden-sm-and-up"
                                ></v-img>
                            </router-link>
                        </v-container>
                    </v-toolbar-title>
                </v-col>
                <v-col class="d-flex justify-end align-center">
                    <PriceComponent v-if="showPrice"></PriceComponent>
                </v-col>
            </v-row>
        </v-app-bar>
        <v-navigation-drawer v-model="drawer" fixed temporary>
            <v-list nav dense>
                <v-list-item
                    v-for="page in pages"
                    :key="page.title"
                    :to="page.to"
                >
                    <v-list-item-icon>
                        <v-icon>{{ page.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ page.title }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
        <v-main>
            <v-container
                fluid
                class="pa-0 ma-0"
                style="max-width: 100% !important"
            >
                <router-view></router-view>
                <FooterComponent></FooterComponent>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
import { extend } from 'vee-validate';
import { required, email, max, regex } from 'vee-validate/dist/rules';
import FooterComponent from './components/home/footer.vue';
import PriceComponent from './components/configurator/price.vue';
import configuratorState from '@/stateMgmt/configuratorState';
import recordingData from '@/stateMgmt/recordingData';
import feedbackData from '@/stateMgmt/feedbackData';
import configuratorLogic from '@/business_logic/configuratorLogic';

extend('required', {
    ...required,
    message: 'Dieses Feld darf nicht leer sein',
});

extend('max', {
    ...max,
    message: 'Nicht mehr als {length} Zeichen erlaubt',
});

extend('regex', {
    ...regex,
    message: '{_field_} {_value_} stimmt nicht mit {regex} überein',
});

extend('email', {
    ...email,
    message: 'Bitte gib eine gültige E-mail Adresse ein',
});

export default {
    name: 'App',
    metaInfo: {
        title: 'Bandrecording Hamburg - Deine bezahlbare Bandaufnahme',
        meta: [
            { charset: 'utf-8' },
            {
                name: 'description',
                content:
                    'Konfiguriere deine individuelle Bandaufnahme und erhalte sofort einen Preis. Dabei kannst du alle Optionen, von der Aufnahme über die Nachbearbeitung bis hin zum Master nach deinen Wünschen anpassen!',
            },
        ],
    },

    provide: {
        configuratorState: configuratorState,
        recordingData: recordingData,
        feedbackData: feedbackData,
        configuratorLogic: configuratorLogic,
    },
    components: {
        PriceComponent,
        FooterComponent,
    },
    data: () => ({
        drawer: false,
        group: null,
        pages: [
            { title: 'Home', icon: 'mdi-home', to: '/' },
            { title: 'Configurator', icon: 'mdi-tune', to: '/configurator' },
            { title: 'Impressum', icon: 'mdi-file-document', to: '/imprint' },
            { title: 'Datenschutz', icon: 'mdi-account-lock', to: '/privacy' },
            { title: 'Kontakt', icon: 'mdi-email', to: '/contact' },
        ],
    }),
    computed: {
        showPrice: function () {
            // checks if we are in the configurator and also not on the last page
            let currentRoute = this.$route.path;
            return (
                currentRoute === '/configurator' &&
                configuratorState.states.currentState !== 'confirmation' &&
                (configuratorState.states.currentState !== 'band' ||
                    configuratorState.states.showPrice) &&
                (configuratorState.states.currentState !== 'recordingtype' ||
                    configuratorState.states.showPrice) &&
                (configuratorState.states.currentState !== 'songs' ||
                    configuratorState.states.showPrice)
            );
        },
    },
};
</script>
<style lang="css">
.row {
    margin: 0 !important;
}
</style>
