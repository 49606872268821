import Vue from 'vue';

const defaultRecordingType = '';
const defaultMusicianID = 0;
const defaultNumberOfMusicians = 0;
const defaultSongID = 0;
const defaultNumberOfSongs = 0;
const defaultIsEdit = true;
const defaultEditQuality = 1;
const defaultMelodyne = false;
const defaultIsMix = true;
const defaultMixQuality = 1;
const defaultRevisions = 0;
const defaultIsMaster = true;
const defaultDdp = false;
const defaultIsStem = false;
const defaultStems = 0;
const defaultName = '';
const defaultEmail = '';
const defaultIsProducer = false;
const defaultIsNight = false;
const defaultRecordingPrice = 0;
const defaultPostPrice = 0;
const defaultNettoPrice = 0;
const defaultMwst = 0;
const defaultBruttoPrice = 0;
const defaultDiscount = 0;
const defaultDiscountPrice = 0;
const defaultRecordingTime = 0;
const defaultPostTime = 0;

const minMaxValues = {
    minRevisions: 1,
    maxRevisions: 3,
    minStems: 2,
    maxStems: 5,
};
const prices = Vue.observable({
    recordingPrice: defaultRecordingPrice,
    postPrice: defaultPostPrice,
    nettoPrice: defaultNettoPrice,
    mwst: defaultMwst,
    bruttoPrice: defaultBruttoPrice,
    discount: defaultDiscount,
    discountPrice: defaultDiscountPrice,
});

const data = Vue.observable({
    recordingType: defaultRecordingType,
    musicianID: defaultMusicianID,
    musicians: {}, //this is fucking hacky and I hate it. fucking vue not beeing able to react to maps...
    numberOfMusicians: defaultNumberOfMusicians,
    songID: defaultSongID,
    songs: {}, //has to be an object as well because the map only works because of the instruments function.
    numberOfSongs: defaultNumberOfSongs,

    // Post production:
    isEdit: defaultIsEdit,
    editQuality: defaultEditQuality,
    melodyne: defaultMelodyne,

    isMix: defaultIsMix,
    mixQuality: defaultMixQuality,
    revisions: defaultRevisions,

    isMaster: defaultIsMaster,
    ddp: defaultDdp,
    isStem: defaultIsStem,
    stems: defaultStems,

    // Additional Info
    name: defaultName,
    email: defaultEmail,

    // options
    isProducer: defaultIsProducer,
    isNight: defaultIsNight,

    recordingTime: defaultRecordingTime,
    postTime: defaultPostTime,
});

const instrumentDict = {
    Vocals: 'Gesang',
    AGuitar: 'Akustik-Gitarre',
    EGuitar: 'E-Gitarre',
    Bass: 'Bass',
    Drums: 'Schlagzeug',
    BackingVocals: 'Background-Gesang',
    Keys: 'Keys',
    Strings: 'Streicher',
    Horns: 'Bläser',
    Other: 'Andere',
};

const methods = {
    setLiveRecordingType() {
        data.recordingType = 'live';
    },

    setOverdubRecordingType() {
        data.recordingType = 'overdub';
    },

    addMusician(musician) {
        Vue.set(data.musicians, musician.id, musician);
    },

    filterSongInstruments() {
        if (Object.entries(data.songs).length > 0) {
            let viableInstruments = methods.createInstrumentArray();
            let tmp_instruments = [];
            for (let song of Object.entries(data.songs)) {
                tmp_instruments = song[1].instruments.filter(function (
                    instrument
                ) {
                    return viableInstruments.indexOf(instrument) != -1;
                });
                song[1].instruments = tmp_instruments;
                Vue.set(data.songs, song[1].id, song[1]);
            }
        }
    },

    removeMusician(id) {
        let idRegEx = new RegExp(id.toString() + '$', 'g');
        Vue.delete(data.musicians, id);
        // delete all the instruments that musician played from the songs
        // TODO: Fix this ugly shit. Vues fault
        if (Object.entries(data.songs).length > 0) {
            let tmp_instruments = [];
            for (let song of Object.entries(data.songs)) {
                tmp_instruments = song[1].instruments.filter(function (
                    instrument
                ) {
                    return instrument.match(idRegEx) == null;
                });
                song[1].instruments = tmp_instruments;
                Vue.set(data.songs, song[1].id, song[1]);
            }
        }
    },

    incrementMusicianID() {
        data.musicianID += 1;
    },

    getNumberOfMusicians() {
        return Object.entries(data.musicians).length;
    },

    getMusicianName(id) {
        return data.musicians[id].name;
    },

    addSong(song) {
        Vue.set(data.songs, song.id, song);
    },

    removeSong(id) {
        Vue.delete(data.songs, id);
    },

    incrementSongID() {
        data.songID += 1;
    },

    getNumberOfSongs() {
        return Object.entries(data.songs).length;
    },

    // these toggle functions are there so mix and or master are deselected when the previous steps are
    toggleEdit() {
        data.isEdit = !data.isEdit;
        if (!data.isEdit) {
            data.isMix = false;
            data.isMaster = false;
            data.melodyne = false;
            data.isStem = false;
            data.ddp = false;
        }
    },

    toggleMix() {
        data.isMix = !data.isMix;
        if (!data.isMix) {
            data.isMaster = false;
            data.isStem = false;
            data.ddp = false;
        }
    },

    toggleMaster() {
        data.isMaster = !data.isMaster;
        if (!data.isMaster) {
            data.isStem = false;
            data.ddp = false;
        }
    },

    toggleProducer() {
        data.isProducer = !data.isProducer;
    },

    toggleNight() {
        data.isNight = !data.isNight;
    },

    createInstrumentArray() {
        let bandInstruments = [];
        Object.entries(data.musicians).forEach((musician) => {
            musician[1].instruments.forEach((instrument) => {
                bandInstruments.push(instrument + ': ' + musician[1].id);
            });
        });
        return bandInstruments;
    },

    resetData() {
        data.recordingType = defaultRecordingType;
        data.musicianID = defaultMusicianID;
        data.musicians = {};
        data.numberOfMusicians = defaultNumberOfMusicians;
        data.defaultSongID = defaultSongID;
        data.songs = {};
        data.numberOfSongs = defaultNumberOfSongs;
        data.isEdit = defaultIsEdit;
        data.editQuality = defaultEditQuality;
        data.melodyne = defaultMelodyne;
        data.isMix = defaultIsMix;
        data.mixQuality = defaultMixQuality;
        data.revisions = defaultRevisions;
        data.isMaster = defaultIsMaster;
        data.ddp = defaultDdp;
        data.isStem = defaultIsStem;
        data.stems = defaultStems;
        data.name = defaultName;
        data.email = defaultEmail;
        data.isProducer = defaultIsProducer;
        data.isNight = defaultIsNight;
        prices.recordingPrice = defaultRecordingPrice;
        prices.postPrice = defaultPostPrice;
        prices.nettoPrice = defaultNettoPrice;
        prices.mwst = defaultMwst;
        prices.bruttoPrice = defaultBruttoPrice;
        prices.discount = defaultDiscount;
        prices.discountPrice = defaultDiscountPrice;
        data.recordingTime = defaultRecordingTime;
        data.postTime = defaultPostTime;
    },

    increaseRevisions() {
        data.revisions = Math.min(
            minMaxValues.maxRevisions,
            data.revisions + 1
        );
    },

    decreaseRevisions() {
        data.revisions = Math.max(
            minMaxValues.minRevisions,
            data.revisions - 1
        );
    },

    increaseStems() {
        data.stems = Math.min(minMaxValues.maxStems, data.stems + 1);
    },

    decreaseStems() {
        data.stems = Math.max(minMaxValues.minStems, data.stems - 1);
    },
};
export default {
    data: data,
    prices: prices,
    instrumentDict: instrumentDict,
    methods,
    minMaxValues,
};
