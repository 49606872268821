<template>
    <v-container class="pa-0 ma-0 d-flex flex-column" fluid>
        <video-background
            :poster="require('../assets/bg.jpg')"
            :src="require('../assets/bg_video1.mp4')"
            style="
                height: 100%;
                width: 177.77777778vh;
                min-width: 100%;
                min-height: 56.25vw;
                position: fixed;
                top: 0;
            "
            overlay="linear-gradient(180deg,rgba(237, 111, 0, 0.49), rgba(130, 0, 237, .5))"
        >
        </video-background>

        <v-container fluid class="pa-0 ma-0" style="height: 75vh; width: 100%">
        </v-container>
        <configure-component></configure-component>
        <info-component></info-component>
        <v-container class="pa-0 ma-0" style="height: 45vh; max-width: 100%">
        </v-container>
        <about-component></about-component>
    </v-container>
</template>

<script>
import ConfigureComponent from '../components/home/configure.vue';
import InfoComponent from '../components/home/info.vue';
import AboutComponent from '../components/home/about.vue';
export default {
    inject: ['recordingData'],
    data: () => ({
        home_boolean: true,
        name: 'Home',
        greeting: 'BANDRECORDING HAMBURG',
        sub: 'Deine bezahlbare Bandaufnahme',
        //videoSelect: this.random_bg().toString(),
    }),
    components: {
        ConfigureComponent,
        InfoComponent,
        AboutComponent,
    },
    methods: {},
    computed: {
        showReset: function () {
            // shows the reset butoton if there is any data in recordingType
            return !this.recordingData.data.recordingType ? false : true;
        },
    },
    created() {
        window.scrollTo(0, 0);
    },
};
</script>

<!-- Design rules for the template. -->
<style scoped></style>
