<template>
    <v-container fluid class="pa-4 my-auto">
        <v-container class="mx-auto" style="max-width: 800px">
            <v-card class="pa-0 mx-auto" style="background-color: #64b450">
                <v-img
                    height="30vh"
                    src="../../assets/bg.jpg"
                    gradient="to bottom, rgba(255,255,255,.0), rgba(100,180,80,1)"
                >
                </v-img>
                <v-row dense justify="space-around"
                    ><v-col cols="auto">
                        <div
                            class="text-h5 font-weight-bold"
                            style="color: white"
                        >
                            Vielen Dank für Dein Interesse an Bandrecording
                            Hamburg!
                        </div>
                    </v-col>
                </v-row>
            </v-card>

            <v-card class="pa-0 mx-auto" style="width: 100%">
                <v-simple-table class="mt-6">
                    <thead>
                        <tr>
                            <th>
                                <h4 class="title text-center">
                                    Wie geht es jetzt weiter?
                                </h4>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="ma-0 pa-0">
                            <td class="text-center pa-4">
                                <b
                                    >Du hast gerade eine E-Mail mit Deiner
                                    individuellen Konfiguration von uns
                                    erhalten.</b
                                ><br />
                                In der E-Mail kannst Du mit nur einem Klick bestätigen, dass unser Studio Deine unverbindliche Anfrage erhält.
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-card>
            <v-card
                v-if="!configuratorState.states.end"
                class="pa-0 mx-auto"
                style="width: 100%"
            >
                <v-simple-table class="mt-6">
                    <thead>
                        <tr>
                            <th>
                                <h4 class="title">
                                    Wir würden uns über Dein Feedback freuen!
                                </h4>
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                Wie zufrieden bist Du mit dem Konfigurator für
                                das Recording?
                            </td>
                            <td class="pa-0 pr-4" style="text-align: end">
                                <v-rating
                                    color="primary"
                                    dense
                                    hover
                                    v-model="feedbackData.data.general"
                                ></v-rating>
                            </td>
                        </tr>
                        <tr>
                            <td>Wie gefällt Dir das Design der Website?</td>
                            <td class="pa-0 pr-4" style="text-align: end">
                                <v-rating
                                    color="primary"
                                    dense
                                    hover
                                    v-model="feedbackData.data.design"
                                ></v-rating>
                            </td>
                        </tr>
                        <tr>
                            <td>Wie bewertest Du die Preise?</td>
                            <td class="pa-0 pr-4" style="text-align: end">
                                <v-rating
                                    color="primary"
                                    dense
                                    hover
                                    v-model="feedbackData.data.price"
                                ></v-rating>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Gibt es genügend Optionen zur Konfiguration?
                            </td>
                            <td class="pa-0 pr-4" style="text-align: end">
                                <v-radio-group
                                    v-model="feedbackData.data.options"
                                    row
                                    dense
                                    style="
                                        justify-content: end !important;
                                        text-align: end;
                                    "
                                >
                                    <v-radio label="Ja" value="1"></v-radio>
                                    <v-radio
                                        label="Nein"
                                        value="0"
                                        class="mr-0"
                                    ></v-radio>
                                </v-radio-group>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Wie können wir unseren Service weiter
                                verbessern?
                            </td>
                            <td class="pa-0 pr-4" style="text-align: end">
                                <v-textarea
                                    auto-grow
                                    dense
                                    placeholder="Lob, Kritik und Anregungen erwünscht"
                                    outlined
                                    class="mt-6"
                                    v-model="feedbackData.data.text"
                                ></v-textarea>
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-card>
            <v-card
                class="pa-0 mx-auto"
                style="width: 100%"
                v-if="configuratorState.states.end"
            >
                <v-simple-table class="mt-6">
                    <thead style="background-color: #64b450">
                        <tr>
                            <th>
                                <h4
                                    class="title text-center font-weight-bold"
                                    style="color: white"
                                >
                                    Danke für Dein Feedback!
                                </h4>
                            </th>
                        </tr>
                    </thead>
                </v-simple-table>
            </v-card>
            <v-row align="center" justify="center" class="mx-auto py-6">
                <v-col cols="auto" v-if="configuratorState.states.end">
                    <ResetButtonComponent></ResetButtonComponent>
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>

<script>
import ResetButtonComponent from './reset_button.vue';
export default {
    inject: ['configuratorState', 'feedbackData', 'recordingData'],
    props: {},
    data: () => ({}),
    components: { ResetButtonComponent },
    emits: ['navigate-to'],
    created() {
        this.$emit('change:background');
        window.scrollTo(0, 0);
    },
};
</script>

<style>
.v-input--radio-group--row .v-input--radio-group__input {
    justify-content: flex-end;
}
</style>
