<template>
    <v-container
        fluid
        class="pa-4 mt-auto contact px-0 px-sm-4"
        :style="{
            backgroundImage: backgroundRender,
        }"
    >
        <h1 class="text-center text-sm-h4 text-h5 mt-12 mb-8">
            Du hast Fragen? Schreib uns einfach...
        </h1>
        <validation-observer ref="observer" v-slot="{ invalid }">
            <form @submit.prevent="submit">
                <v-card
                    v-if="!messageSent"
                    class="pa-0 mx-auto"
                    max-width="800px"
                    min-width="250px"
                >
                    <v-img
                        height="30vh"
                        src="../assets/bg.jpg"
                        gradient="to bottom, rgba(255,255,255,.0), rgba(255,255,255,1)"
                    >
                    </v-img>
                    <v-row dense justify="space-between" class="pa-5"
                        ><v-col>
                            <validation-provider
                                v-slot="{ errors }"
                                name="Name"
                                rules="required|max:30"
                            >
                                <v-text-field
                                    v-model="name"
                                    :counter="30"
                                    :error-messages="errors"
                                    label="Name"
                                    required
                                ></v-text-field>
                            </validation-provider>
                            <validation-provider
                                v-slot="{ errors }"
                                name="email"
                                rules="required|email"
                            >
                                <v-text-field
                                    v-model="email"
                                    :error-messages="errors"
                                    label="E-mail"
                                    required
                                ></v-text-field>
                            </validation-provider>
                            <validation-provider
                                v-slot="{ errors }"
                                name="Kommentar"
                                rules="required|max:600"
                            >
                                <v-textarea
                                    v-model="text"
                                    :counter="600"
                                    :error-messages="errors"
                                    label="Kommentar"
                                    required
                                ></v-textarea>
                            </validation-provider>
                        </v-col>
                    </v-row>
                </v-card>
                <v-container
                    style="justify-content: center; display: flex"
                    class="mt-4"
                    v-if="!messageSent"
                >
                    <v-btn
                        color="primary"
                        type="submit"
                        large
                        :disabled="invalid"
                    >
                        Absenden
                    </v-btn>
                </v-container>
            </form>
            <v-card
                max-width="800px"
                min-width="250px"
                class="pa-0 mx-auto"
                v-if="messageSent"
            >
                <v-simple-table class="mt-6">
                    <thead style="background-color: #64b450">
                        <tr>
                            <th>
                                <h4
                                    class="title text-center font-weight-bold"
                                    style="color: white"
                                >
                                    Vielen Dank! Deine Nachricht wurde
                                    erfolgreich verschickt.
                                </h4>
                            </th>
                        </tr>
                    </thead>
                </v-simple-table>
            </v-card>
        </validation-observer>
        <v-container
            style="justify-content: center; display: flex"
            class="mt-4"
        >
            <HomeButtonComponent v-if="messageSent"></HomeButtonComponent>
        </v-container>
    </v-container>
</template>

<script>
import {
    ValidationObserver,
    ValidationProvider,
    setInteractionMode,
} from 'vee-validate';
import sendData from '../rest/sendData';

setInteractionMode('aggressive');
import HomeButtonComponent from '../components/configurator/home_button.vue';
export default {
    components: {
        HomeButtonComponent,
        ValidationProvider,
        ValidationObserver,
    },
    data: () => ({
        name: '',
        email: '',
        text: '',
        messageSent: false,
        backgroundColor1: '',
        backgroundColor2: '',
        backgroundRender: '',
        colorArray1: [
            'rgba(255, 173, 173, .75)',
            'rgba(160, 196, 255, .75)',
            'rgba(255, 214, 165, .75)',
            'rgba(202, 255, 191, .75)',
            'rgba(105, 48, 195, .75)',
        ],
        colorArray2: [
            'rgba(253, 255, 182, .8)',
            'rgba(189, 178, 255, .8)',
            'rgba(155, 246, 255, .8)',
            'rgba(160, 196, 255, .8)',
            'rgba(255, 183, 3, .8)',
        ],
    }),

    methods: {
        async submit() {
            this.$refs.observer.validate();
            let status = await sendData.methods.sendContactRequest(
                this.name,
                this.email,
                this.text
            );
            console.log(status);
            if (status) {
                this.messageSent = true;
                return true;
                // if message is not sent
            } else {
                return false;
            }
        },
        clear() {
            this.name = '';
            this.email = '';
            this.text = '';
            this.$refs.observer.reset();
        },
    },
    created() {
        this.backgroundColor1 = this.colorArray1[Math.floor(Math.random() * 5)];
        this.backgroundColor2 = this.colorArray2[Math.floor(Math.random() * 5)];
        this.backgroundRender =
            'linear-gradient(' +
            this.backgroundColor1 +
            ',' +
            this.backgroundColor2 +
            '), url(' +
            'https://bandrecording-hamburg.de/assets/backgrounds/' +
            Math.floor(Math.random() * 7 + 1) +
            '.jpg)';
    },
};
</script>

<!-- Design rules for the template. -->
<style scoped>
.contact {
    margin: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: top;
    background-position-x: center;
    width: 100%;
    min-height: 100vh;
}
</style>
