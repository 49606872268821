<template>
    <v-btn
        color="primary"
        outlined
        style="background-color: #fff"
        align="center"
        x-large
        @click="reset()"
        elevation="5"
        class="mx-auto"
    >
        Neu starten!
    </v-btn>
</template>

<script>
export default {
    inject: ['configuratorState', 'recordingData'],
    methods: {
        reset() {
            // reset the configurator and go to the first page
            this.recordingData.methods.resetData();
            this.configuratorState.methods.reset();
            if (this.$route.path != '/configurator') {
                this.$router.push('/configurator');
            }
        },
    },
};
</script>

<style></style>
