<template>
    <v-card
        class="mt-sm-0 mt-6"
        :width="$vuetify.breakpoint.xsOnly ? '100%' : '49%'"
    >
        <v-row
            style="cursor: pointer"
            @click="configuratorState.methods.setSongsState()"
        >
            <v-subheader class="title"
                >Songs
                <v-icon
                    right
                    class="mr-3"
                    style="cursor: pointer; right: 0; position: absolute"
                    >mdi-pencil</v-icon
                >
            </v-subheader>
        </v-row>
        <v-divider></v-divider>
        <v-row>
            <v-tabs v-model="tab" show-arrows>
                <v-tabs-slider color="primary"></v-tabs-slider>
                <v-tab v-for="song in recordingData.data.songs" :key="song.id">
                    {{ song.name }}
                </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
                <v-tab-item
                    v-for="song in recordingData.data.songs"
                    :key="song.id"
                >
                    <v-list disabled dense>
                        <v-list-item-group color="primary">
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon>mdi-music-note</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title
                                        >{{ song.name }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon>mdi-clock</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title
                                        >{{
                                            getLength(song.length)
                                        }}
                                        Minuten</v-list-item-title
                                    >
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon>mdi-guitar-acoustic</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title
                                        v-for="instrument in song.instruments"
                                        :key="instrument"
                                        >{{
                                            recordingData.instrumentDict[
                                                instrument.substring(
                                                    0,
                                                    instrument.length - 3
                                                )
                                            ]
                                        }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-tab-item>
            </v-tabs-items>
        </v-row>
    </v-card>
</template>

<script>
export default {
    inject: ['configuratorState', 'recordingData'],
    props: {},
    data: () => ({
        tab: null,
    }),
    methods: {
        getLength(length) {
            switch (length) {
                case 0:
                    return 'Bis zu 3';
                case 1:
                    return 'Zwischen 3 und 5';
                case 2:
                    return 'Über 5';
            }
        },
    },
};
</script>
