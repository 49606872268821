<template>
    <v-container fluid class="py-4 px-0 px-sm-4 my-auto">
        <v-card
            class="mb-2"
            style="margin: auto"
            min-width="250px"
            max-width="800px"
        >
            <v-card-title class="justify-center text-xs-h6 text-sm-h5">
                Dein Bandrecording im Überblick
            </v-card-title>
            <v-card-text class="text-justify text-subtitle-2">
                In der Übersicht kannst Du alle Deine Konfigurationen nochmal
                ansehen und überprüfen. Du hast hier auch die Möglichkeit, noch
                etwas an Deinem Recording zu ändern. Sollte etwas unklar sein,
                erhältst Du bei dem
                <v-icon color="primary" small>mdi-information-outline</v-icon>
                Hilfe.
            </v-card-text>
        </v-card>
        <v-container class="mx-auto px-0 px-sm-auto" style="max-width: 800px">
            <optionsSummary></optionsSummary>
            <priceSummary></priceSummary>

            <v-row class="mt-6" justify="space-between">
                <musicianSummary></musicianSummary>
                <songSummary></songSummary>
            </v-row>
            <recordingtypeSummary></recordingtypeSummary>
            <timeSummary></timeSummary>
            <postSummary></postSummary>
            <emailSummary></emailSummary>
        </v-container>
    </v-container>
</template>

<script>
import songSummary from './summary/songSummary.vue';
import musicianSummary from './summary/musicianSummary.vue';
import emailSummary from './summary/emailSummary.vue';
import optionsSummary from './summary/optionsSummary.vue';
import postSummary from './summary/postSummary.vue';
import priceSummary from './summary/priceSummary.vue';
import timeSummary from './summary/timeSummary.vue';
import recordingtypeSummary from './summary/recordingtypeSummary.vue';

export default {
    inject: ['configuratorState', 'recordingData'],
    props: {
        musician: Object,
        recording: Object,
        band: Object,
    },
    components: {
        songSummary,
        musicianSummary,
        emailSummary,
        optionsSummary,
        postSummary,
        priceSummary,
        timeSummary,
        recordingtypeSummary,
    },
    emits: ['navigate-to'],
    methods: {
        navigate_to(value) {
            this.$emit('navigate-to', value);
        },
    },
    created() {
        this.$emit('change:background');
        this.configuratorState.states.beenToSummary = true;
        window.scrollTo(0, 0);
    },
};
</script>

<style>
.summary {
    position: absolute;
    text-align: left;
    bottom: 15%;
    left: 2%;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    border-radius: 2px;
    -webkit-box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.4);
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.4);
    min-width: 18%;
}

.summary ul {
    font-weight: 100;
}

.summary ul li {
    text-transform: capitalize;
    font-size: 0.8vw;
    cursor: pointer;
    margin: 0.3%;
    padding: 0 0.5% 0 2%;
}

.summary ul li:hover {
    background: rgba(255, 116, 0, 0.6);
    border-radius: 2px;
    padding: 0 0.5% 0 2%;
}
.active_chip {
    color: white !important;
    background-color: #f8d763;
}
</style>
