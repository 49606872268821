<template>
    <v-card :width="$vuetify.breakpoint.xsOnly ? '100%' : '49%'">
        <v-row
            style="cursor: pointer"
            @click="configuratorState.methods.setBandState()"
        >
            <v-subheader class="title"
                >Band
                <v-icon
                    right
                    class="mr-3"
                    style="cursor: pointer; right: 0; position: absolute"
                    >mdi-pencil</v-icon
                >
            </v-subheader>
        </v-row>
        <v-divider> </v-divider>
        <v-row>
            <v-tabs v-model="tab" show-arrows>
                <v-tabs-slider color="primary"></v-tabs-slider>
                <v-tab
                    v-for="musician in recordingData.data.musicians"
                    :key="musician.id"
                >
                    {{ musician.name }}
                </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
                <v-tab-item
                    v-for="musician in recordingData.data.musicians"
                    :key="musician.id"
                >
                    <v-list disabled dense>
                        <v-list-item-group color="secondary">
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon>mdi-human-greeting-variant</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title
                                        >{{ musician.name }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon>mdi-chart-line-variant</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title
                                        >{{
                                            getExperience(musician.experience)
                                        }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon>mdi-guitar-acoustic</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title
                                        v-for="instrument in musician.instruments"
                                        :key="instrument"
                                    >
                                        {{
                                            recordingData.instrumentDict[
                                                instrument
                                            ]
                                        }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-tab-item>
            </v-tabs-items>
        </v-row>
    </v-card>
</template>
<script>
export default {
    inject: ['configuratorState', 'recordingData'],
    props: {},
    data: () => ({
        tab: null,
    }),
    methods: {
        getExperience(experience) {
            switch (experience) {
                case 0:
                    return 'Wenig Studioerfahrung';
                case 1:
                    return 'Ein bisschen Studioerfahrung';
                case 2:
                    return 'Viel Studioerfahrung';
            }
        },
    },
};
</script>
