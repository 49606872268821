var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"configurator",style:({
        backgroundImage: _vm.backgroundRender,
    })},[_c('ValidationObserver',{ref:"form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onNext.apply(null, arguments)}}},[_c('div',{staticStyle:{"padding-top":"3vh"}},[_c('transition',{attrs:{"name":_vm.configuratorState.states.goBack
                            ? 'component-fade-reverse'
                            : 'component-fade',"mode":"out-in"}},[(
                            _vm.configuratorState.states.currentState ===
                            'recordingtype'
                        )?_c('RecordingTypeComponent',{on:{"change:background":_vm.changeBackground}}):_vm._e(),(
                            _vm.configuratorState.states.currentState === 'band'
                        )?_c('BandComponent',{on:{"change:background":_vm.changeBackground}}):_vm._e(),(
                            _vm.configuratorState.states.currentState ===
                            'songs'
                        )?_c('SongsComponent',{on:{"change:background":_vm.changeBackground}}):_vm._e(),(
                            _vm.configuratorState.states.currentState === 'post'
                        )?_c('PostComponent',{on:{"change:background":_vm.changeBackground}}):_vm._e(),(
                            _vm.configuratorState.states.currentState ===
                            'summary'
                        )?_c('SummaryComponent',{on:{"change:background":_vm.changeBackground}}):_vm._e(),(
                            _vm.configuratorState.states.currentState ===
                            'confirmation'
                        )?_c('CustomerFeedbackComponent',{on:{"change:background":_vm.changeBackground}}):_vm._e()],1),_c('v-row',{staticClass:"pb-12",attrs:{"align":"center","justify":"center"}},[(_vm.showBack)?_c('v-col',{attrs:{"cols":"auto"}},[_c('BackButtonComponent',{on:{"validateBack":function($event){return _vm.onBack()}}})],1):_vm._e(),(_vm.showSummary && _vm.$vuetify.breakpoint.smAndUp)?_c('v-col',{attrs:{"cols":"auto"}},[_c('SummaryButtonComponent',{on:{"validateSummary":function($event){return _vm.onSummary()}}})],1):_vm._e(),(_vm.showNext)?_c('v-col',{attrs:{"cols":"auto"}},[_c('NextButtonComponent',{attrs:{"disabled":_vm.disableNext},on:{"validateNext":function($event){return _vm.onNext()}}})],1):_vm._e(),(_vm.showSummary && _vm.$vuetify.breakpoint.xsOnly)?_c('v-col',{attrs:{"cols":"auto"}},[_c('SummaryButtonComponent',{on:{"validateSummary":function($event){return _vm.onSummary()}}})],1):_vm._e()],1)],1),_c('v-snackbar',{staticClass:"text-body-2 pa-0",staticStyle:{"padding":"0"},attrs:{"timeout":_vm.timeoutErrorMsg,"max-width":"800px"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
                        var attrs = ref.attrs;
return [_c('v-icon',_vm._b({attrs:{"color":"primary"},on:{"click":function($event){_vm.showMailError = false}}},'v-icon',attrs,false),[_vm._v("mdi-close")])]}}]),model:{value:(_vm.showMailError),callback:function ($$v) {_vm.showMailError=$$v},expression:"showMailError"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]),_c('v-snackbar',{staticClass:"pa-0 progressBar",staticStyle:{"padding":"0","opacity":"0.9"},attrs:{"timeout":_vm.timeoutProgressBar,"width":"60vw","max-width":"500px","min-height":"0"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
                        var attrs = ref.attrs;
return [_c('v-icon',_vm._b({attrs:{"color":"primary"},on:{"click":function($event){_vm.showProgressBar = false}}},'v-icon',attrs,false),[_vm._v("mdi-close")])]}}]),model:{value:(_vm.showProgressBar),callback:function ($$v) {_vm.showProgressBar=$$v},expression:"showProgressBar"}},[_c('v-progress-linear',{staticClass:"pa-0",attrs:{"value":_vm.getConfiguratorProgress,"color":"primary","height":"8","striped":""},on:{"click":function($event){_vm.showProgressBar = false}}})],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }