var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"py-4 px-0 px-sm-4 my-auto",attrs:{"fluid":""}},[_c('v-card',{staticStyle:{"margin":"auto"},attrs:{"dir":"","min-width":"250px","max-width":"730px"}},[_c('v-card-title',{staticClass:"justify-center text-h5"},[_vm._v("Art der Aufnahme")]),_c('v-card-text',{staticClass:"text-left subtitle-2"},[_vm._v("Im ersten Schritt gilt es die Art der Aufnahme auszuwählen. Sie bestimmt das Vorgehen der gesamten Produktion. Überlege also gut, welche Variante am Besten zu Eurem Bandrecording passt. ")])],1),_c('v-container',{staticClass:"mx-auto px-0 px-sm-4"},[_c('v-row',{attrs:{"align":"center","justify":"center"}},_vm._l((_vm.types),function(type,index){return _c('v-col',{key:index,staticClass:"px-0 px-sm-3",attrs:{"cols":"auto"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"pa-0 mx-auto white",class:(type.name === _vm.recordingData.data.recordingType
                                ? 'selection'
                                : 'white') +
                            (index < 1
                                ? ' ml-sm-auto mr-sm-1 mx-auto'
                                : ' mr-sm-auto ml-sm-1 mx-auto'),attrs:{"elevation":type.name === _vm.recordingData.data.recordingType
                                ? '24'
                                : hover
                                ? '12'
                                : '1',"min-width":"250px","max-width":"350px"},on:{"click":function($event){return _vm.setRecordingType(type.name)}}},[_c('v-img',{attrs:{"height":"300px","src":type.picture,"gradient":"to bottom, rgba(255,255,255,.0), rgba(255,255,255,1) "}},[_c('v-app-bar',{attrs:{"flat":"","color":"rgba(0, 0, 0, 0)"}},[_c('div',{class:type.name ===
                                        _vm.recordingData.data.recordingType
                                            ? 'primary'
                                            : 'white'})])],1),_c('v-subheader',[_c('span',{staticClass:"text-h5 pt-4 d-inline-block"},[_vm._v(_vm._s(type.name)+" Recording ")])]),_c('v-card-text',[_c('span',[_vm._v(" "+_vm._s(type.description)+" ")])]),_c('v-row',{staticClass:"py-4",attrs:{"align":"center","justify":"center"}},[_c('v-btn',{staticClass:"mx-auto",attrs:{"color":"primary","elevation":"0"}},[_vm._v(" Auswählen ")])],1)],1)]}}],null,true)})],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }