import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#ED6F00',
                secondary: '#8200ED',
                accent: '#c85600',

                bronze_bg: '#e8ccbf',
                bronze_fg: '#BB9893',

                silber_bg: '#ebebeb',
                silber_fg: '#767FAD',

                gold_bg: '#f8eec2',
                gold_fg: '#FFBF66',
                selection: '#ffbf66',
                disabled: '#ccc',
            },
        },
    },
});
