<template>
    <v-form class="mt-6">
        <v-card
            class="pa-7 mx-auto"
            style="padding-left: 100px; padding-right: 100px"
        >
            <v-row align="center" justify="center">
                <v-subheader text-center style="height: 100%">
                    <h4 class="subheading" style="text-align: left">
                        Wenn alle Einstellungen Deinen Wünschen entsprechen, nenne uns zu guter Letzt noch Deinen Namen und Deine E-Mail-Adresse.
                        Nach Absenden erhältst Du eine automatische E-Mail mit Deiner individuellen Konfiguration von uns.
                        In dieser E-Mail kannst Du die Konfiguration mit nur einem Klick bestätigen und somit eine unverbindliche Anfrage an das Studio schicken.
                        Wir werden uns anschließend zeitnah bei Dir zurückmelden.
                    </h4></v-subheader
                >
            </v-row>
            <v-row align="center" justify="center">
                <v-col cols="12" md="4">
                    <ValidationProvider
                        :rules="{
                            required: true,
                        }"
                        v-slot="{ errors }"
                    >
                        <v-text-field
                            v-model="recordingData.data.name"
                            label="Name"
                            maxlength="30"
                            :counter=30
                            placeholder="Gib Deinen Namen ein"
                            :error-messages="errors[0]"
                        ></v-text-field>
                    </ValidationProvider>
                </v-col>

                <v-col cols="12" md="4">
                    <ValidationProvider
                        :rules="{
                            required: true,
                            email: true,
                        }"
                        v-slot="{ errors }"
                    >
                        <v-text-field
                            v-model="recordingData.data.email"
                            label="E-Mail"
                            placeholder="Gib Deine E-Mail ein"
                            :error-messages="errors[0]"
                        ></v-text-field>
                    </ValidationProvider>
                </v-col>
            </v-row>
        </v-card>
    </v-form>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
    inject: ['configuratorState', 'recordingData'],
    components: {
        ValidationProvider,
    },
};
</script>
