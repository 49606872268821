<template>
    <v-card class="pa-0 mx-auto mt-6" style="width: 100%">
        <v-card-title
            @click="configuratorState.methods.setRecordingtypeState()"
            style="cursor: pointer"
            class="py-2"
        >
            <h4 class="title text-h6" style="color: #666666">
                Art der Aufnahme
            </h4>
            <v-icon
                right
                class="mr-3"
                style="cursor: pointer; right: 0; position: absolute"
                >mdi-pencil</v-icon
            >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="py-2 text-subtitle-1">
            {{ recordingData.data.recordingType }}
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    inject: ['configuratorState', 'recordingData'],
};
</script>
