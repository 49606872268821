<template>
    <v-container
        fluid
        class="pa-4 mt-auto contact"
        :style="{
            backgroundImage: backgroundRender,
        }"
    >
        <v-card class="pa-4 mx-auto" max-width="800px" width="80%">
            <h2>Datenschutzerklärung</h2>
            <p>
                Verantwortliche Stelle im Sinne der Datenschutzgesetze,
                insbesondere der EU-Datenschutzgrundverordnung (DSGVO), ist:
            </p>
            <p>
                Klaukien, Oechsle GbR
                <br />Conventstr. 8-10 <br />22089 Hamburg
            </p>
            <h3>Ihre Betroffenenrechte</h3>
            <p>
                Unter den angegebenen Kontaktdaten unseres
                Datenschutzbeauftragten können Sie jederzeit folgende Rechte
                ausüben:
            </p>
            <ul>
                <li>
                    Auskunft über Ihre bei uns gespeicherten Daten und deren
                    Verarbeitung,
                </li>
                <li>Berichtigung unrichtiger personenbezogener Daten,</li>
                <li>Löschung Ihrer bei uns gespeicherten Daten,</li>
                <li>
                    Einschränkung der Datenverarbeitung, sofern wir Ihre Daten
                    aufgrund gesetzlicher Pflichten noch nicht löschen dürfen,
                </li>
                <li>
                    Widerspruch gegen die Verarbeitung Ihrer Daten bei uns und
                </li>
                <li>
                    Datenübertragbarkeit, sofern Sie in die Datenverarbeitung
                    eingewilligt haben oder einen Vertrag mit uns abgeschlossen
                    haben.
                </li>
            </ul>
            <p>
                Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese
                jederzeit mit Wirkung für die Zukunft widerrufen.
            </p>
            <p>
                Sie können sich jederzeit mit einer Beschwerde an die für Sie
                zuständige Aufsichtsbehörde wenden. Ihre zuständige
                Aufsichtsbehörde richtet sich nach dem Bundesland Ihres
                Wohnsitzes, Ihrer Arbeit oder der mutmaßlichen Verletzung. Eine
                Liste der Aufsichtsbehörden (für den nichtöffentlichen Bereich)
                mit Anschrift finden Sie unter:
                <a
                    href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
                    target="_blank"
                    rel="noopener"
                    >https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</a
                >.
            </p>
            <h3>
                Zwecke der Datenverarbeitung durch die verantwortliche Stelle
                und Dritte
            </h3>
            <p>
                Wir verarbeiten Ihre personenbezogenen Daten nur zu den in
                dieser Datenschutzerklärung genannten Zwecken. Eine Übermittlung
                Ihrer persönlichen Daten an Dritte zu anderen als den genannten
                Zwecken findet nicht statt. Wir geben Ihre persönlichen Daten
                nur an Dritte weiter, wenn:
            </p>
            <ul>
                <li>Sie Ihre ausdrückliche Einwilligung dazu erteilt haben,</li>
                <li>
                    die Verarbeitung zur Abwicklung eines Vertrags mit Ihnen
                    erforderlich ist,
                </li>
                <li>
                    die Verarbeitung zur Erfüllung einer rechtlichen
                    Verpflichtung erforderlich ist,
                </li>
            </ul>
            <p>
                die Verarbeitung zur Wahrung berechtigter Interessen
                erforderlich ist und kein Grund zur Annahme besteht, dass Sie
                ein überwiegendes schutzwürdiges Interesse an der
                Nichtweitergabe Ihrer Daten haben.
            </p>
            <h3>
                Erfassung allgemeiner Informationen beim Besuch unserer Website
            </h3>
            <p>
                Wenn Sie auf unsere Website zugreifen, werden automatisch
                mittels eines Cookies Informationen allgemeiner Natur erfasst.
                Diese Informationen (Server-Logfiles) beinhalten etwa die Art
                des Webbrowsers, das verwendete Betriebssystem, den Domainnamen
                Ihres Internet-Service-Providers und ähnliches. Hierbei handelt
                es sich ausschließlich um Informationen, welche keine
                Rückschlüsse auf Ihre Person zulassen.
            </p>
            <p>
                Diese Informationen sind technisch notwendig, um von Ihnen
                angeforderte Inhalte von Webseiten korrekt auszuliefern und
                fallen bei Nutzung des Internets zwingend an. Sie werden
                insbesondere zu folgenden Zwecken verarbeitet:
            </p>
            <ul>
                <li>
                    Sicherstellung eines problemlosen Verbindungsaufbaus der
                    Website,
                </li>
                <li>
                    Sicherstellung einer reibungslosen Nutzung unserer Website,
                </li>
                <li>Auswertung der Systemsicherheit und -stabilität sowie</li>
                <li>zu weiteren administrativen Zwecken.</li>
            </ul>
            <p>
                Die Verarbeitung Ihrer personenbezogenen Daten basiert auf
                unserem berechtigten Interesse aus den vorgenannten Zwecken zur
                Datenerhebung. Wir verwenden Ihre Daten nicht, um Rückschlüsse
                auf Ihre Person zu ziehen. Empfänger der Daten sind nur die
                verantwortliche Stelle und ggf. Auftragsverarbeiter.
            </p>
            <p>
                Anonyme Informationen dieser Art werden von uns ggfs.
                statistisch ausgewertet, um unseren Internetauftritt und die
                dahinterstehende Technik zu optimieren.
            </p>
            <h3>Cookies</h3>
            <p>
                Wie viele andere Webseiten verwenden wir auch so genannte
                „Cookies“. Cookies sind kleine Textdateien, die von einem
                Websiteserver auf Ihre Festplatte übertragen werden. Hierdurch
                erhalten wir automatisch bestimmte Daten wie z. B. IP-Adresse,
                verwendeter Browser, Betriebssystem und Ihre Verbindung zum
                Internet.
            </p>
            <p>
                Cookies können nicht verwendet werden, um Programme zu starten
                oder Viren auf einen Computer zu übertragen. Anhand der in
                Cookies enthaltenen Informationen können wir Ihnen die
                Navigation erleichtern und die korrekte Anzeige unserer
                Webseiten ermöglichen.
            </p>
            <p>
                In keinem Fall werden die von uns erfassten Daten an Dritte
                weitergegeben oder ohne Ihre Einwilligung eine Verknüpfung mit
                personenbezogenen Daten hergestellt.
            </p>
            <p>
                Natürlich können Sie unsere Website grundsätzlich auch ohne
                Cookies betrachten. Internet-Browser sind regelmäßig so
                eingestellt, dass sie Cookies akzeptieren. Im Allgemeinen können
                Sie die Verwendung von Cookies jederzeit über die Einstellungen
                Ihres Browsers deaktivieren. Bitte verwenden Sie die
                Hilfefunktionen Ihres Internetbrowsers, um zu erfahren, wie Sie
                diese Einstellungen ändern können. Bitte beachten Sie, dass
                einzelne Funktionen unserer Website möglicherweise nicht
                funktionieren, wenn Sie die Verwendung von Cookies deaktiviert
                haben.
            </p>
            <h3>SSL-Verschlüsselung</h3>
            <p>
                Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen,
                verwenden wir dem aktuellen Stand der Technik entsprechende
                Verschlüsselungsverfahren (z. B. SSL) über HTTPS.
            </p>
            <h3>Kontaktformular</h3>
            <p>
                Treten Sie bzgl. Fragen jeglicher Art per E-Mail oder
                Kontaktformular mit uns in Kontakt, erteilen Sie uns zum Zwecke
                der Kontaktaufnahme Ihre freiwillige Einwilligung. Hierfür ist
                die Angabe einer validen E-Mail-Adresse erforderlich. Diese
                dient der Zuordnung der Anfrage und der anschließenden
                Beantwortung derselben. Die Angabe weiterer Daten ist optional.
                Die von Ihnen gemachten Angaben werden zum Zwecke der
                Bearbeitung der Anfrage sowie für mögliche Anschlussfragen
                gespeichert. Nach Erledigung der von Ihnen gestellten Anfrage
                werden personenbezogene Daten automatisch gelöscht.
            </p>
            <h3>Verwendung von Google Analytics</h3>
            <p>
                Diese Website benutzt Google Analytics, einen Webanalysedienst
                der Google Inc. (folgend: Google). Google Analytics verwendet
                sog. „Cookies“, also Textdateien, die auf Ihrem Computer
                gespeichert werden und die eine Analyse der Benutzung der
                Webseite durch Sie ermöglichen. Die durch das Cookie erzeugten
                Informationen über Ihre Benutzung dieser Webseite werden in der
                Regel an einen Server von Google in den USA übertragen und dort
                gespeichert. Aufgrund der Aktivierung der IP-Anonymisierung auf
                diesen Webseiten, wird Ihre IP-Adresse von Google jedoch
                innerhalb von Mitgliedstaaten der Europäischen Union oder in
                anderen Vertragsstaaten des Abkommens über den Europäischen
                Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die
                volle IP-Adresse an einen Server von Google in den USA
                übertragen und dort gekürzt. Im Auftrag des Betreibers dieser
                Website wird Google diese Informationen benutzen, um Ihre
                Nutzung der Webseite auszuwerten, um Reports über die
                Webseitenaktivitäten zusammenzustellen und um weitere mit der
                Websitenutzung und der Internetnutzung verbundene
                Dienstleistungen gegenüber dem Webseitenbetreiber zu erbringen.
                Die im Rahmen von Google Analytics von Ihrem Browser
                übermittelte IP-Adresse wird nicht mit anderen Daten von Google
                zusammengeführt.
            </p>
            <p>
                Die Zwecke der Datenverarbeitung liegen in der Auswertung der
                Nutzung der Website und in der Zusammenstellung von Reports über
                Aktivitäten auf der Website. Auf Grundlage der Nutzung der
                Website und des Internets sollen dann weitere verbundene
                Dienstleistungen erbracht werden. Die Verarbeitung beruht auf
                dem berechtigten Interesse des Webseitenbetreibers.
            </p>
            <p>
                Sie können die Speicherung der Cookies durch eine entsprechende
                Einstellung Ihrer Browser-Software verhindern; wir weisen Sie
                jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht
                sämtliche Funktionen dieser Website vollumfänglich werden nutzen
                können. Sie können darüber hinaus die Erfassung der durch das
                Cookie erzeugten und auf Ihre Nutzung der Webseite bezogenen
                Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung
                dieser Daten durch Google verhindern, indem sie das unter dem
                folgenden Link verfügbare Browser-Plugin herunterladen und
                installieren:
                <a
                    href="http://tools.google.com/dlpage/gaoptout?hl=de"
                    target="_blank"
                    rel="noopener"
                    >Browser Add On zur Deaktivierung von Google Analytics</a
                >.
            </p>
            <h3>Verwendung von Scriptbibliotheken (Google Webfonts)</h3>
            <p>
                Um unsere Inhalte browserübergreifend korrekt und grafisch
                ansprechend darzustellen, verwenden wir auf dieser Website
                Scriptbibliotheken und Schriftbibliotheken wie z. B. Google
                Webfonts (<a
                    href="http://www.google.com/webfonts/"
                    target="_blank"
                    rel="noopener"
                    >https://www.google.com/webfonts/</a
                >). Google Webfonts werden zur Vermeidung mehrfachen Ladens in
                den Cache Ihres Browsers übertragen. Falls der Browser die
                Google Webfonts nicht unterstützt oder den Zugriff unterbindet,
                werden Inhalte in einer Standardschrift angezeigt.
            </p>
            <p>
                Der Aufruf von Scriptbibliotheken oder Schriftbibliotheken löst
                automatisch eine Verbindung zum Betreiber der Bibliothek aus.
                Dabei ist es theoretisch möglich – aktuell allerdings auch
                unklar ob und ggf. zu welchen Zwecken – dass Betreiber
                entsprechender Bibliotheken Daten erheben.
            </p>
            <p>
                Die Datenschutzrichtlinie des Bibliothekbetreibers Google finden
                Sie hier:
                <a
                    href="https://www.google.com/policies/privacy/"
                    target="_blank"
                    rel="noopener"
                    >https://www.google.com/policies/privacy/</a
                >
            </p>
            <h3>Verwendung von Adobe Typekit</h3>
            <p>
                Wir setzen Adobe Typekit zur visuellen Gestaltung unserer
                Website ein. Typekit ist ein Dienst der Adobe Systems Software
                Ireland Ltd. der uns den Zugriff auf eine Schriftartenbibliothek
                gewährt. Zur Einbindung der von uns benutzten Schriftarten, muss
                Ihr Browser eine Verbindung zu einem Server von Adobe in den USA
                aufbauen und die für unsere Website benötigte Schriftart
                herunterladen. Adobe erhält hierdurch die Information, dass von
                Ihrer IP-Adresse unsere Website aufgerufen wurde. Weitere
                Informationen zu Adobe Typekit finden Sie in den
                Datenschutzhinweisen von Adobe, die Sie hier abrufen können:
                <a
                    href="http://www.adobe.com/privacy/typekit.html"
                    target="_blank"
                    rel="noopener"
                    >www.adobe.com/privacy/typekit.html</a
                >
            </p>
            <h3><strong>Änderung unserer Datenschutzbestimmungen</strong></h3>
            <p>
                Wir behalten uns vor, diese Datenschutzerklärung anzupassen,
                damit sie stets den aktuellen rechtlichen Anforderungen
                entspricht oder um Änderungen unserer Leistungen in der
                Datenschutzerklärung umzusetzen, z.B. bei der Einführung neuer
                Services. Für Ihren erneuten Besuch gilt dann die neue
                Datenschutzerklärung.
            </p>
            <h3><strong>Fragen an den Datenschutzbeauftragten</strong></h3>
            <p>
                Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte
                eine E-Mail.
            </p>
            <p>
                <em>Die Datenschutzerklärung wurde mit dem </em
                ><a
                    href="https://www.activemind.de/datenschutz/datenschutzhinweis-generator/"
                    target="_blank"
                    rel="noopener"
                    ><em
                        >Datenschutzerklärungs-Generator der activeMind AG
                        erstellt</em
                    ></a
                ><em>.</em>
            </p>
        </v-card>
    </v-container>
</template>

<script>
export default {
    data: () => ({
        backgroundColor1: '',
        backgroundColor2: '',
        backgroundRender: '',
        colorArray1: [
            'rgba(255, 173, 173, .75)',
            'rgba(160, 196, 255, .75)',
            'rgba(255, 214, 165, .75)',
            'rgba(202, 255, 191, .75)',
            'rgba(105, 48, 195, .75)',
        ],
        colorArray2: [
            'rgba(253, 255, 182, .8)',
            'rgba(189, 178, 255, .8)',
            'rgba(155, 246, 255, .8)',
            'rgba(160, 196, 255, .8)',
            'rgba(255, 183, 3, .8)',
        ],
    }),

    created() {
        this.backgroundColor1 = this.colorArray1[Math.floor(Math.random() * 5)];
        this.backgroundColor2 = this.colorArray2[Math.floor(Math.random() * 5)];
        this.backgroundRender =
            'linear-gradient(' +
            this.backgroundColor1 +
            ',' +
            this.backgroundColor2 +
            '), url(' +
            'https://bandrecording-hamburg.de/assets/backgrounds/' +
            Math.floor(Math.random() * 7 + 1) +
            '.jpg)';
    },
};
</script>

<!-- Design rules for the template. -->
<style scoped>
.contact {
    margin: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: top;
    background-position-x: center;
    width: 100%;
    min-height: 100vh;
}
</style>
