// Times (hours)
const data = {
    aufbauBasisZeit: 1.0,
    aufbauDrums: 3.5,
    aufbauBass: 0.5,
    aufbauEGuitar: 1.0,
    aufbauAGuitar: 0.5,
    aufbauKeys: 0.5,
    aufbauKeyboard: 0.25,
    aufbauVocals: 0.25,
    aufbauBackingVocals: 0.25,
    aufbauHorns: 0.25,
    aufbauStrings: 0.5,
    aufbauOther: 0.5,
    recordingDrumsLowXP: 2,
    recordingBassLowXP: 1.0,
    recordingEGuitarLowXP: 2.5,
    recordingAGuitarLowXP: 1.25,
    recordingKeysLowXP: 1.25,
    recordingKeyboardLowXP: 1.25,
    recordingVocalsLowXP: 2.25,
    recordingBackingVocalsLowXP: 0.6,
    recordingHornsLowXP: 1.25,
    recordingStringsLowXP: 1.25,
    recordingOtherLowXP: 1.56,
    recordingDrumsMediumXP: 1.5,
    recordingBassMediumXP: 1.0,
    recordingEGuitarMediumXP: 2,
    recordingAGuitarMediumXP: 1.0,
    recordingKeysMediumXP: 1.0,
    recordingKeyboardMediumXP: 1.0,
    recordingVocalsMediumXP: 1.5,
    recordingBackingVocalsMediumXP: 0.5,
    recordingHornsMediumXP: 1.0,
    recordingStringsMediumXP: 1.0,
    recordingOtherMediumXP: 1.22,
    recordingDrumsHighXP: 1.0,
    recordingBassHighXP: 0.75,
    recordingEGuitarHighXP: 1.25,
    recordingAGuitarHighXP: 0.75,
    recordingKeysHighXP: 0.75,
    recordingKeyboardHighXP: 0.75,
    recordingVocalsHighXP: 1.25,
    recordingBackingVocalsHighXP: 0.4,
    recordingHornsHighXP: 0.75,
    recordingStringsHighXP: 0.75,
    recordingOtherHighXP: 0.89,
    editDrumsOverdub: 0.8,
    editBassOverdub: 0.3,
    editEGuitarOverdub: 0.5,
    editAGuitarOverdub: 0.25,
    editKeysOverdub: 0.25,
    editKeyboardOverdub: 0.25,
    editVocalsOverdub: 0.6,
    editBackingVocalsOverdub: 0.3,
    editHornsOverdub: 0.25,
    editStringsOverdub: 0.25,
    editOtherOverdub: 0.38,
    mixBasisZeitOverdub: 1.0,
    mixDrumsOverdub: 2.5,
    mixBassOverdub: 0.5,
    mixEGuitarOverdub: 1.5,
    mixAGuitarOverdub: 1.0,
    mixKeysOverdub: 1.0,
    mixKeyboardOverdub: 1.0,
    mixVocalsOverdub: 1.5,
    mixBackingVocalsOverdub: 0.5,
    mixHornsOverdub: 0.5,
    mixStringsOverdub: 0.5,
    mixOtherOverdub: 1.11,
    editDrumsLive: 0.5,
    editBassLive: 0.25,
    editEGuitarLive: 0.33,
    editAGuitarLive: 0.25,
    editKeysLive: 0.25,
    editKeyboardLive: 0.25,
    editVocalsLive: 0.5,
    editBackingVocalsLive: 0.2,
    editHornsLive: 0.25,
    editStringsLive: 0.25,
    editOtherLive: 0.31,
    mixBasisZeitLive: 1.0,
    mixDrumsLive: 2,
    mixBassLive: 0.5,
    mixEGuitarLive: 1.0,
    mixAGuitarLive: 0.8,
    mixKeysLive: 0.66,
    mixKeyboardLive: 0.5,
    mixVocalsLive: 1.5,
    mixBackingVocalsLive: 0.3,
    mixHornsLive: 0.5,
    mixStringsLive: 0.5,
    mixOtherLive: 0.88,
    recordingShortLowXP: 1.8,
    recordingNormalLowXP: 2,
    recordingLongLowXP: 2.35,
    recordingShortMediumXP: 0.8,
    recordingNormalMediumXP: 1.0,
    recordingLongMediumXP: 1.35,
    recordingShortHighXP: 0.55,
    recordingNormalHighXP: 0.75,
    recordingLongHighXP: 1.1,
    mixRev: 1.0,
};

export default { data };
