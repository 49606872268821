<template>
    <v-col cols="auto" class="px-0 px-sm-3">
        <v-hover v-slot="{ hover }">
            <v-card
                class="pa-0 mx-auto white"
                min-width="320px"
                max-width="350px"
                height="965px"
                :elevation="hover ? 12 : 2"
            >
                <v-img
                    height="250px"
                    :class="isGrey()"
                    src="../../assets/post/master/1.jpg"
                    :gradient="
                        'to bottom,' +
                        getColor() +
                        ', rgba(255,255,255,.2), rgba(255,255,255,1)'
                    "
                >
                    <v-card-title class="text" style="margin-top: 180px">
                        Master
                        <v-tooltip bottom max-width="300">
                            <template #activator="{ on }">
                                <v-icon color="primary" small right v-on="on"
                                    >mdi-information-outline
                                </v-icon>
                            </template>
                            <span
                                >Das Mastering ist der finale Schritt der
                                Nachbearbeitung. Hierbei wird für eine
                                gleichmäßige Lautheit aller Aufnahmen gesorgt,
                                um den Standards der Musikindustrie gerecht zu
                                werden. Dazu werden mithilfe hochwertigen
                                Studioequipments alle Mixe im Frequenzspektrum
                                sowie in der Dynanik angepasst. Außerdem werden
                                Übergänge zwischen den Songs optimiert und die
                                Produktion nach Wunsch in verschiedene Formate
                                konvertiert.</span
                            >
                        </v-tooltip>
                        <v-switch
                            class="mx-3"
                            color="primary"
                            :input-value="recordingData.data.isMaster"
                            @click="recordingData.methods.toggleMaster()"
                            :disabled="!recordingData.data.isMix"
                            inset
                        >
                        </v-switch>
                    </v-card-title>
                </v-img>
                <v-card-text class="pt-2 pb-0">
                    <v-row>
                        <v-switch
                            v-model="recordingData.data.isStem"
                            :disabled="!recordingData.data.isMaster"
                            :label="'Stem Mastering'"
                            class="pl-1"
                        >
                        </v-switch>
                        <v-tooltip bottom max-width="300">
                            <template #activator="{ on }">
                                <v-icon color="primary" small right v-on="on"
                                    >mdi-information-outline
                                </v-icon>
                            </template>
                            <span
                                >Beim Stem Mastering wird nicht der Mix als
                                Ganzes, sondern in Instrumenten- gruppen (Stems)
                                bearbeitet. Mit dieser Methode hat das Studio
                                vielseitigere Möglichkeiten auf die einzelnen
                                Instrumentengruppen einzugehen und diese
                                intensiver zu bearbeiten. In der Regel wird kein
                                Stem Mastering benötigt. Es wird empfohlen diese
                                Einstellung nur zu wählen, wenn sie explizit
                                erforderlich ist.
                            </span>
                        </v-tooltip>
                    </v-row>
                </v-card-text>
                <v-subheader>Anzahl Stem-Mastering-Spuren: </v-subheader>
                <v-card-text class="pt-6 pb-0">
                    <v-slider
                        v-model="recordingData.data.stems"
                        :max="recordingData.minMaxValues.maxStems"
                        :min="recordingData.minMaxValues.minStems"
                        step="1"
                        thumb-label="always"
                        :thumb-size="28"
                        :disabled="
                            !recordingData.data.isMaster ||
                            !recordingData.data.isStem
                        "
                        class="pl-1"
                    ></v-slider>
                </v-card-text>
                <v-card-text class="" style="padding-top: 6px">
                    <v-row>
                        <v-switch
                            v-model="recordingData.data.ddp"
                            :disabled="!recordingData.data.isMaster"
                            :label="'DDP Mastering'"
                            class="pl-1"
                        >
                        </v-switch>
                        <v-tooltip bottom max-width="300">
                            <template #activator="{ on }">
                                <v-icon color="primary" small right v-on="on"
                                    >mdi-information-outline
                                </v-icon>
                            </template>
                            <span
                                >DDP ist ein Format, welches alle Informationen
                                Deiner Produktion in einem Paket bündelt.
                                Presswerke setzen dies häufig voraus, um die
                                Musikdaten, Metadaten, Pausenlängen etc. in
                                einem Paket zu erhalten. Es wird empfohlen diese
                                Einstellung nur zu wählen, wenn sie explizit
                                erforderlich ist.</span
                            >
                        </v-tooltip>
                    </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-text>
                    <v-row justify="center">
                        <v-card-subtitle class="pt-0 mb-5"
                            ><p
                                class="text-h6 text-center mb-1 mt-0"
                                :class="
                                    (recordingData.data.isMaster
                                        ? 'black'
                                        : 'disabled') + '--text'
                                "
                            >
                                Deine Master-Konfiguration
                            </p>
                        </v-card-subtitle>
                        <v-list-item
                            class="py-0 my-0"
                            dense
                            style="min-height: 20px"
                            v-for="(item, i) in bulletpoints"
                            :key="i"
                        >
                            <v-list-item-icon class="mr-0">
                                <v-icon
                                    class="pr-2"
                                    v-text="
                                        recordingData.data.isMaster
                                            ? item.icon
                                            : 'mdi-close-thick'
                                    "
                                    :color="
                                        recordingData.data.isMaster
                                            ? item.color
                                            : 'disabled'
                                    "
                                    small
                                ></v-icon
                            ></v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title
                                    v-text="item.text"
                                    :class="
                                        (recordingData.data.isMaster
                                            ? item.textcolor
                                            : 'disabled') + '--text'
                                    "
                                >
                                </v-list-item-title>
                            </v-list-item-content>
                            <v-tooltip v-if="item.tooltip" left max-width="300">
                                <template #activator="{ on }">
                                    <v-icon
                                        :color="
                                            recordingData.data.isMaster
                                                ? item.color
                                                : 'disabled'
                                        "
                                        small
                                        left
                                        v-on="on"
                                        >mdi-information-outline</v-icon
                                    >
                                </template>
                                <span v-text="item.tooltip"> </span>
                            </v-tooltip>
                        </v-list-item>
                    </v-row>
                </v-card-text>
                <v-fade-transition>
                    <v-overlay
                        v-if="hover && !recordingData.data.isMix"
                        absolute
                        z-index="1"
                        color="primary"
                    >
                        <v-card class="mx-4 white">
                            <v-card-text class="black--text text-center">
                                Um Master auswählen zu können muss Mix gewählt
                                sein.
                            </v-card-text>
                        </v-card>
                    </v-overlay>
                </v-fade-transition>
            </v-card>
        </v-hover>
    </v-col>
</template>

<script>
export default {
    inject: ['recordingData'],
    methods: {
        getColor() {
            if (!this.recordingData.data.isMaster) {
                return 'rgba(255,255,255,1)';
            } else {
                return 'rgba(255,255,255,0)';
            }
        },
        isGrey() {
            if (this.recordingData.data.isMaster) {
                return '';
            } else {
                return 'greyscale';
            }
        },
    },
    computed: {
        bulletpoints: function () {
            return [
                {
                    text: 'Frequenzoptimierung',
                    icon: 'mdi-check-bold',
                    color: 'primary',
                    textcolor: 'black',
                    tooltip:
                        'Bei diesem Bearbeitungsschritt wird das Frequenzbild des Mixes optimiert, um mehr Transparenz und Differenzierung der einzelnen Instrumente zu erreichen.',
                },
                {
                    text: 'Dynamikbearbeitung (Stereosumme)',
                    icon: 'mdi-check-bold',
                    color: 'primary',
                    textcolor: 'black',
                    tooltip:
                        'Bei diesem Bearbeitungsschritt wird die Dynamik der Stereosumme angepasst, um auf unterschiedlichen Abspielgeräten optimal zu klingen.',
                },
                {
                    text: 'Stereobasisverbreiterung',
                    icon: 'mdi-check-bold',
                    color: 'primary',
                    textcolor: 'black',
                    tooltip:
                        'Bei diesem Bearbeitungsschritt wird das Stereobild (Breite des Mixes) verbessert, um den einzelnen Instrumenten mehr Raum im Mix zu verschaffen.',
                },
                {
                    text: 'Referenzmaster',
                    icon: 'mdi-check-bold',
                    color: 'primary',
                    textcolor: 'black',
                    tooltip:
                        'Bei diesem Bearbeitungsschritt wird das Klangbild eures Masters möglichst nahe an eine von euch definierte Referenz angeglichen.',
                },
                {
                    text: 'Lautheit-Maximierung',
                    icon: 'mdi-check-bold',
                    color: 'primary',
                    textcolor: 'black',
                    tooltip:
                        'Bei diesem Bearbeitungsschritt wird die Lautheit Eures Masters auf ein konkurrenzfähiges Level angehoben.',
                },
                {
                    text: 'Formatkonvertierung',
                    icon: 'mdi-check-bold',
                    color: 'primary',
                    textcolor: 'black',
                    tooltip:
                        'Bei diesem Bearbeitungsschritt wird der Master in das von Euch benötigte Format (z.B. MP3, WAV, AIFF, FLAC) konvertiert.',
                },
                {
                    text: 'Stem Mastering',
                    icon: this.recordingData.data.isStem
                        ? 'mdi-check-bold'
                        : 'mdi-close-thick',
                    color: this.recordingData.data.isStem
                        ? 'primary'
                        : 'disabled',
                    textcolor: this.recordingData.data.isStem
                        ? 'black'
                        : 'disabled',
                },
                {
                    text: 'DDP Mastering',
                    icon: this.recordingData.data.ddp
                        ? 'mdi-check-bold'
                        : 'mdi-close-thick',
                    color: this.recordingData.data.ddp ? 'primary' : 'disabled',
                    textcolor: this.recordingData.data.ddp
                        ? 'black'
                        : 'disabled',
                },
            ];
        },
    },
};
</script>

<style>
.greyscale {
    filter: gray; /* IE6-9 */
    -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
    filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
}
</style>
