<template>
    <v-card class="pa-0 mx-auto" style="width: 100%">
        <v-card-title
            @click="configuratorState.methods.setPostState()"
            class="mt-6 py-2"
            style="cursor: pointer"
        >
            <h4 class="title text-h6" style="color: #666666">
                Nachbearbeitung
            </h4>
            <v-icon
                right
                class="mr-3"
                style="cursor: pointer; right: 0; position: absolute"
                >mdi-pencil</v-icon
            >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-0">
            <v-row class="pa-0">
                <v-col
                    class="font-weight-black d-flex justify-start align-center"
                    >Edit</v-col
                >
                <v-spacer></v-spacer>
                <v-col class="d-flex pr-0 justify-end align-center">
                    <v-switch
                        class="ma-0 pa-0"
                        color="primary"
                        :input-value="recordingData.data.isEdit"
                        @click="recordingData.methods.toggleEdit()"
                        inset
                        hide-details="true"
                    ></v-switch>
                </v-col>
            </v-row>
        </v-card-text>
        <v-fade-transition>
            <v-divider v-if="recordingData.data.isEdit"></v-divider>
        </v-fade-transition>
        <v-expand-transition>
            <v-card-text class="pa-0" v-if="recordingData.data.isEdit">
                <v-row class="pa-0 pl-4">
                    <v-col class="d-flex justify-start align-center"
                        >Qualität</v-col
                    >
                    <v-spacer></v-spacer>
                    <v-col
                        class="
                            d-flex
                            pl-2
                            ml-12
                            pr-0
                            py-0
                            align-center
                            text-right
                        "
                        cols="auto"
                    >
                        <v-select
                            v-model="recordingData.data.editQuality"
                            :items="qualities"
                            hide-details="true"
                            menu-props="auto"
                            solo
                            flat
                            dense
                            style="width: 125px"
                        ></v-select>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-expand-transition>
        <v-fade-transition>
            <v-divider v-if="recordingData.data.isEdit"></v-divider>
        </v-fade-transition>
        <v-expand-transition>
            <v-card-text class="pa-0" v-if="recordingData.data.isEdit">
                <v-row class="pa-0 pl-4">
                    <v-col class="d-flex justify-start align-center"
                        >Tonkorrektur Gesang</v-col
                    >
                    <v-spacer></v-spacer>
                    <v-col class="d-flex pr-2 justify-end align-center">
                        <v-switch
                            v-model="recordingData.data.melodyne"
                            class="ma-0 pa-0"
                            color="primary"
                            hide-details="true"
                        ></v-switch>
                    </v-col>
                </v-row> </v-card-text
        ></v-expand-transition>
        <v-fade-transition>
            <v-divider v-if="recordingData.data.isEdit"></v-divider>
        </v-fade-transition>
        <v-expand-transition>
            <v-card-text class="pa-0" v-if="recordingData.data.isEdit">
                <v-row class="pa-0">
                    <v-col
                        class="
                            font-weight-black
                            d-flex
                            justify-start
                            align-center
                        "
                        >Mix</v-col
                    >
                    <v-spacer></v-spacer>
                    <v-col class="d-flex pr-0 justify-end align-center">
                        <v-switch
                            class="ma-0 pa-0"
                            color="primary"
                            :input-value="recordingData.data.isMix"
                            @click="recordingData.methods.toggleMix()"
                            inset
                            hide-details="true"
                        ></v-switch>
                    </v-col>
                </v-row> </v-card-text
        ></v-expand-transition>
        <v-fade-transition>
            <v-divider v-if="recordingData.data.isMix"></v-divider
        ></v-fade-transition>
        <v-expand-transition>
            <v-card-text class="pa-0" v-if="recordingData.data.isMix">
                <v-row class="pa-0 pl-4">
                    <v-col class="d-flex justify-start align-center"
                        >Qualität</v-col
                    >
                    <v-spacer></v-spacer>
                    <v-col
                        class="d-flex pl-2 pr-0 py-0 align-center text-right"
                        cols="auto"
                    >
                        <v-select
                            flat
                            v-model="recordingData.data.mixQuality"
                            :items="qualities"
                            hide-details="true"
                            menu-props="auto"
                            solo
                            dense
                            style="width: 125px"
                            class="py-0"
                        ></v-select>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-expand-transition>
        <v-fade-transition>
            <v-divider v-if="recordingData.data.isMix"></v-divider>
        </v-fade-transition>
        <v-expand-transition>
            <v-card-text class="pa-0" v-if="recordingData.data.isMix">
                <v-row class="pa-0 pl-4">
                    <v-col class="d-flex justify-start align-center"
                        >Revisionen</v-col
                    >
                    <v-spacer></v-spacer>
                    <v-col class="d-flex pr-1 py-0 justify-end align-center">
                        <v-btn
                            fab
                            elevation="0"
                            icon
                            x-small
                            class="mr-1"
                            v-on:click="
                                recordingData.methods.decreaseRevisions()
                            "
                            :disabled="
                                recordingData.data.revisions <=
                                recordingData.minMaxValues.minRevisions
                            "
                        >
                            <v-icon>mdi-minus</v-icon>
                        </v-btn>
                        {{ this.recordingData.data.revisions }}
                        <v-btn
                            fab
                            elevation="0"
                            icon
                            x-small
                            class="ml-0"
                            v-on:click="
                                recordingData.methods.increaseRevisions()
                            "
                            :disabled="
                                recordingData.data.revisions >=
                                recordingData.minMaxValues.maxRevisions
                            "
                        >
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </v-col></v-row
                >
            </v-card-text>
        </v-expand-transition>
        <v-fade-transition>
            <v-divider v-if="recordingData.data.isMix"></v-divider>
        </v-fade-transition>
        <v-expand-transition>
            <v-card-text class="pa-0" v-if="recordingData.data.isMix">
                <v-row class="pa-0">
                    <v-col
                        class="
                            font-weight-black
                            d-flex
                            justify-start
                            align-center
                        "
                        >Master</v-col
                    >
                    <v-spacer></v-spacer>
                    <v-col class="d-flex pr-0 justify-end align-center">
                        <v-switch
                            class="ma-0 pa-0"
                            color="primary"
                            :input-value="recordingData.data.isMaster"
                            @click="recordingData.methods.toggleMaster()"
                            inset
                            hide-details="true"
                        ></v-switch>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-expand-transition>
        <v-fade-transition>
            <v-divider v-if="recordingData.data.isMaster"></v-divider>
        </v-fade-transition>
        <v-expand-transition>
            <v-card-text class="pa-0" v-if="recordingData.data.isMaster">
                <v-row class="pa-0 pl-4">
                    <v-col class="d-flex justify-start align-center"
                        >DDP Mastering</v-col
                    >
                    <v-spacer></v-spacer>
                    <v-col class="d-flex pr-2 justify-end align-center">
                        <v-switch
                            v-model="recordingData.data.ddp"
                            class="ma-0 pa-0"
                            color="primary"
                            hide-details="true"
                        ></v-switch>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-expand-transition>
        <v-fade-transition>
            <v-divider v-if="recordingData.data.isMaster"></v-divider>
        </v-fade-transition>
        <v-expand-transition>
            <v-card-text class="pa-0" v-if="recordingData.data.isMaster">
                <v-row class="pa-0 pl-4">
                    <v-col class="d-flex justify-start align-center"
                        >Stem Mastering</v-col
                    >
                    <v-spacer></v-spacer>
                    <v-col class="d-flex pr-2 justify-end align-center">
                        <v-switch
                            v-model="recordingData.data.isStem"
                            class="ma-0 pa-0"
                            color="primary"
                            hide-details="true"
                        ></v-switch>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-expand-transition>
        <v-fade-transition>
            <v-divider
                v-if="recordingData.data.isStem && recordingData.data.isMaster"
            ></v-divider>
        </v-fade-transition>
        <v-expand-transition>
            <v-card-text class="pa-0">
                <v-row
                    class="pa-0 pl-4"
                    v-if="
                        recordingData.data.isStem && recordingData.data.isMaster
                    "
                >
                    <v-col>Stems</v-col>
                    <v-spacer></v-spacer>
                    <v-col class="d-flex pr-1 py-0 justify-end align-center">
                        <v-btn
                            fab
                            elevation="0"
                            icon
                            x-small
                            class="mr-1"
                            v-on:click="recordingData.methods.decreaseStems()"
                            :disabled="
                                recordingData.data.stems <=
                                recordingData.minMaxValues.minStems
                            "
                        >
                            <v-icon>mdi-minus</v-icon>
                        </v-btn>
                        {{ this.recordingData.data.stems }}
                        <v-btn
                            fab
                            elevation="0"
                            icon
                            x-small
                            class="ml-0"
                            v-on:click="recordingData.methods.increaseStems()"
                            :disabled="
                                recordingData.data.stems >=
                                recordingData.minMaxValues.maxStems
                            "
                        >
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </v-col></v-row
                >
            </v-card-text>
        </v-expand-transition>
    </v-card>
</template>
<script>
export default {
    inject: ['configuratorState', 'recordingData'],
    data: function () {
        return {
            qualities: [
                { value: 0, text: 'Gold' },
                { value: 1, text: 'Platin' },
                { value: 2, text: 'Diamond' },
            ],
        };
    },
    methods: {
        getQuality(quality) {
            switch (quality) {
                case 0:
                    return 'Gold';
                case 1:
                    return 'Platin';
                case 2:
                    return 'Diamond';
                default:
                    return 'Platin';
            }
        },
        getBooleans(boolean) {
            switch (boolean) {
                case false:
                    return 'Nein';
                case true:
                    return 'Ja';
                default:
                    return 'Nein';
            }
        },
    },
    computed: {
        computedEditQuality: function () {
            return this.getQuality(this.recordingData.data.editQuality);
        },
    },
};
</script>
<style scoped></style>
