<template>
    <v-container fluid class="py-4 px-0 px-sm-4 my-auto">
        <v-card dir style="margin: auto" min-width="250px" max-width="730px">
            <v-card-title class="justify-center text-h5"
                >Nachbearbeitung</v-card-title
            >
            <v-card-text class="text-justify text-subtitle-2"
                >Im letzten Schritt hast Du die Möglichkeit die Nachbearbeitung
                zu konfigurieren. Bei der Nachbearbeitung lässt sich mit
                zusätzlichem Aufwand und Studio Know-how der Gesamtsound
                optimieren. Kommt eine Nachbearbeitung für Dich in Frage, schaue
                Dir bitte alle Einstellungsmöglichkeiten genau an. Sollte etwas
                unklar sein, erhältst Du bei dem
                <v-icon color="primary" small>mdi-information-outline</v-icon>
                Hilfe.
            </v-card-text>
        </v-card>
        <v-container class="mx-auto px-0 px-sm-auto">
            <v-row align="start" justify="center">
                <EditComponent :qualityLabels="qualityLabels"></EditComponent>
                <MixComponent :qualityLabels="qualityLabels"></MixComponent>
                <MasterComponent></MasterComponent>
            </v-row>
        </v-container>
    </v-container>
</template>

<script>
import EditComponent from '../../components/configurator/edit.vue';
import MixComponent from '../../components/configurator/mix.vue';
import MasterComponent from '../../components/configurator/master.vue';
export default {
    inject: ['recordingData'],
    components: { EditComponent, MixComponent, MasterComponent },
    data: function () {
        return {
            qualityLabels: ['Gold', 'Platin', 'Diamond'],
        };
    },
    created() {
        this.$emit('change:background');
        window.scrollTo(0, 0);
    },
};
</script>

<style scoped></style>
