var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"px-0 px-sm-3",attrs:{"cols":"auto"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"pa-0 mx-auto white",attrs:{"min-width":"320px","height":"965px","max-width":"350px","elevation":hover ? 12 : 2}},[_c('v-img',{class:_vm.isGrey(),attrs:{"height":"250px","src":require("../../assets/post/edit/1.jpg"),"gradient":'to bottom,' +
                    _vm.getColor() +
                    ', rgba(255,255,255,.2), rgba(255,255,255,1)'}},[_c('v-card-title',{staticClass:"text",staticStyle:{"margin-top":"180px"}},[_vm._v(" Edit "),_c('v-tooltip',{attrs:{"bottom":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"primary","small":"","right":""}},on),[_vm._v("mdi-information-outline")])]}}],null,true)},[_c('span',[_vm._v("Der Edit bildet die Grundlage für einen guten Mix und hat großen Anteil am Gesamtsound. Das Editing umfasst das Säubern und Schneiden der Audiospuren, bei dem zusätzlich Spielfehler korrigiert werden können. ")])]),_c('v-switch',{staticClass:"mx-3",attrs:{"color":"primary","input-value":_vm.recordingData.data.isEdit,"inset":""},on:{"click":function($event){return _vm.recordingData.methods.toggleEdit()}}})],1)],1),_c('v-subheader',[_vm._v("Welche Qualität soll der Edit haben? "),_c('v-tooltip',{attrs:{"bottom":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"primary","small":"","right":""}},on),[_vm._v("mdi-information-outline")])]}}],null,true)},[_c('span',[_vm._v("Die Qualität des Edits wirkt sich auf den Aufwand der Nachbearbeitung und somit auf die Preiskalkulation aus. Bei einer höheren Edit-Qualität kann intensiver an Timingkorrekturen geschliffen werden, um das Ergebnis natürlicher klingen zu lassen. ")])])],1),_c('v-card-text',[_c('v-slider',{attrs:{"max":2,"step":"1","tick-labels":_vm.qualityLabels,"disabled":!_vm.recordingData.data.isEdit,"color":_vm.getColor(),"track-color":_vm.getColor(),"tick-size":"4"},model:{value:(_vm.recordingData.data.editQuality),callback:function ($$v) {_vm.$set(_vm.recordingData.data, "editQuality", $$v)},expression:"recordingData.data.editQuality"}})],1),_c('v-card-text',[_c('v-row',[_c('v-switch',{staticClass:"pl-1",attrs:{"disabled":!_vm.recordingData.data.isEdit,"label":'Tonkorrektur Gesang'},model:{value:(_vm.recordingData.data.melodyne),callback:function ($$v) {_vm.$set(_vm.recordingData.data, "melodyne", $$v)},expression:"recordingData.data.melodyne"}}),_c('v-tooltip',{attrs:{"bottom":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"primary","small":"","right":""}},on),[_vm._v("mdi-information-outline")])]}}],null,true)},[_c('span',[_vm._v("Die Tonkorrektur (auch Melodyne oder Autotune genannt) ist eine heutzutage gängige Methode. Hier werden die Gesangsstimmen nachträglich verbessert. Wählst Du diese Option nicht, bleibt Deine Orginalstimme erhalten. ")])])],1)],1),_c('v-divider',{staticClass:"mt-sm-14"}),_c('v-card-text',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-card-subtitle',{staticClass:"pt-0"},[_c('p',{staticClass:"text-h6 text-center mb-1 mt-0",class:(_vm.recordingData.data.isEdit
                                    ? 'black'
                                    : 'disabled') + '--text'},[_vm._v(" "+_vm._s(_vm.qualityName)+" ")]),_c('p',{staticClass:"text-subtitle-2 text-center mb-0",class:(_vm.recordingData.data.isEdit
                                    ? 'black'
                                    : 'disabled') + '--text'},[_vm._v(" Edit-Zeit pro Song: "+_vm._s(_vm.qualityTime)+" "+_vm._s(Math.ceil( _vm.editTime / _vm.recordingData.data.numberOfSongs ))+" "+_vm._s(Math.ceil( _vm.editTime / _vm.recordingData.data.numberOfSongs ) != 1 ? 'Stunden' : 'Stunde')+" ")])]),_vm._l((_vm.bulletpoints),function(item,i){return _c('v-list-item',{key:i,staticClass:"py-0 my-0",staticStyle:{"min-height":"20px"},attrs:{"dense":""}},[_c('v-list-item-icon',{staticClass:"mr-0"},[_c('v-icon',{staticClass:"pr-2",attrs:{"color":_vm.recordingData.data.isEdit
                                        ? item.color
                                        : 'disabled',"small":""},domProps:{"textContent":_vm._s(
                                    _vm.recordingData.data.isEdit
                                        ? item.icon
                                        : 'mdi-close-thick'
                                )}})],1),_c('v-list-item-content',[_c('v-list-item-title',{class:(_vm.recordingData.data.isEdit
                                        ? item.textcolor
                                        : 'disabled') + '--text',domProps:{"textContent":_vm._s(item.text)}})],1),(item.tooltip)?_c('v-tooltip',{attrs:{"left":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                        var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":_vm.recordingData.data.isEdit
                                            ? item.color
                                            : 'disabled',"small":"","left":""}},on),[_vm._v("mdi-information-outline")])]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(item.tooltip)}})]):_vm._e()],1)})],2)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }