<template>
    <div style="background-color: #fff; width: 100vw; z-index: 4">
        <v-container
            class="pa-0 d-flex justify-space-between flex-column"
            fluid
            style="margin-bottom: 100px; max-width: 1200px"
        >
            <v-card
                class="py-15 px-6 ma-0 elevation-0"
                style="border-radius: 0; box-shadow: none"
            >
                <v-row class="pa-0">
                    <v-col class="pa-0">
                        <v-card-title
                            primary-title
                            class="justify-center text-subtitle-2 text-md-h3 text-lg-h2 text-sm-h4"
                        >
                            Dein Studio in Hamburg
                        </v-card-title>
                        <v-card-text
                            class="pt-0 text-subtitle-1 text-md-h5 text-lg-h4 text-sm-h6 text-center"
                        >
                            Elevate Studios
                        </v-card-text>
                        <v-card-text class="text-justify">
                            Die <a :href=elevateURL target=”_blank”><strong>Elevate Studios</strong></a> im Herzen Hamburgs
                            verfügen über drei miteinander verkabelte Aufnahmeräume sowie
                            drei Regieräume, in denen parallel und eigenständig
                            gearbeitet werden kann. Mit über 80qm Fläche bietet unser großer
                            Aufnahmeraum genügend Platz für die
                            Liveproduktion einer Band bis hin zur vollständigen
                            und hochwertigen Aufnahme eines Kammer-Orchesters.
                            Der angrenzende Aufnahmeraum ist für Gesangs- und
                            Sprachaufnahmen optimiert, kann aber auch jederzeit als weitere Kabine
                            für E-Gitarren genutzt werden. Zusätzlich können auch in unserer Lounge Aufnahmen stattfinden.
                        </v-card-text>
                    </v-col>
                </v-row>
                <v-row class="pa-0" align="center">
                    <v-col class="pa-0">
                        <v-img src="../../assets/home/sofa.jpg"></v-img>
                    </v-col>
                </v-row>
            </v-card>
        </v-container>
    </div>
</template>

<script>
export default {
    data: function () {
        return {
            elevateURL: 'https://elevate-studios.de/'
        };
    }
};
</script>

<style scoped>
.v-responsive__content {
    width: fit-content !important;
}
</style>
