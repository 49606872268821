import recordingData from '../stateMgmt/recordingData';
import feedbackData from '../stateMgmt/feedbackData';
import configuratorLogic from '../business_logic/configuratorLogic';
const baseURL = 'https://bandrecording-hamburg.de/mail-service/';

const json = {
    recordingType: '',
    email: '',
    name: '',
    musicians: [],
    songs: [],
    edit: {
        isEdit: true,
        quality: '',
        melodyne: true,
    },
    mix: {
        isMix: true,
        quality: '',
        revisions: 0,
    },
    master: {
        isMaster: true,
        isDDP: true,
        isStem: true,
        stems: 0,
    },
    price: {
        recording: 0,
        post: 0,
        netto: 0,
        brutto: 0,
        discountPrice: 0,
        discount: 0,
    },
    isNight: false,
    isProducer: false,
};

// method to establish the http connection and send the data to the REST endpoint
const sendHttpRequest = (method, url, data) => {
    // create a promise
    const promise = new Promise((resolve, reject) => {
        // Creating a XHR object
        let xhr = new XMLHttpRequest();
        // open a connection
        xhr.open(method, url);
        // Set the request header i.e. which type of content you are sending
        xhr.setRequestHeader('Content-Type', 'application/json');
        // when the server responded
        xhr.onload = () => {
            if (xhr.status >= 400) {
                reject(xhr.response);
            } else {
                resolve(xhr.response);
            }
        };
        // when the server does not respond
        xhr.onerror = () => {
            reject('something went wrong with the server');
        };
        // Sending data with the request
        xhr.send(data);
    });
    return promise;
};

const methods = {
    sendFeedback() {
        let url = baseURL + 'feedback/';

        // Converting JSON data to string
        var data = JSON.stringify(feedbackData.data);

        // call the send http request method
        return sendHttpRequest('POST', url, data)
            .then(function (responseData) {
                console.log(responseData);
                return true;
            })
            .catch(function (err) {
                console.log(err);
                return false;
            });
    },
    sendOffer() {
        let url = baseURL + 'offer/';
        // check if the json is valid
        if (methods.validateRecordingData()) {
            // building the json
            methods.buildJson();
            // calc the individual recording time for each instrument
            methods.getMusicianRecordingTime();
            // Converting JSON data to string
            var data = JSON.stringify(json);

            // call the send http request method
            return sendHttpRequest('POST', url, data)
                .then(function (responseData) {
                    console.log(responseData);
                    return true;
                })
                .catch(function (err) {
                    console.log(err);
                    return false;
                });
        } else {
            console.log('There are errors in the JSON!');
            return false;
        }
    },

    validateRecordingData() {
        for (const musician of Object.values(recordingData.data.musicians)) {
            if (musician.name == '') {
                return false;
            }
        }
        for (const song of Object.values(recordingData.data.songs)) {
            if (song.name == '') {
                return false;
            }
        }
        if (recordingData.data.name == '' || recordingData.data.email == '') {
            return false;
        }
        return true;
    },

    buildJson() {
        json.recordingType = recordingData.data.recordingType;
        json.email = recordingData.data.email;
        json.name = recordingData.data.name;

        for (const musician of Object.values(recordingData.data.musicians)) {
            json.musicians.push(musician);
        }

        for (const song of Object.values(recordingData.data.songs)) {
            json.songs.push(song);
        }

        json.edit.isEdit = recordingData.data.isEdit;
        json.edit.quality = recordingData.data.editQuality;
        json.edit.melodyne = recordingData.data.melodyne;

        json.mix.isMix = recordingData.data.isMix;
        json.mix.quality = recordingData.data.mixQuality;
        json.mix.revisions = recordingData.data.revisions;

        json.master.isMaster = recordingData.data.isMaster;
        json.master.isDDP = recordingData.data.ddp;
        json.master.isStem = recordingData.data.isStem;
        json.master.stems = recordingData.data.stems;

        json.isNight = recordingData.data.isNight;
        json.isProducer = recordingData.data.isProducer;

        json.price.recording = recordingData.prices.recordingPrice;
        json.price.post = recordingData.prices.postPrice;
        json.price.netto = recordingData.prices.nettoPrice;
        json.price.brutto = recordingData.prices.bruttoPrice;
        json.price.discountPrice = recordingData.prices.discountPrice;
        json.price.discount = recordingData.prices.discount;
    },

    getMusicianRecordingTime() {
        let musiciansRecordingTime =
            configuratorLogic.methods.calculateMusicianRecordingTime(
                recordingData.data.songs,
                recordingData.data.musicians
            );
        for (const [key, value] of Object.entries(musiciansRecordingTime)) {
            recordingData.data.musicians[key].recordingTime = value;
        }
    },

    sendContactRequest(name, email, text) {
        let url = baseURL + 'contactform/';
        // Converting JSON data to string
        var data = JSON.stringify({ name: name, email: email, text: text });

        // call the send http request method
        return sendHttpRequest('POST', url, data)
            .then(function (responseData) {
                console.log(responseData);
                return true;
            })
            .catch(function (err) {
                console.log(err);
                return false;
            });
    },
};
export default {
    json: json,
    methods,
};
