var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"px-0 px-sm-3",attrs:{"cols":"auto"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"pa-0 mx-auto white pb-10",attrs:{"min-width":"320px","max-width":"350px","height":"965px","elevation":hover ? 12 : 2}},[_c('v-img',{class:_vm.isGrey(),attrs:{"height":"250px","src":require("../../assets/post/mix/1.jpg"),"gradient":'to bottom,' +
                    _vm.getColor() +
                    ', rgba(255,255,255,.2), rgba(255,255,255,1)'}},[_c('v-card-title',{staticClass:"text pt-0",staticStyle:{"margin-top":"200px"}},[_vm._v(" Mix "),_c('v-tooltip',{attrs:{"bottom":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"primary","small":"","right":""}},on),[_vm._v("mdi-information-outline")])]}}],null,true)},[_c('span',[_vm._v("Der Mix ist ein wichtiger Schritt der Nachbearbeitung und bestimmt den Gesamtsound. Hier werden die einzelnen Instrumente bezüglich Frequenzumfang und Dynamik bearbeitet, um einen transparenten, druckvollen und modernen Gesamtsound zu liefern. ")])]),_c('v-switch',{staticClass:"mx-3",attrs:{"color":"primary","inset":"","input-value":_vm.recordingData.data.isMix,"disabled":!_vm.recordingData.data.isEdit},on:{"click":function($event){return _vm.recordingData.methods.toggleMix()}}})],1)],1),_c('v-subheader',[_vm._v("Welche Qualität soll der Mix haben? "),_c('v-tooltip',{attrs:{"bottom":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"primary","small":"","right":""}},on),[_vm._v("mdi-information-outline")])]}}],null,true)},[_c('span',[_vm._v("Hohe Mixqualität ermöglicht zusätzliche kreative Effekte und Hallraumeinstellungen, die den Song musikalisch unterstützen. Die Qualität des Mixes wirkt sich auf den Aufwand der Nachbearbeitung und somit auf die Preiskalkulation aus. ")])])],1),_c('v-card-text',[_c('v-slider',{attrs:{"max":2,"step":"1","tick-labels":_vm.qualityLabels,"disabled":!_vm.recordingData.data.isMix,"color":_vm.getColor(),"track-color":_vm.getColor(),"tick-size":"4"},model:{value:(_vm.recordingData.data.mixQuality),callback:function ($$v) {_vm.$set(_vm.recordingData.data, "mixQuality", $$v)},expression:"recordingData.data.mixQuality"}})],1),_c('v-subheader',[_vm._v("Wie viele Revisionen sollen eingeplant werden? "),_c('v-tooltip',{attrs:{"bottom":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"primary","small":"","right":""}},on),[_vm._v("mdi-information-outline")])]}}],null,true)},[_c('span',[_vm._v("Nach Fertigstellung des Mixes hast Du die Möglichkeit, Feedback und Änderungswünsche zu formulieren, die daraufhin in einer kostenlosen Revision eingearbeitet werden. Weitere Revisionen führen zu einem Preisaufschlag und können hier direkt mit eingeplant werden.")])])],1),_c('v-card-text',{staticClass:"pt-6"},[_c('v-slider',{staticClass:"pl-1",attrs:{"max":_vm.recordingData.minMaxValues.maxRevisions,"min":_vm.recordingData.minMaxValues.minRevisions,"thumb-label":"always","disabled":!_vm.recordingData.data.isMix,"step":"1","thumb-size":28},model:{value:(_vm.recordingData.data.revisions),callback:function ($$v) {_vm.$set(_vm.recordingData.data, "revisions", $$v)},expression:"recordingData.data.revisions"}})],1),_c('v-divider',{staticClass:"mt-3"}),_c('v-card-text',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-card-subtitle',{staticClass:"pt-0"},[_c('p',{staticClass:"text-h6 text-center mb-1 mt-0",class:(_vm.recordingData.data.isEdit
                                    ? 'black'
                                    : 'disabled') + '--text'},[_vm._v(" "+_vm._s(_vm.qualityName)+" ")]),_c('p',{staticClass:"text-subtitle-2 text-center mb-0",class:(_vm.recordingData.data.isEdit
                                    ? 'black'
                                    : 'disabled') + '--text'},[_vm._v(" Mix-Zeit pro Song: "+_vm._s(_vm.qualityTime)+" "+_vm._s(Math.ceil( _vm.mixTime / _vm.recordingData.data.numberOfSongs ))+" "+_vm._s(Math.ceil( _vm.mixTime / _vm.recordingData.data.numberOfSongs ) != 1 ? 'Stunden' : 'Stunde')+" ")])]),_vm._l((_vm.bulletpoints),function(item,i){return _c('v-list-item',{key:i,staticClass:"py-0 my-0",staticStyle:{"min-height":"20px"},attrs:{"dense":""}},[_c('v-list-item-icon',{staticClass:"mr-0"},[_c('v-icon',{staticClass:"pr-2",attrs:{"color":_vm.recordingData.data.isMix
                                        ? item.color
                                        : 'disabled',"small":""},domProps:{"textContent":_vm._s(
                                    _vm.recordingData.data.isMix
                                        ? item.icon
                                        : 'mdi-close-thick'
                                )}})],1),_c('v-list-item-content',[_c('v-list-item-title',{class:(_vm.recordingData.data.isMix
                                        ? item.textcolor
                                        : 'disabled') + '--text',domProps:{"textContent":_vm._s(item.text)}})],1),(item.tooltip)?_c('v-tooltip',{attrs:{"left":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                        var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":_vm.recordingData.data.isMix
                                            ? item.color
                                            : 'disabled',"small":"","left":""}},on),[_vm._v("mdi-information-outline")])]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(item.tooltip)}})]):_vm._e()],1)})],2)],1),_c('v-fade-transition',[(hover && !_vm.recordingData.data.isEdit)?_c('v-overlay',{attrs:{"absolute":"","z-index":"1","color":"primary"}},[_c('v-card',{staticClass:"mx-4 white"},[_c('v-card-text',{staticClass:"black--text text-center"},[_vm._v(" Um Mix auswählen zu können muss Edit gewählt sein. ")])],1)],1):_vm._e()],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }