<template>
    <v-col cols="auto" class="px-0 px-sm-3">
        <v-hover v-slot="{ hover }">
            <v-card
                class="pa-0 mx-auto white"
                min-width="320px"
                height="965px"
                max-width="350px"
                :elevation="hover ? 12 : 2"
            >
                <v-img
                    height="250px"
                    :class="isGrey()"
                    src="../../assets/post/edit/1.jpg"
                    :gradient="
                        'to bottom,' +
                        getColor() +
                        ', rgba(255,255,255,.2), rgba(255,255,255,1)'
                    "
                >
                    <v-card-title class="text" style="margin-top: 180px">
                        Edit
                        <v-tooltip bottom max-width="300">
                            <template #activator="{ on }">
                                <v-icon color="primary" small right v-on="on"
                                    >mdi-information-outline</v-icon
                                >
                            </template>
                            <span
                                >Der Edit bildet die Grundlage für einen guten
                                Mix und hat großen Anteil am Gesamtsound. Das
                                Editing umfasst das Säubern und Schneiden der
                                Audiospuren, bei dem zusätzlich Spielfehler
                                korrigiert werden können.
                            </span>
                        </v-tooltip>
                        <v-switch
                            class="mx-3"
                            color="primary"
                            :input-value="recordingData.data.isEdit"
                            @click="recordingData.methods.toggleEdit()"
                            inset
                        ></v-switch>
                    </v-card-title>
                </v-img>
                <v-subheader
                    >Welche Qualität soll der Edit haben?
                    <v-tooltip bottom max-width="300">
                        <template #activator="{ on }">
                            <v-icon color="primary" small right v-on="on"
                                >mdi-information-outline</v-icon
                            >
                        </template>
                        <span
                            >Die Qualität des Edits wirkt sich auf den Aufwand
                            der Nachbearbeitung und somit auf die
                            Preiskalkulation aus. Bei einer höheren
                            Edit-Qualität kann intensiver an Timingkorrekturen
                            geschliffen werden, um das Ergebnis natürlicher
                            klingen zu lassen.
                        </span>
                    </v-tooltip>
                </v-subheader>
                <v-card-text>
                    <v-slider
                        v-model="recordingData.data.editQuality"
                        :max="2"
                        step="1"
                        :tick-labels="qualityLabels"
                        :disabled="!recordingData.data.isEdit"
                        :color="getColor()"
                        :track-color="getColor()"
                        tick-size="4"
                    ></v-slider>
                </v-card-text>
                <v-card-text>
                    <v-row>
                        <v-switch
                            v-model="recordingData.data.melodyne"
                            :disabled="!recordingData.data.isEdit"
                            :label="'Tonkorrektur Gesang'"
                            class="pl-1"
                        ></v-switch>
                        <v-tooltip bottom max-width="300">
                            <template #activator="{ on }">
                                <v-icon color="primary" small right v-on="on"
                                    >mdi-information-outline</v-icon
                                >
                            </template>
                            <span
                                >Die Tonkorrektur (auch Melodyne oder Autotune
                                genannt) ist eine heutzutage gängige Methode.
                                Hier werden die Gesangsstimmen nachträglich
                                verbessert. Wählst Du diese Option nicht, bleibt
                                Deine Orginalstimme erhalten.
                            </span>
                        </v-tooltip>
                    </v-row>
                </v-card-text>
                <v-divider class="mt-sm-14"></v-divider>
                <v-card-text>
                    <v-row class="" justify="center">
                        <v-card-subtitle class="pt-0"
                            ><p
                                class="text-h6 text-center mb-1 mt-0"
                                :class="
                                    (recordingData.data.isEdit
                                        ? 'black'
                                        : 'disabled') + '--text'
                                "
                            >
                                {{ qualityName }}
                            </p>
                            <p
                                class="text-subtitle-2 text-center mb-0"
                                :class="
                                    (recordingData.data.isEdit
                                        ? 'black'
                                        : 'disabled') + '--text'
                                "
                            >
                                Edit-Zeit pro Song:
                                {{ qualityTime }}
                                {{
                                    Math.ceil(
                                        editTime /
                                            recordingData.data.numberOfSongs
                                    )
                                }}
                                {{
                                    Math.ceil(
                                        editTime /
                                            recordingData.data.numberOfSongs
                                    ) != 1
                                        ? 'Stunden'
                                        : 'Stunde'
                                }}
                            </p>
                        </v-card-subtitle>
                        <v-list-item
                            class="py-0 my-0"
                            dense
                            style="min-height: 20px"
                            v-for="(item, i) in bulletpoints"
                            :key="i"
                        >
                            <v-list-item-icon class="mr-0">
                                <v-icon
                                    class="pr-2"
                                    v-text="
                                        recordingData.data.isEdit
                                            ? item.icon
                                            : 'mdi-close-thick'
                                    "
                                    :color="
                                        recordingData.data.isEdit
                                            ? item.color
                                            : 'disabled'
                                    "
                                    small
                                ></v-icon
                            ></v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title
                                    v-text="item.text"
                                    :class="
                                        (recordingData.data.isEdit
                                            ? item.textcolor
                                            : 'disabled') + '--text'
                                    "
                                >
                                </v-list-item-title>
                            </v-list-item-content>
                            <v-tooltip v-if="item.tooltip" left max-width="300">
                                <template #activator="{ on }">
                                    <v-icon
                                        :color="
                                            recordingData.data.isEdit
                                                ? item.color
                                                : 'disabled'
                                        "
                                        small
                                        left
                                        v-on="on"
                                        >mdi-information-outline</v-icon
                                    >
                                </template>
                                <span v-text="item.tooltip"> </span>
                            </v-tooltip>
                        </v-list-item>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-hover>
    </v-col>
</template>

<script>
export default {
    inject: ['recordingData', 'configuratorLogic'],
    props: { qualityLabels: Array },
    data: function () {
        return {
            whiteColor: 'rgba(255, 255, 255, 1)',
            goldColor: 'rgba(226, 176, 7, 1)',
            platinColor: 'rgba(149, 154, 164, 1)',
            diamondColor: 'rgba(154, 197, 219)',
        };
    },
    methods: {
        getColor() {
            if (this.recordingData.data.isEdit) {
                if (this.recordingData.data.editQuality == 0) {
                    return this.goldColor;
                } else if (this.recordingData.data.editQuality == 1) {
                    return this.platinColor;
                } else {
                    return this.diamondColor;
                }
            } else {
                return this.whiteColor;
            }
        },
        isGrey() {
            if (this.recordingData.data.isEdit) {
                return '';
            } else {
                return 'greyscale';
            }
        },
        start(value) {
            console.log('hover ' + this.hover);
            console.log(value);
        },
    },
    computed: {
        bulletpoints: function () {
            let quality = this.recordingData.data.editQuality;
            return [
                {
                    text: 'Säuberung der Spuren',
                    icon: 'mdi-check-bold',
                    color: 'primary',
                    textcolor: 'black',
                    tooltip:
                        'Bei diesem Bearbeitungsschritt werden die Spuren von Störgeräuschen bereinigt.',
                },
                {
                    text: 'Setzen von Fades',
                    icon: 'mdi-check-bold',
                    color: 'primary',
                    textcolor: 'black',
                    tooltip:
                        'Bei diesem Bearbeitungsschritt werden saubere Übergänge gesetzt.',
                },
                {
                    text: 'Timing-Korrekturen',
                    icon: quality == 0 ? 'mdi-close-thick' : 'mdi-check-bold',
                    color: quality == 0 ? 'disabled' : 'primary',
                    textcolor: quality == 0 ? 'disabled' : 'black',
                    tooltip:
                        'Bei diesem Bearbeitungsschritt werden grobe Timining-Fehler der Musiker korrigiert.',
                },
                {
                    text: 'Take-Auswahl',
                    icon: quality == 0 ? 'mdi-close-thick' : 'mdi-check-bold',
                    color: quality == 0 ? 'disabled' : 'primary',
                    textcolor: quality == 0 ? 'disabled' : 'black',
                    tooltip:
                        'Bei diesem Bearbeitungsschritt wird der beste Aufnahmetake ausgewählt',
                },
                {
                    text: 'Clip-Gain-Automation',
                    icon: quality <= 1 ? 'mdi-close-thick' : 'mdi-check-bold',
                    color: quality <= 1 ? 'disabled' : 'primary',
                    textcolor: quality <= 1 ? 'disabled' : 'black',
                    tooltip:
                        'Bei diesem Bearbeitungsschritt wird die Dynamik der Spuren händisch angepasst, um eine musikalische Dynamikreduktion zu erreichen.',
                },
                {
                    text: 'Timing-Korrekturen (erweitert)',
                    icon: quality <= 1 ? 'mdi-close-thick' : 'mdi-check-bold',
                    color: quality <= 1 ? 'disabled' : 'primary',
                    textcolor: quality <= 1 ? 'disabled' : 'black',
                    tooltip:
                        'Bei diesem Bearbeitungsschritt werden die Spuren im Microtiming (musikalisches Timing) korrigiert. Dadurch wirkt die Aufnahme homogener, ohne an Natürlichkeit zu verlieren.',
                },
                {
                    text: 'Take-Auswahl (erweitert)',
                    icon: quality <= 1 ? 'mdi-close-thick' : 'mdi-check-bold',
                    color: quality <= 1 ? 'disabled' : 'primary',
                    textcolor: quality <= 1 ? 'disabled' : 'black',
                    tooltip:
                        'Bei diesem Bearbeitungsschritt wird der finale Take aus den besten Teilabschnitten aller Takes zusammengeschnitten.',
                },
                {
                    text: 'Tonkorrektur Gesang',
                    icon: this.recordingData.data.melodyne
                        ? 'mdi-check-bold'
                        : 'mdi-close-thick',
                    color: this.recordingData.data.melodyne
                        ? 'primary'
                        : 'disabled',
                    textcolor: this.recordingData.data.melodyne
                        ? 'black'
                        : 'disabled',
                },
            ];
        },
        qualityTime: function () {
            if (this.recordingData.data.editQuality === 0) {
                return 'max.';
            } else if (this.recordingData.data.editQuality === 1) {
                return 'ca. ';
            } else {
                return 'mind. ';
            }
        },
        qualityName: function () {
            if (this.recordingData.data.editQuality === 0) {
                return 'Dein Gold-Edit';
            } else if (this.recordingData.data.editQuality === 1) {
                return 'Dein Platin-Edit';
            } else {
                return 'Dein Diamond-Edit';
            }
        },
        editTime: function () {
            return this.configuratorLogic.methods
                .calculateEditTime(
                    this.recordingData.data.isEdit,
                    this.recordingData.data.songs,
                    this.recordingData.data.recordingType,
                    this.recordingData.data.musicians,
                    this.recordingData.data.editQuality
                )
                .toPrecision(2);
        },
    },
};
</script>

<style>
.greyscale {
    filter: gray; /* IE6-9 */
    -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
    filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
}
</style>
