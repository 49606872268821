<template>
    <v-card class="pa-0 mx-auto" style="width: 100%">
        <v-list class="mt-6 py-0">
            <v-list-item>
                <v-list-item-content class="py-2">
                    <v-list-item-subtitle class="text-h6"
                        >Aufwand für</v-list-item-subtitle
                    >
                </v-list-item-content>
                <v-list-item-content>
                    <v-list-item-subtitle class="text-h6 text-right">
                        Studiozeit
                        <v-tooltip bottom max-width="300">
                            <template #activator="{ on }">
                                <v-icon color="primary" small v-on="on"
                                    >mdi-information-outline</v-icon
                                >
                            </template>
                            <span>Ein Aufnahmetag umfasst 8 Stunden.</span>
                        </v-tooltip>
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-subtitle>Recording</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content>
                    <v-list-item-subtitle class="subtitle-1 text-right">
                        {{ recordingTime }}
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-subtitle>
                        Nachbearbeitung</v-list-item-subtitle
                    >
                </v-list-item-content>
                <v-list-item-content>
                    <v-list-item-subtitle class="subtitle-1 text-right">
                        {{ postTime }}
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-card>
</template>

<script>
export default {
    inject: ['recordingData'],
    methods: {
        SplitTime(numberOfHours) {
            var Days = Math.floor(numberOfHours / 8);
            var Remainder = numberOfHours % 8;
            var Hours = Math.floor(Remainder);
            var Minutes = Math.floor(60 * (Remainder - Hours));
            return { Days: Days, Hours: Hours, Minutes: Minutes };
        },
    },
    computed: {
        recordingTime: function () {
            let returnString = '';
            let recordingTime = this.SplitTime(
                Math.ceil(this.recordingData.data.recordingTime)
            );
            switch (recordingTime.Days) {
                case 0:
                    break;
                case 1:
                    returnString = recordingTime.Days + ' Tag ';
                    break;
                default:
                    returnString = recordingTime.Days + ' Tage ';
                    break;
            }

            if (recordingTime.Days !== 0 && recordingTime.Hours !== 0)
                returnString += 'und ';

            switch (recordingTime.Hours) {
                case 0:
                    break;
                case 1:
                    returnString += recordingTime.Hours + ' Stunde';
                    break;
                default:
                    returnString += recordingTime.Hours + ' Stunden';
                    break;
            }
            return returnString;
        },
        postTime: function () {
            let returnString = '';
            let postTime = this.SplitTime(
                Math.ceil(this.recordingData.data.postTime)
            );
            switch (postTime.Days) {
                case 0:
                    break;
                case 1:
                    returnString = postTime.Days + ' Tag ';
                    break;
                default:
                    returnString = postTime.Days + ' Tage ';
                    break;
            }
            if (postTime.Days !== 0 && postTime.Hours !== 0)
                returnString += 'und ';
            switch (postTime.Hours) {
                case 0:
                    break;
                case 1:
                    returnString += postTime.Hours + ' Stunde';
                    break;
                default:
                    returnString += postTime.Hours + ' Stunden';
                    break;
            }
            return returnString;
        },
    },
};
</script>
