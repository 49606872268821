<template>
    <v-btn
        color="primary"
        elevation="5"
        x-large
        class="mx-auto"
        @click="onSubmit"
        :disabled="disabled"
    >
        {{ text }}
    </v-btn>
</template>

<script>
export default {
    inject: ['configuratorState'],
    components: {},
    props: {
        disabled: Boolean,
    },
    methods: {
        onSubmit() {
            this.$emit('validateNext');
        },
    },
    computed: {
        text: function () {
            if (
                this.configuratorState.states.currentState === 'summary' ||
                this.configuratorState.states.currentState === 'confirmation'
            ) {
                return 'Absenden';
            } else {
                return 'Weiter';
            }
        },
    },
};
</script>
