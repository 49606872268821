var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{staticClass:"mt-6"},[_c('v-card',{staticClass:"pa-7 mx-auto",staticStyle:{"padding-left":"100px","padding-right":"100px"}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-subheader',{staticStyle:{"height":"100%"},attrs:{"text-center":""}},[_c('h4',{staticClass:"subheading",staticStyle:{"text-align":"left"}},[_vm._v(" Wenn alle Einstellungen Deinen Wünschen entsprechen, nenne uns zu guter Letzt noch Deinen Namen und Deine E-Mail-Adresse. Nach Absenden erhältst Du eine automatische E-Mail mit Deiner individuellen Konfiguration von uns. In dieser E-Mail kannst Du die Konfiguration mit nur einem Klick bestätigen und somit eine unverbindliche Anfrage an das Studio schicken. Wir werden uns anschließend zeitnah bei Dir zurückmelden. ")])])],1),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"rules":{
                        required: true,
                    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Name","maxlength":"30","counter":30,"placeholder":"Gib Deinen Namen ein","error-messages":errors[0]},model:{value:(_vm.recordingData.data.name),callback:function ($$v) {_vm.$set(_vm.recordingData.data, "name", $$v)},expression:"recordingData.data.name"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"rules":{
                        required: true,
                        email: true,
                    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"E-Mail","placeholder":"Gib Deine E-Mail ein","error-messages":errors[0]},model:{value:(_vm.recordingData.data.email),callback:function ($$v) {_vm.$set(_vm.recordingData.data, "email", $$v)},expression:"recordingData.data.email"}})]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }