import Vue from 'vue';
import sendData from '../rest/sendData';
const states = Vue.observable({
    counter: 0,
    validStates: [
        'recordingtype',
        'band',
        'songs',
        'post',
        'summary',
        'confirmation',
    ],
    currentState: 'recordingtype',
    beenToSummary: false,
    goBack: false,
    end: false,
    showPrice: false,
});

const methods = {
    async goNext() {
        // if on confirmation site -> send feedback
        if (states.currentState === 'confirmation') {
            sendData.methods.sendFeedback();
            states.end = true;
            // if on summary site -> send mail to band
        } else if (states.currentState === 'summary') {
            // if message is successfully sent
            let status = await sendData.methods.sendOffer();
            console.log(status);
            if (status) {
                states.goBack = false;
                states.counter++;
                states.currentState = states.validStates[states.counter];
                return true;
                // if message is not sent
            } else {
                return false;
            }
            // if on any other site than summary or confirmation
        } else {
            states.goBack = false;
            states.counter++;
            states.currentState = states.validStates[states.counter];
        }
        return true;
    },
    goBack() {
        if (states.counter > 0) {
            states.goBack = true;
            states.counter--;
            states.currentState = states.validStates[states.counter];
        }
    },
    setBandState() {
        this.setState('band', true);
    },
    setSongsState() {
        this.setState('songs', true);
    },
    setPostState() {
        this.setState('post', true);
    },
    setSummaryState() {
        this.setState('summary', false);
    },
    setRecordingtypeState() {
        this.setState('recordingtype', false);
    },
    setState(state, goBack) {
        states.goBack = goBack;
        states.counter = states.validStates.indexOf(state);
        states.currentState = states.validStates[states.counter];
    },
    reset() {
        states.counter = 0;
        states.currentState = states.validStates[states.counter];
        states.beenToSummary = false;
        states.end = false;
        states.showPrice = false;
    },
};

export default {
    states,
    methods,
};
