import basePrices from './basePrices';
import factorConstants from './factorConstants';
import timeConstants from './timeConstants';

///////////////// Price Calculations ///////////////////

const methods = {
    /**
     * calculate the discount based on the price
     *
     * @param {int} price - of the recording
     * @returns {int} new price
     */
    calculateDiscount(price) {
        if (price >= basePrices.data.highDiscountLimit) {
            return Math.round(price * basePrices.data.highDiscount);
        } else if (price >= basePrices.data.mediumDiscountLimit) {
            return Math.round(price * basePrices.data.mediumDiscount);
        } else if (price >= basePrices.data.lowDiscountLimit) {
            return Math.round(price * basePrices.data.lowDiscount);
        } else {
            return price;
        }
    },

    /**
     * calculate the recording price based on the time
     *
     * @param {int} recordingTime - time it takes to record
     * @param {float} producerPrice - the price for the producer
     * @returns {int} price
     */
    calculateRecordingPrice(
        recordingTime,
        producerPrice,
        timeOfDay,
        recordingType
    ) {
        let recordingPrice =
            recordingTime * basePrices.data.recordingHour + producerPrice;
        return Math.round(
            calculateTimeOfDayFactor(recordingPrice, timeOfDay, recordingType)
        );
    },

    /**
     * calculate the price of the master recording
     *
     * @param {bool} isMix - if a mix is wanted
     * @param {bool} master - if a master is wanted
     * @param {Object} songs - songs Object
     * @param {int} numberOfStems - numberOfStems
     * @returns {float} price for the master
     */
    calculateMasterPrice(isMix, master, songs, numberOfStems, isStem) {
        if (isMix) {
            if (!isStem && master) {
                return getNumberOfSongs(songs) * basePrices.data.standardMaster;
            } else if (master) {
                return (
                    getNumberOfSongs(songs) * basePrices.data.standardMaster +
                    basePrices.data.stemMaster * numberOfStems
                );
            } else {
                return 0.0;
            }
        } else {
            return 0.0;
        }
    },

    /**
     * calculate the price of the ddp part of the master
     *
     * @param {bool} isMaster - if a master is wanted
     * @param {bool} ddp - if ddp is wanted
     * @param {Object} songs - song collection Object
     * @returns {int} price for the ddp master
     */
    calculateDDP(isMaster, ddp, songs) {
        if (isMaster) {
            if (ddp && getNumberOfSongs(songs) > 1) {
                return (
                    basePrices.data.ddpMaster +
                    basePrices.data.ddpMasterSong * getNumberOfSongs(songs)
                );
            } else if (ddp) {
                return basePrices.data.ddpMaster;
            } else {
                return 0;
            }
        } else {
            return 0.0;
        }
    },

    /**
     * calculate the price for the revisions
     *
     * @param {bool} isMix - if a mix is wanted
     * @param {int} revisions - how many revisions are wanted
     * @returns {float} price for the revisions
     */
    calculateRevision(isMix, revisions) {
        if (isMix) {
            if (revisions > 1) {
                return (revisions - 1) * basePrices.data.revision;
            } else {
                return 0.0;
            }
        } else {
            return 0.0;
        }
    },

    /**
     * calculate mix price
     *
     * @param {float} mixTime - time the mix takes
     * @returns {int} price for the mix
     */
    calculateMixPrice(mixTime) {
        return Math.round(mixTime * basePrices.data.mixHour);
    },

    /**
     * calculate the edit price
     *
     * @param {flaot} editTime - time the edit takes
     * @returns {int} price for the edit
     */
    calculateEditPrice(editTime) {
        return Math.round(editTime * basePrices.data.editHour);
    },

    /**
     * calculate the cost of the producer
     *
     * @param {bool} producer - if a producer is wanted
     * @param {float} fullRecordingTime - time the full recording takes
     * @returns {int} the price for the producer
     */
    calculateProducerPrice(producer, fullRecordingTime) {
        if (producer) {
            return Math.round(fullRecordingTime * basePrices.data.producerHour);
        } else {
            return 0.0; //TODO: check if this is actually a float
        }
    },

    calculateMelodynePrice(isEdit, melodyne, songs) {
        if (melodyne && isEdit) {
            return Math.round(
                getNumberOfSongs(songs) * basePrices.data.melodyne
            );
        } else {
            return 0.0;
        }
    },
    calculateBruttoPrice(nettoPrice) {
        return Math.round((nettoPrice * 1.19 + Number.EPSILON) * 100) / 100;
    },

    calculateFinalPrice(
        recordingPrice,
        editPrice,
        mixPrice,
        masterPrice,
        ddpPrice,
        revisionPrice,
        melodynePrice
    ) {
        let postPrice =
            editPrice +
            mixPrice +
            masterPrice +
            ddpPrice +
            revisionPrice +
            melodynePrice;
        return recordingPrice + postPrice;
    },

    ////////////// Time Calculations ////////////////

    calculateSetupTime(bandInstruments) {
        let setupTime = timeConstants.data.aufbauBasisZeit;
        bandInstruments.forEach((instrument) => {
            let instrumentString = 'aufbau' + instrument;
            setupTime += timeConstants.data[instrumentString];
        });
        return setupTime;
    },

    calculateRecordingTimeOverdub(songs, musicians) {
        let recordingTime = 0.0;
        // get every song
        for (const song of Object.entries(songs)) {
            let songRecordingTime = 0.0;
            // get every instrument
            song[1].instruments.forEach((instrument) => {
                // split the musician name
                let instrumentMusician = getInstrumentAndMusician(instrument);
                let instrumentRecTimeString =
                    'recording' +
                    instrumentMusician.instrument +
                    getExperience(musicians, instrumentMusician.musician);
                let instrumentRecordingTime =
                    timeConstants.data[instrumentRecTimeString];
                songRecordingTime += instrumentRecordingTime;
            });
            songRecordingTime = calculateSongLengthRecordingFactorOverdub(
                songRecordingTime,
                song[1].length,
                'overdub'
            );
            recordingTime += songRecordingTime;
        }
        return recordingTime;
    },

    calculateMusicianRecordingTime(songs, musicians) {
        let musiciansRecordingTime = {};
        // get every song
        for (const song of Object.entries(songs)) {
            // get every instrument
            song[1].instruments.forEach((instrument) => {
                // split the musician name
                let instrumentMusician = getInstrumentAndMusician(instrument);
                let instrumentRecTimeString =
                    'recording' +
                    instrumentMusician.instrument +
                    getExperience(musicians, instrumentMusician.musician);

                let instrumentRecordingTime =
                    timeConstants.data[instrumentRecTimeString];

                // if musician not exists, create it
                if (!(instrumentMusician.musician in musiciansRecordingTime)) {
                    musiciansRecordingTime[instrumentMusician.musician] = {};

                    // if instrument in musician not exists, create it
                }
                if (
                    !(
                        instrumentMusician.instrument in
                        musiciansRecordingTime[instrumentMusician.musician]
                    )
                ) {
                    musiciansRecordingTime[instrumentMusician.musician][
                        instrumentMusician.instrument
                    ] = 0.0;
                }
                // set the recording time
                musiciansRecordingTime[instrumentMusician.musician][
                    instrumentMusician.instrument
                ] += instrumentRecordingTime;
                // calculate the setup time for the musician
                let instrumentString = 'aufbau' + instrumentMusician.instrument;
                let setupTime = timeConstants.data[instrumentString];
                musiciansRecordingTime[instrumentMusician.musician][
                    instrumentMusician.instrument
                ] += setupTime;
            });
        }
        return musiciansRecordingTime;
    },

    calculateRecordingTimeLive(songs, musicians) {
        let accessString =
            'recording' +
            getMaxSongLength(songs) +
            getLowestExperience(musicians);
        return getNumberOfSongs(songs) * timeConstants.data[accessString];
    },

    calculateEditTime(isEdit, songs, recordingType, musicians, quality) {
        if (isEdit) {
            let editTime = 0.0;
            // get every song
            for (const song of Object.entries(songs)) {
                let songEditTime = 0.0;
                // get every instrument
                song[1].instruments.forEach((instrument) => {
                    // split the musician name
                    let instrumentMusician =
                        getInstrumentAndMusician(instrument);
                    let accessString =
                        'edit' + instrumentMusician.instrument + recordingType;
                    let instrumentEditTime = timeConstants.data[accessString];
                    instrumentEditTime = calculateEditExperienceFactor(
                        instrumentEditTime,
                        getExperience(musicians, instrumentMusician.musician),
                        recordingType
                    );
                    songEditTime += instrumentEditTime;
                });
                songEditTime = calculateSongLengthPostFactor(
                    songEditTime,
                    getSongLength(song[1].length),
                    recordingType
                );
                editTime += songEditTime;
            }
            editTime = calculateEditQualityFactor(
                editTime,
                getQuality(quality),
                recordingType
            );
            return editTime;
        } else {
            return 0.0;
        }
    },

    calculateMixTime(isMix, songs, recordingType, musicians, quality) {
        if (isMix) {
            let mixTime = 0.0;
            // get every song
            for (const song of Object.entries(songs)) {
                let songMixTime = 0.0;
                // get every instrument
                song[1].instruments.forEach((instrument) => {
                    // split the musician name
                    let instrumentMusician =
                        getInstrumentAndMusician(instrument);
                    let accessString =
                        'mix' + instrumentMusician.instrument + recordingType;
                    let instrumentMixTime = timeConstants.data[accessString];

                    instrumentMixTime = calculateMixExperienceFactor(
                        instrumentMixTime,
                        getExperience(musicians, instrumentMusician.musician),
                        recordingType
                    );
                    songMixTime += instrumentMixTime;
                });
                songMixTime = calculateSongLengthPostFactor(
                    songMixTime,
                    getSongLength(song[1].length),
                    recordingType
                );
                mixTime += songMixTime;
            }
            mixTime = calculateMixQualityFactor(
                mixTime,
                getQuality(quality),
                recordingType
            );
            return mixTime;
        } else {
            return 0.0;
        }
    },
};
//////////// Factor Calculations ////////////////

function calculateSongLengthRecordingFactorOverdub(
    recordingTime,
    maxSongLength
) {
    let accessString = 'songLengthRecording' + maxSongLength;
    return recordingTime * factorConstants.data[accessString];
}

function calculateSongLengthPostFactor(postTime, maxSongLength, recordingType) {
    let accessString = 'songLengthPost' + recordingType + maxSongLength;
    return postTime * factorConstants.data[accessString];
}

function calculateTimeOfDayFactor(recordingPrice, timeOfDay) {
    if (timeOfDay) {
        return Math.round(recordingPrice * factorConstants.data['night']);
    } else {
        return Math.round(recordingPrice * factorConstants.data['day']);
    }
}

function calculateEditQualityFactor(editTime, quality, recordingType) {
    let accessString = 'edit' + quality + recordingType;
    return editTime * factorConstants.data[accessString];
}

function calculateMixQualityFactor(mixTime, quality, recordingType) {
    let accessString = 'mix' + quality + recordingType;
    return mixTime * factorConstants.data[accessString];
}

function calculateEditExperienceFactor(editTime, experience, recordingType) {
    let accessString = 'edit' + experience + recordingType;
    return editTime * factorConstants.data[accessString];
}

function calculateMixExperienceFactor(mixTime, experience, recordingType) {
    let accessString = 'mix' + experience + recordingType;
    return mixTime * factorConstants.data[accessString];
}

//////////// Helper Functions ////////////////

function getExperience(musicians, musicianID) {
    // get every musician
    // TODO: this will be sooo much better when we can use maps
    for (const musician of Object.entries(musicians)) {
        // TODO: I really hate this. Please change to not string compare or at least proper compare
        if (musicianID == musician[1].id) {
            // map the slider values to the names used in timeConstants.js
            switch (musician[1].experience) {
                case 0:
                    return 'LowXP';
                case 1:
                    return 'MediumXP';
                case 2:
                    return 'HighXP';
                default:
                    return 'MediumXP';
            }
        }
    }
}

function getLowestExperience(musicians) {
    let lowestExperience = 2;
    for (const musician of Object.entries(musicians)) {
        lowestExperience = Math.min(lowestExperience, musician[1].experience);
    }
    switch (lowestExperience) {
        case 0:
            return 'LowXP';
        case 1:
            return 'MediumXP';
        case 2:
            return 'HighXP';
        default:
            return 'MediumXP';
    }
}

function getSongLength(songLength) {
    switch (songLength) {
        case 0:
            return 'Short';
        case 1:
            return 'Normal';
        case 2:
            return 'Long';
        default:
            return 'Normal';
    }
}

function getMaxSongLength(songs) {
    let maxSongLength = 0;
    for (const song of Object.entries(songs)) {
        maxSongLength = Math.max(maxSongLength, song[1].length);
    }
    switch (maxSongLength) {
        case 0:
            return 'Short';
        case 1:
            return 'Normal';
        case 2:
            return 'Long';
        default:
            return 'Normal';
    }
}

function getNumberOfSongs(songs) {
    return Object.keys(songs).length;
}

function getInstrumentAndMusician(instrumentMusicianString) {
    let instrumentMusician = {
        instrument: '',
        musician: '',
    };
    let instrumentMusicianArray = instrumentMusicianString.split(':');
    instrumentMusician.instrument = instrumentMusicianArray[0].trim();
    instrumentMusician.musician = instrumentMusicianArray[1].trim();
    return instrumentMusician;
}

function getQuality(quality) {
    switch (quality) {
        case 0:
            return 'Gold';
        case 1:
            return 'Platin';
        case 2:
            return 'Diamond';
        default:
            return 'Platin';
    }
}
export default {
    methods,
};
