<template>
    <div
        :style="{
            backgroundImage: backgroundRender,
        }"
        class="configurator"
    >
        <ValidationObserver ref="form">
            <form @submit.prevent="onNext">
                <div style="padding-top: 3vh">
                    <transition
                        :name="
                            configuratorState.states.goBack
                                ? 'component-fade-reverse'
                                : 'component-fade'
                        "
                        mode="out-in"
                    >
                        <RecordingTypeComponent
                            v-if="
                                configuratorState.states.currentState ===
                                'recordingtype'
                            "
                            @change:background="changeBackground"
                        >
                        </RecordingTypeComponent>

                        <BandComponent
                            v-if="
                                configuratorState.states.currentState === 'band'
                            "
                            @change:background="changeBackground"
                        >
                        </BandComponent>

                        <SongsComponent
                            v-if="
                                configuratorState.states.currentState ===
                                'songs'
                            "
                            @change:background="changeBackground"
                        >
                        </SongsComponent>

                        <PostComponent
                            v-if="
                                configuratorState.states.currentState === 'post'
                            "
                            @change:background="changeBackground"
                        >
                        </PostComponent>

                        <SummaryComponent
                            v-if="
                                configuratorState.states.currentState ===
                                'summary'
                            "
                            @change:background="changeBackground"
                        >
                        </SummaryComponent>

                        <CustomerFeedbackComponent
                            v-if="
                                configuratorState.states.currentState ===
                                'confirmation'
                            "
                            @change:background="changeBackground"
                        >
                        </CustomerFeedbackComponent>
                    </transition>
                    <v-row align="center" justify="center" class="pb-12">
                        <v-col cols="auto" v-if="showBack">
                            <BackButtonComponent
                                @validateBack="onBack()"
                            ></BackButtonComponent>
                        </v-col>
                        <v-col
                            cols="auto"
                            v-if="showSummary && $vuetify.breakpoint.smAndUp"
                        >
                            <SummaryButtonComponent
                                @validateSummary="onSummary()"
                            >
                            </SummaryButtonComponent>
                        </v-col>
                        <v-col cols="auto" v-if="showNext">
                            <NextButtonComponent
                                @validateNext="onNext()"
                                :disabled="disableNext"
                            >
                            </NextButtonComponent>
                        </v-col>
                        <v-col
                            cols="auto"
                            v-if="showSummary && $vuetify.breakpoint.xsOnly"
                        >
                            <SummaryButtonComponent
                                @validateSummary="onSummary()"
                            >
                            </SummaryButtonComponent>
                        </v-col>
                    </v-row>
                </div>
                <v-snackbar
                    v-model="showMailError"
                    :timeout="timeoutErrorMsg"
                    style="padding: 0"
                    max-width="800px"
                    class="text-body-2 pa-0"
                >
                    {{ errorMessage }}

                    <template v-slot:action="{ attrs }">
                        <v-icon
                            color="primary"
                            v-bind="attrs"
                            v-on:click="showMailError = false"
                            >mdi-close</v-icon
                        >
                    </template>
                </v-snackbar>

                <v-snackbar
                    v-model="showProgressBar"
                    :timeout="timeoutProgressBar"
                    class="pa-0 progressBar"
                    style="padding: 0; opacity: 0.9"
                    width="60vw"
                    max-width="500px"
                    min-height="0"
                >
                    <v-progress-linear
                        :value="getConfiguratorProgress"
                        color="primary"
                        class="pa-0"
                        height="8"
                        striped
                        @click="showProgressBar = false"
                    >
                    </v-progress-linear>

                    <template v-slot:action="{ attrs }">
                        <v-icon
                            color="primary"
                            v-bind="attrs"
                            v-on:click="showProgressBar = false"
                            >mdi-close</v-icon
                        >
                    </template>
                </v-snackbar>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
import BackButtonComponent from '../components/configurator/back_button.vue';
import NextButtonComponent from '../components/configurator/next_button.vue';
import SummaryButtonComponent from '../components/configurator/summary_button.vue';
import RecordingTypeComponent from '../components/configurator/recordingtype.vue';
import BandComponent from '../components/configurator/band.vue';
import SongsComponent from '../components/configurator/songs.vue';
import PostComponent from '../components/configurator/post.vue';
import SummaryComponent from '../components/configurator/summary.vue';
import CustomerFeedbackComponent from '../components/configurator/customerFeedback.vue';
import { ValidationObserver } from 'vee-validate';
export default {
    metaInfo: {
        title: 'Konfiguriere deine Bandaufnahme',
    },
    props: {
        disabled: Boolean,
    },
    inject: ['configuratorState', 'recordingData'],
    data: () => ({
        showMailError: false,
        showProgressBar: true,
        timeoutErrorMsg: 4000,
        timeoutProgressBar: 1500,
        errorMessage: '',
        backgroundColor1: '',
        backgroundColor2: '',
        backgroundRender: '',
        colorArray1: [
            'rgba(255, 173, 173, .75)',
            'rgba(160, 196, 255, .75)',
            'rgba(255, 214, 165, .75)',
            'rgba(202, 255, 191, .75)',
            'rgba(105, 48, 195, .75)',
        ],
        colorArray2: [
            'rgba(253, 255, 182, .8)',
            'rgba(189, 178, 255, .8)',
            'rgba(155, 246, 255, .8)',
            'rgba(160, 196, 255, .8)',
            'rgba(255, 183, 3, .8)',
        ],
    }),
    methods: {
        onNext() {
            this.$refs.form.validate().then((success) => {
                if (success) {
                    if (!this.configuratorState.methods.goNext()) {
                        this.errorMessage =
                            'Bei dem Senden der Mail ist ein Fehler aufgetreten.';
                        this.showMailError = true;
                    }
                } else {
                    this.errorMessage =
                        'Bitte korrigiere die rot markierten Felder!';
                    this.showMailError = true;
                }
            });
        },
        onSummary() {
            this.$refs.form.validate().then((success) => {
                if (success) {
                    this.configuratorState.methods.setSummaryState();
                } else {
                    this.errorMessage =
                        'Bitte korrigiere die rot markierten Felder!';
                    this.showMailError = true;
                }
            });
        },
        onBack() {
            if (this.configuratorState.states.currentState !== 'summary') {
                this.$refs.form.validate().then((success) => {
                    if (success) {
                        this.configuratorState.methods.goBack();
                    } else {
                        this.errorMessage =
                            'Bitte korrigiere die rot markierten Felder!';
                        this.mailError = true;
                    }
                });
            } else {
                this.configuratorState.methods.goBack();
            }
        },
        changeBackground: function () {
            this.backgroundColor1 =
                this.colorArray1[Math.floor(Math.random() * 5)];
            this.backgroundColor2 =
                this.colorArray2[Math.floor(Math.random() * 5)];
            this.backgroundRender =
                'linear-gradient(' +
                this.backgroundColor1 +
                ',' +
                this.backgroundColor2 +
                '), url(' +
                'https://bandrecording-hamburg.de/assets/backgrounds/' +
                Math.floor(Math.random() * 7 + 1) +
                '.jpg)';
            this.showProgressBar = this.configuratorState.states.counter >= 1; // Only show progress bar after first step of configurator
        },
    },
    components: {
        BandComponent,
        SongsComponent,
        PostComponent,
        RecordingTypeComponent,
        SummaryComponent,
        BackButtonComponent,
        SummaryButtonComponent,
        CustomerFeedbackComponent,
        ValidationObserver,
        NextButtonComponent,
    },
    computed: {
        showBack: function () {
            return (
                this.configuratorState.states.currentState !==
                    'recordingtype' &&
                this.configuratorState.states.currentState !== 'confirmation'
            );
        },
        showNext: function () {
            return (
                !this.configuratorState.states.end &&
                this.configuratorState.states.currentState !== 'recordingtype'
            );
        },
        showSummary: function () {
            return (
                this.configuratorState.states.beenToSummary &&
                this.configuratorState.states.currentState !== 'summary' &&
                this.configuratorState.states.currentState !== 'confirmation'
            );
        },
        getConfiguratorProgress: function () {
            return (this.configuratorState.states.counter * 100) / 5; // Progress bar changes in 20% steps per configurator step
        },

        disableNext: function () {
            // check if the recordingType, musicians or songs are empty
            if (this.configuratorState.states.currentState === 'band') {
                return (
                    Object.keys(this.recordingData.data.musicians).length <= 0
                );
            } else if (this.configuratorState.states.currentState === 'songs') {
                return Object.keys(this.recordingData.data.songs).length <= 0;
            } else {
                return this.recordingData.data.recordingType === '';
            }
        },
        text: function () {
            return this.configuratorState.states.currentState === 'summary'
                ? 'Absenden'
                : 'Weiter';
        },
    },
};
</script>

<style>
.configurator {
    margin: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: top;
    background-position-x: center;
    width: 100%;
    min-height: 100vh;
}
.v-snack__content {
    padding-top: 0;
    padding-bottom: 0;
}
.component-fade-enter-active,
.component-fade-leave-active {
    transition: all 0.2s ease;
}
.component-fade-enter {
    transform: translateX(100%);
    opacity: 0;
}
.component-fade-leave-to {
    transform: translateX(-100%);
    opacity: 0;
}
.component-fade-reverse-enter-active,
.component-fade-reverse-leave-active {
    transition: all 0.2s ease;
}
.component-fade-reverse-enter {
    transform: translateX(-100%);
    opacity: 0;
}
.component-fade-reverse-leave-to {
    transform: translateX(100%);
    opacity: 0;
}
</style>
