<template>
    <v-container fluid class="py-4 px-0 px-sm-4 my-auto">
        <v-card dir style="margin: auto" min-width="250px" max-width="730px">
            <v-card-title class="justify-center text-h5"
                >Deine Band</v-card-title
            >
            <v-card-text class="justify text-subtitle-2">
                Als Nächstes geht es voll und ganz um Deine Band. Füge
                Bandmitglieder über den
                <v-icon color="primary" small>mdi-plus-circle</v-icon>
                Button hinzu und wähle ihre Instrumente aus. Gebe außerdem an,
                wie erfahren ihr bereits im Studio seid. Sollte eine Einstellung
                unklar sein, erhältst Du bei dem
                <v-icon color="primary" small>mdi-information-outline</v-icon>
                Hilfe. Um auf die nächste Seite zu kommen, drücke auf den Weiter Button.
            </v-card-text>
        </v-card>
        <v-container class="mx-auto px-0 px-sm-auto">
            <v-row align="center" justify="center">
                <MusicianComponent
                    v-for="musician in recordingData.data.musicians"
                    :key="musician.id"
                    :musician="musician"
                    :availableInstruments="availableInstruments"
                    @update:musician="addMusician"
                    @remove:musician="removeComponent"
                    @filterSongInstruments="
                        recordingData.methods.filterSongInstruments()
                    "
                >
                </MusicianComponent>
            </v-row>
            <v-row align="center" justify="center">
                <v-btn
                    color="primary"
                    fab
                    class="ma-3"
                    v-on:click="addComponent"
                    :disabled="recordingData.data.numberOfMusicians >= 8"
                >
                    <v-icon large>mdi-plus</v-icon>
                </v-btn>
            </v-row>
        </v-container>
        <v-snackbar v-model="lastMusicianWarning" timeout="2000">
            {{ warningText }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    color="primary"
                    icon
                    v-bind="attrs"
                    @click="lastMusicianWarning = false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </v-container>
</template>

<script>
import MusicianComponent from './musician.vue';

export default {
    inject: ['recordingData'],
    components: {
        MusicianComponent,
    },
    data: function () {
        return {
            headline: 'Wie viele Musiker:innen seid ihr?',
            lastMusicianWarning: false,
            warningText: 'Es muss mindestens ein Musiker in der Band sein.',
            bandInstruments: [],
            availableInstruments: [
                'Drums',
                'Bass',
                'EGuitar',
                'Vocals',
                'AGuitar',
                'BackingVocals',
                'Keys',
                'Strings',
                'Horns',
                'Other',
            ],
        };
    },
    methods: {
        addComponent() {
            let instruments = [
                this.availableInstruments[
                    this.recordingData.data.musicianID %
                        this.availableInstruments.length
                ],
            ];
            this.recordingData.methods.incrementMusicianID();
            let defaultMusician = {
                id: this.recordingData.data.musicianID,
                name: 'Musiker:in ' + this.recordingData.data.musicianID,
                instruments: instruments,
                experience: 0,
                recordingTime: {},
            };
            this.addMusician(defaultMusician);
            this.recordingData.data.numberOfMusicians += 1;
        },

        removeComponent(id) {
            if (Object.entries(this.recordingData.data.musicians).length > 1) {
                this.recordingData.methods.removeMusician(id);
                this.recordingData.data.numberOfMusicians -= 1;
            } else {
                this.lastMusicianWarning = true;
            }
        },

        addMusician(musician) {
            this.recordingData.methods.addMusician(musician);
        },
    },
    watch: {
        recordingData: {
            handler: function () {
                this.bandInstruments =
                    this.recordingData.methods.createInstrumentArray();
            },
            deep: true,
        },
    },
    created() {
        this.$emit('change:background');
        window.scrollTo(0, 0);
    },
};
</script>
<style scoped>
.musiciansContainer {
    display: flex;
}

.v-btn--outlined {
    border: medium solid currentColor;
}
</style>
