<template>
    <v-container fluid class="py-4 px-0 px-sm-4 my-auto">
        <v-card dir style="margin: auto" min-width="250px" max-width="730px">
            <v-card-title class="justify-center text-h5">Songs</v-card-title>
            <v-card-text class="text-justify text-subtitle-2"
                >Nun geht es um die Songs Eures Bandrecordings. Füge sie über
                den
                <v-icon color="primary" small>mdi-plus-circle</v-icon>
                Button hinzu und wähle die eingesetzten Instrumente sowie die
                Länge der Songs. Ab diesem Schritt erscheint live eine
                Preiskalkulation Deines Bandrecordings.
            </v-card-text>
        </v-card>
        <v-container class="mx-auto px-0 px-sm-auto">
            <v-row align="center" justify="center">
                <SongComponent
                    v-for="song in recordingData.data.songs"
                    :key="song.id"
                    :song="song"
                    :bandInstruments="bandInstruments"
                    @update:song="addSong"
                    @remove:song="removeComponent"
                >
                </SongComponent>
            </v-row>
            <v-row align="center" justify="center">
                <v-btn
                    color="primary"
                    fab
                    class="ma-3"
                    v-on:click="addComponent"
                    :disabled="recordingData.data.numberOfSongs >= 12"
                >
                    <v-icon large>mdi-plus</v-icon>
                </v-btn>
            </v-row>
        </v-container>
        <v-snackbar v-model="lastSongWarning" timeout="2000">
            {{ warningText }}

            <template v-slot:action="{ attrs }">
                <v-btn
                    color="primary"
                    icon
                    v-bind="attrs"
                    @click="lastSongWarning = false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </v-container>
</template>

<script>
import SongComponent from './song.vue';
export default {
    inject: ['recordingData', 'configuratorState'],
    components: {
        SongComponent,
    },
    props: {
        /*recordingSongs: Number*/
    },
    data: function () {
        let bandInstruments =
            this.recordingData.methods.createInstrumentArray();
        return {
            bandInstruments,
            lastSongWarning: false,
            warningText: 'Ihr müsst mindestens einen Song aufnehmen',
        };
    },
    methods: {
        addComponent() {
            this.recordingData.methods.incrementSongID();
            let defaultSong = {
                id: this.recordingData.data.songID,
                number: this.recordingData.data.numberOfSongs,
                name: 'Song ' + this.recordingData.data.songID,
                length: 1,
                instruments: this.bandInstruments,
            };
            this.addSong(defaultSong);
            if (!this.configuratorState.states.showPrice)
                this.configuratorState.states.showPrice = true;
            this.recordingData.data.numberOfSongs += 1;
        },

        removeComponent(id) {
            if (Object.entries(this.recordingData.data.songs).length > 1) {
                this.recordingData.methods.removeSong(id);
                this.recordingData.data.numberOfSongs -= 1;
            } else {
                this.lastSongWarning = true;
            }
        },

        addSong(song) {
            this.recordingData.methods.addSong(song);
        },
    },
    created() {
        this.$emit('change:background');
        window.scrollTo(0, 0);
    },
};
</script>

<style scoped>
.songsContainer {
    display: flex;
}

.v-btn--outlined {
    border: medium solid currentColor;
}
</style>
