var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-scroll-x-transition',[_c('v-col',{staticClass:"px-0 px-sm-3",attrs:{"cols":"auto "}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"pa-0 mx-auto white",attrs:{"max-width":"350px","min-width":"320px","min-height":"550px","elevation":hover ? 12 : 2}},[_c('v-img',{attrs:{"height":"200px","src":_vm.changeBackground(),"gradient":"to bottom, rgba(255,255,255,.0), rgba(255,255,255,1)"}},[_c('v-app-bar',{attrs:{"flat":"","color":"rgba(0, 0, 0, 0)"}},[_c('v-spacer'),_c('v-icon',{attrs:{"color":"primary"},on:{"click":_vm.removeMusician}},[_vm._v("mdi-close")])],1),_c('v-card-title',{staticClass:"mt-13"},[_c('v-avatar',{attrs:{"size":"56","color":"rgba(255, 255, 255, 0.5)"}},[_c('v-img',{attrs:{"alt":"user","src":require("../../assets/default_musician.png"),"gradient":"to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"}})],1),_c('ValidationProvider',{attrs:{"rules":{
                                required: true,
                            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ml-3",attrs:{"label":"Name des Bandmitglieds","maxlength":"16","counter":16,"hint":"Erleichtert die Zuordnung","placeholder":"Musiker:in Name","error-messages":errors[0]},model:{value:(_vm.musician.name),callback:function ($$v) {_vm.$set(_vm.musician, "name", $$v)},expression:"musician.name"}})]}}],null,true)})],1)],1),_c('v-subheader',[_vm._v(" Hat "+_vm._s(_vm.musician.name)+" bereits Studioerfahrung? "),_c('v-tooltip',{attrs:{"bottom":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"primary","small":"","right":""}},on),[_vm._v("mdi-information-outline")])]}}],null,true)},[_c('span',[_vm._v("Wie häufig war "+_vm._s(_vm.musician.name)+" schon in einer Aufnahmesituation? Kann "+_vm._s(_vm.musician.name)+" dabei über einen längeren Zeitraum fehlerfrei spielen? Je mehr Studioerfahrung ihr mitbringt, desto reibungsloser und schneller verläuft erfahrungsgemäß die Aufnahme, was im Gesamtpreis berücksichtigt wird. ")])])],1),_c('v-card-text',[_c('v-slider',{attrs:{"max":2,"step":"1","tick-labels":_vm.experienceLabels,"tick-size":"4"},model:{value:(_vm.musician.experience),callback:function ($$v) {_vm.$set(_vm.musician, "experience", $$v)},expression:"musician.experience"}})],1),_c('v-divider'),_c('v-subheader',[_vm._v("Wähle die Instrumente von "+_vm._s(_vm.musician.name))]),_c('v-card-text',[_c('v-chip-group',{attrs:{"color":"orange","column":"","center":"","multiple":"","mandatory":"","active-class":"active_chip"},model:{value:(_vm.musician.instruments),callback:function ($$v) {_vm.$set(_vm.musician, "instruments", $$v)},expression:"musician.instruments"}},_vm._l((_vm.availableInstruments),function(instrument){return _c('v-chip',{key:instrument,attrs:{"small":"","value":instrument},on:{"input":function($event){return _vm.$emit('filterSongInstruments')}}},[_vm._v(" "+_vm._s(_vm.recordingData.instrumentDict[instrument])+" ")])}),1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }