import Vue from 'vue';

const data = Vue.observable({
    general: 0,
    design: 0,
    price: 0,
    options: 2,
    text: '',
});

export default {
    data: data,
};
