<template>
    <v-menu offset-y offset-x open-on-hover>
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                :class="
                    $vuetify.breakpoint.xs
                        ? 'myColor-xs-and-down'
                        : 'myColor-over-xs'
                "
                style="
                    background-color: #ffbf66;
                    box-shadow: none;
                    z-index: 999;
                "
                v-bind="attrs"
                v-on="on"
            >
                <span class="hidden-xs-only text-subtitle-2 text-capitalize">
                    Preisrechner: {{ currencyNoFraction.format(bruttoPrice) }}
                    <v-icon right v-on="on">mdi-menu-down</v-icon>
                </span>
                <span class="hidden-sm-and-up text-caption">
                    {{ currencyNoFraction.format(bruttoPrice) }}
                </span>
            </v-btn>
            <span
                id="priceChange"
                :class="' text-h6 text-capitalize ' + priceChange.color"
            >
                {{ priceChange.change }}
            </span>
            <div class="shadow"></div>
        </template>
        <v-list>
            <v-list-item link dense style="cursor: pointer">
                <v-list-item-content>
                    <v-list-item-title class="text-body"
                        >für Aufnahme
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-body-2">
                        {{ currency.format(recordingPrice) }}
                    </v-list-item-subtitle></v-list-item-content
                >
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item link dense style="cursor: pointer">
                <v-list-item-content>
                    <v-list-item-title class="text-body"
                        >für Nachbearbeitung
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-body-2">
                        {{ currency.format(postPrice) }}
                    </v-list-item-subtitle></v-list-item-content
                >
            </v-list-item>
            <v-divider v-if="recordingData.prices.discount !== 0"></v-divider>
            <v-list-item dense v-if="recordingData.prices.discount !== 0">
                <v-list-item-content>
                    <v-list-item-title class="text-body"
                        >Rabatt:
                        <v-tooltip bottom max-width="300">
                            <template #activator="{ on }">
                                <v-icon color="primary" small v-on="on"
                                    >mdi-information-outline</v-icon
                                >
                            </template>
                            <span
                                ><b>Rabatte:</b><br />
                                Ab 2.000,- Netto Euro gibt es 5% Rabatt.<br />
                                Ab 5.000,- Netto Euro gibt es 10% Rabatt.<br />
                                Ab 7.500,- Netto Euro gibt es 15% Rabatt.<br />
                            </span>
                        </v-tooltip>
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-body-2">
                        -{{ currency.format(nettoPrice - priceWithDiscount) }}
                    </v-list-item-subtitle></v-list-item-content
                >
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item dense link>
                <v-list-item-content>
                    <v-list-item-title class="text-body"
                        >Nettopreis
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-body-2">
                        {{ currency.format(priceWithDiscount) }}
                    </v-list-item-subtitle></v-list-item-content
                >
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item link style="cursor: pointer">
                <v-list-item-content>
                    <v-list-item-title class="text-subtitle-2"
                        >Gesamtpreis
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-subtitle-2">
                        {{ currency.format(bruttoPrice) }}
                    </v-list-item-subtitle></v-list-item-content
                >
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
export default {
    props: {},
    inject: ['configuratorLogic', 'recordingData', 'configuratorState'],
    data: () => ({
        setupTime: 0.0,
        recordingTime: 0.0,
        recordingSetupTime: 0.0,
        editTime: 0.0,
        mixTime: 0.0,

        recordingPrice: 0.0,
        editPrice: 0.0,
        mixPrice: 0.0,
        masterPrice: 0.0,
        ddpPrice: 0.0,
        revisionPrice: 0.0,
        producerPrice: 0.0,
        melodynePrice: 0.0,
        postPrice: 0.0,
        nettoPrice: 0.0,
        mwst: 0.0,
        bruttoPrice: 0.0,
        priceWithDiscount: 0.0,
        previousPrice: 0.0,
        currency: new Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency: 'EUR',
        }),
        currencyNoFraction: new Intl.NumberFormat('de-DE', {
            style: 'currency',
            maximumFractionDigits: 0,
            currency: 'EUR',
        }),
    }),
    emits: [''],
    computed: {
        computedData: function () {
            return this.recordingData.data;
        },
        priceChange: function () {
            let change =
                this.recordingData.prices.bruttoPrice - this.previousPrice;
            let priceChange = {
                change: '',
                color: 'black--text',
            };
            if (change < 0) {
                priceChange.color = 'red--text';
                priceChange.change = this.currencyNoFraction.format(change);
            } else {
                priceChange.color = 'green--text';
                priceChange.change =
                    '+' + this.currencyNoFraction.format(change);
            }
            return priceChange;
        },
        bandInstruments: function () {
            let bandInstruments = [];
            //I developed a deep hatred for how vue2 handles reactivity so this is how I reacted
            Object.entries(this.recordingData.data.musicians).forEach(
                (musician) => {
                    // musician[0] is the id of the musician, index 1 has the object
                    musician[1].instruments.forEach((instrument) => {
                        bandInstruments.push(instrument);
                    });
                }
            );
            return bandInstruments;
        },
        width() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return 130;
                case 'sm':
                    return 200;
                case 'md':
                    return 200;
                case 'lg':
                    return 200;
                case 'xl':
                    return 200;
            }
            return 0;
        },
        songs: function () {
            let defaultSongs = {
                0: {
                    id: 0,
                    number: 0,
                    name: 'Default Song',
                    length: 1,
                    instruments:
                        this.recordingData.methods.createInstrumentArray(),
                },
            };
            // if there are no songs yet use a default song to calculate the price
            return this.recordingData.methods.getNumberOfSongs() > 0
                ? this.recordingData.data.songs
                : defaultSongs;
        },
    },
    methods: {
        getRecordingTime() {
            this.setupTime = this.configuratorLogic.methods.calculateSetupTime(
                this.bandInstruments
            );

            if (this.recordingData.data.recordingType === 'Overdub') {
                this.recordingTime =
                    this.configuratorLogic.methods.calculateRecordingTimeOverdub(
                        this.songs,
                        this.recordingData.data.musicians // TODO: instruments has to be an object because you need for every instrument in every song the experience
                    );
            } else {
                this.recordingTime =
                    this.configuratorLogic.methods.calculateRecordingTimeLive(
                        this.songs,
                        this.recordingData.data.musicians
                    );
            }
            this.recordingSetupTime = this.recordingTime + this.setupTime;

            this.recordingData.data.recordingTime = this.recordingSetupTime;
        },

        getPostTime() {
            this.editTime = this.configuratorLogic.methods.calculateEditTime(
                this.recordingData.data.isEdit,
                this.songs,
                this.recordingData.data.recordingType,
                this.recordingData.data.musicians,
                this.recordingData.data.editQuality
            );

            this.mixTime = this.configuratorLogic.methods.calculateMixTime(
                this.recordingData.data.isMix,
                this.songs,
                this.recordingData.data.recordingType,
                this.recordingData.data.musicians,
                this.recordingData.data.mixQuality
            );

            this.recordingData.data.postTime = this.editTime + this.mixTime;
        },

        getFinalPrice() {
            this.producerPrice =
                this.configuratorLogic.methods.calculateProducerPrice(
                    this.recordingData.data.isProducer,
                    this.setupTime + this.recordingTime
                );

            this.recordingPrice =
                this.configuratorLogic.methods.calculateRecordingPrice(
                    this.recordingTime + this.setupTime,
                    this.producerPrice,
                    this.recordingData.data.isNight,
                    this.recordingData.data.recordingType
                );

            this.editPrice = this.configuratorLogic.methods.calculateEditPrice(
                this.editTime
            );

            this.mixPrice = this.configuratorLogic.methods.calculateMixPrice(
                this.mixTime
            );

            this.masterPrice =
                this.configuratorLogic.methods.calculateMasterPrice(
                    this.recordingData.data.isMix,
                    this.recordingData.data.isMaster,
                    this.songs,
                    this.recordingData.data.stems,
                    this.recordingData.data.isStem
                );

            this.ddpPrice = this.configuratorLogic.methods.calculateDDP(
                this.recordingData.data.isMaster,
                this.recordingData.data.ddp,
                this.songs
            );

            this.revisionPrice =
                this.configuratorLogic.methods.calculateRevision(
                    this.recordingData.data.isMix,
                    this.recordingData.data.revisions
                );

            this.melodynePrice =
                this.configuratorLogic.methods.calculateMelodynePrice(
                    this.recordingData.data.isEdit,
                    this.recordingData.data.melodyne,
                    this.songs
                );

            this.postPrice =
                this.editPrice +
                this.mixPrice +
                this.masterPrice +
                this.ddpPrice +
                this.revisionPrice +
                this.melodynePrice;

            this.nettoPrice =
                this.configuratorLogic.methods.calculateFinalPrice(
                    this.recordingPrice,
                    this.editPrice,
                    this.mixPrice,
                    this.masterPrice,
                    this.ddpPrice,
                    this.revisionPrice,
                    this.melodynePrice
                );
            this.priceWithDiscount =
                this.configuratorLogic.methods.calculateDiscount(
                    this.nettoPrice
                );
            this.bruttoPrice =
                this.configuratorLogic.methods.calculateBruttoPrice(
                    this.priceWithDiscount
                );
            this.mwst =
                Math.round(
                    (this.bruttoPrice -
                        this.priceWithDiscount +
                        Number.EPSILON) *
                        100
                ) / 100;

            // set the prices in the recordingData
            this.recordingData.prices.recordingPrice = this.recordingPrice;
            this.recordingData.prices.postPrice = this.postPrice;
            this.recordingData.prices.nettoPrice = this.nettoPrice;
            this.recordingData.prices.mwst = this.mwst;
            this.recordingData.prices.bruttoPrice = this.bruttoPrice;
            this.recordingData.prices.discountPrice = this.priceWithDiscount;
            this.recordingData.prices.discount =
                this.nettoPrice - this.priceWithDiscount;
        },
        calculatePrice() {
            this.getRecordingTime();
            this.getPostTime();
            this.getFinalPrice();
        },
        triggerPriceAnimation() {
            let element = document.getElementById('priceChange');
            // set the position to the mouse coordinates
            document.addEventListener(
                'click',
                (event) => {
                    let mousex = event.clientX; // Gets Mouse X
                    let mousey = event.clientY; // Gets Mouse Y
                    // check if on the right screen third
                    if (mousex > (window.innerWidth / 3) * 2) {
                        // set the price popup to the left
                        element.style.left = mousex - 80 + 'px';
                    } else {
                        element.style.left = mousex + 20 + 'px';
                    }
                    element.style.top = mousey + 20 + 'px';
                },
                { once: true }
            );
            // set the position of the touch coordinates
            document.addEventListener(
                'touchstart',
                (event) => {
                    let mousex = event.touches[0].clientX; // Gets Touch X
                    let mousey = event.touches[0].clientY; // Gets Touch Y
                    // check if on the right screen third
                    if (mousex > (window.innerWidth / 3) * 2) {
                        // set the price popup to the left
                        element.style.left = mousex - 80 + 'px';
                    } else {
                        element.style.left = mousex + 20 + 'px';
                    }
                    element.style.top = mousey + 20 + 'px';
                },
                { once: true }
            );
            // wait for 120ms and restart the animation
            setTimeout(function () {
                element.style.animation = 'none';
                element.offsetHeight; /* trigger reflow */
                element.style.animation = '1.2s popupPrice linear 1';
            }, 180);
            return;
        },
    },
    // watch the recordingData and run a function when it gets updated
    watch: {
        computedData: {
            handler: function () {
                let temp = this.recordingData.prices.bruttoPrice;
                this.calculatePrice();
                // watcher triggers multiple times. So check if the brutto price actually changes from the previous price
                if (temp !== this.recordingData.prices.bruttoPrice) {
                    this.previousPrice = temp;
                    this.triggerPriceAnimation();
                }
            },
            deep: true,
        },
    },
    created() {
        if (this.recordingData.data.recordingType !== '') {
            this.calculatePrice();
        }
    },
};
</script>

<style>
.summary {
    position: absolute;
    text-align: left;
    bottom: 15%;
    left: 2%;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    border-radius: 2px;
    -webkit-box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.4);
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.4);
    min-width: 18%;
}

.summary ul {
    list-style-type: none;
    font-weight: 100;
}

.summary ul li {
    text-transform: capitalize;
    font-size: 0.8vw;
    cursor: pointer;
    margin: 0.3%;
    padding: 0 0.5% 0 2%;
}

.summary ul li:hover {
    background: rgba(255, 116, 0, 0.6);
    border-radius: 2px;
    padding: 0 0.5% 0 2%;
}
.shadow::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    box-shadow: 0px 0px 0px calc(100vh + 100vw) rgba(0, 0, 0, 0.8);
    animation: 2.2s fade ease 1;
}

.myColor-xs-and-down {
    animation: 2s move_xs ease 1;
}

.myColor-over-xs {
    z-index: 300;
    animation: 2s move ease 1;
}

.myColor-over-xs::after {
    content: '';
    position: absolute;
    width: 180px;
    height: 30px;
    border-radius: 10px;
    top: 0;
    left: 0;
    box-shadow: 0 0 30px 10px #ffbf66;
    opacity: 0;
    animation: 2.2s fade ease 1;
}

.myColor-xs-and-down::after {
    content: '';
    position: absolute;
    width: 60px;
    height: 30px;
    border-radius: 10px;
    top: 0;
    left: 0;
    box-shadow: 0 0 30px 10px #ffbf66;
    opacity: 0;
    animation: 2.2s fade ease 1;
}

#priceChange {
    top: calc(100vh / 2);
    right: 50%;
    position: absolute;
    opacity: 0;
    z-index: 999;
}

@keyframes popupPrice {
    0% {
        opacity: 1;
        transform: scale(100%);
    }
    100% {
        opacity: 0;
        transform: scale(200%);
    }
}

@keyframes fade {
    0% {
        opacity: 1;
    }
    90% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes move {
    0% {
        position: relative;
        left: -50vw;
        top: 20vh;
        transform: translate(65%, 0) scale(180%);
    }
    50% {
        position: relative;
        left: -50vw;
        top: 20vh;
        transform: translate(65%, 0) scale(180%);
    }
    100% {
        position: relative;
        left: 0%;
        top: 0%;
        transform: translate(0, 0);
    }
}

@keyframes move_xs {
    0% {
        position: relative;
        left: -50vw;
        top: 20vh;
        transform: translate(95%, 0) scale(180%);
    }
    50% {
        position: relative;
        left: -50vw;
        top: 20vh;
        transform: translate(95%, 0) scale(180%);
    }
    100% {
        position: relative;
        left: 0%;
        top: 0%;
        transform: translate(0, 0);
    }
}
</style>
