var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"offset-y":"","offset-x":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:_vm.$vuetify.breakpoint.xs
                    ? 'myColor-xs-and-down'
                    : 'myColor-over-xs',staticStyle:{"background-color":"#ffbf66","box-shadow":"none","z-index":"999"}},'v-btn',attrs,false),on),[_c('span',{staticClass:"hidden-xs-only text-subtitle-2 text-capitalize"},[_vm._v(" Preisrechner: "+_vm._s(_vm.currencyNoFraction.format(_vm.bruttoPrice))+" "),_c('v-icon',_vm._g({attrs:{"right":""}},on),[_vm._v("mdi-menu-down")])],1),_c('span',{staticClass:"hidden-sm-and-up text-caption"},[_vm._v(" "+_vm._s(_vm.currencyNoFraction.format(_vm.bruttoPrice))+" ")])]),_c('span',{class:' text-h6 text-capitalize ' + _vm.priceChange.color,attrs:{"id":"priceChange"}},[_vm._v(" "+_vm._s(_vm.priceChange.change)+" ")]),_c('div',{staticClass:"shadow"})]}}])},[_c('v-list',[_c('v-list-item',{staticStyle:{"cursor":"pointer"},attrs:{"link":"","dense":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-body"},[_vm._v("für Aufnahme ")]),_c('v-list-item-subtitle',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(_vm.currency.format(_vm.recordingPrice))+" ")])],1)],1),_c('v-divider'),_c('v-list-item',{staticStyle:{"cursor":"pointer"},attrs:{"link":"","dense":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-body"},[_vm._v("für Nachbearbeitung ")]),_c('v-list-item-subtitle',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(_vm.currency.format(_vm.postPrice))+" ")])],1)],1),(_vm.recordingData.prices.discount !== 0)?_c('v-divider'):_vm._e(),(_vm.recordingData.prices.discount !== 0)?_c('v-list-item',{attrs:{"dense":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-body"},[_vm._v("Rabatt: "),_c('v-tooltip',{attrs:{"bottom":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"primary","small":""}},on),[_vm._v("mdi-information-outline")])]}}],null,false,3307478940)},[_c('span',[_c('b',[_vm._v("Rabatte:")]),_c('br'),_vm._v(" Ab 2.000,- Netto Euro gibt es 5% Rabatt."),_c('br'),_vm._v(" Ab 5.000,- Netto Euro gibt es 10% Rabatt."),_c('br'),_vm._v(" Ab 7.500,- Netto Euro gibt es 15% Rabatt."),_c('br')])])],1),_c('v-list-item-subtitle',{staticClass:"text-body-2"},[_vm._v(" -"+_vm._s(_vm.currency.format(_vm.nettoPrice - _vm.priceWithDiscount))+" ")])],1)],1):_vm._e(),_c('v-divider'),_c('v-list-item',{attrs:{"dense":"","link":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-body"},[_vm._v("Nettopreis ")]),_c('v-list-item-subtitle',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(_vm.currency.format(_vm.priceWithDiscount))+" ")])],1)],1),_c('v-divider'),_c('v-list-item',{staticStyle:{"cursor":"pointer"},attrs:{"link":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-subtitle-2"},[_vm._v("Gesamtpreis ")]),_c('v-list-item-subtitle',{staticClass:"text-subtitle-2"},[_vm._v(" "+_vm._s(_vm.currency.format(_vm.bruttoPrice))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }