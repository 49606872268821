import Vue from 'vue';

import VueRouter from 'vue-router';
import Home from '../pages/Home.vue';
import Configurator from '../pages/Configurator.vue';
import Contact from '../pages/Contact.vue';
import Imprint from '../pages/Imprint.vue';
import Privacy from '../pages/Privacy.vue';
import Confirmation from '../pages/Confirmation.vue';
import Meta from 'vue-meta';

Vue.use(VueRouter);
Vue.use(Meta);

const routes = [
    { path: '/', component: Home },
    { path: '/configurator', component: Configurator },
    { path: '/contact', component: Contact },
    { path: '/imprint', component: Imprint },
    { path: '/privacy', component: Privacy },
    { path: '/confirmation', component: Confirmation },
];

const router = new VueRouter({
    routes,
});

export default router;
