<template>
    <v-scroll-x-transition>
        <v-col cols="auto " class="px-0 px-sm-3">
            <v-hover v-slot="{ hover }">
                <v-card
                    class="pa-0 mx-auto white"
                    max-width="350px"
                    min-width="320px"
                    min-height="550px"
                    :elevation="hover ? 12 : 2"
                >
                    <v-img
                        height="200px"
                        :src="changeBackground()"
                        gradient="to bottom, rgba(255,255,255,.0), rgba(255,255,255,1)"
                    >
                        <v-app-bar flat color="rgba(0, 0, 0, 0)">
                            <v-spacer></v-spacer>
                            <v-icon color="primary" v-on:click="removeMusician"
                                >mdi-close</v-icon
                            >
                        </v-app-bar>
                        <v-card-title class="mt-13">
                            <v-avatar
                                size="56"
                                color="rgba(255, 255, 255, 0.5)"
                            >
                                <v-img
                                    alt="user"
                                    src="../../assets/default_musician.png"
                                    gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
                                />
                            </v-avatar>
                            <ValidationProvider
                                :rules="{
                                    required: true,
                                }"
                                v-slot="{ errors }"
                            >
                                <v-text-field
                                    class="ml-3"
                                    v-model="musician.name"
                                    label="Name des Bandmitglieds"
                                    maxlength="16"
                                    :counter="16"
                                    hint="Erleichtert die Zuordnung"
                                    placeholder="Musiker:in Name"
                                    :error-messages="errors[0]"
                                ></v-text-field>
                            </ValidationProvider>
                        </v-card-title>
                    </v-img>
                    <v-subheader>
                        Hat {{ musician.name }} bereits Studioerfahrung?
                        <v-tooltip bottom max-width="300">
                            <template #activator="{ on }">
                                <v-icon color="primary" small right v-on="on"
                                    >mdi-information-outline</v-icon
                                >
                            </template>
                            <span
                                >Wie häufig war {{ musician.name }} schon in
                                einer Aufnahmesituation? Kann
                                {{ musician.name }} dabei über einen längeren
                                Zeitraum fehlerfrei spielen? Je mehr
                                Studioerfahrung ihr mitbringt, desto
                                reibungsloser und schneller verläuft
                                erfahrungsgemäß die Aufnahme, was im Gesamtpreis
                                berücksichtigt wird.
                            </span>
                        </v-tooltip>
                    </v-subheader>
                    <v-card-text>
                        <v-slider
                            v-model="musician.experience"
                            :max="2"
                            step="1"
                            :tick-labels="experienceLabels"
                            tick-size="4"
                        ></v-slider>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-subheader
                        >Wähle die Instrumente von
                        {{ musician.name }}</v-subheader
                    >
                    <v-card-text>
                        <v-chip-group
                            color="orange"
                            column
                            center
                            multiple
                            mandatory
                            active-class="active_chip"
                            v-model="musician.instruments"
                        >
                            <v-chip
                                small
                                v-for="instrument in availableInstruments"
                                :key="instrument"
                                :value="instrument"
                                @input="$emit('filterSongInstruments')"
                            >
                                {{ recordingData.instrumentDict[instrument] }}
                            </v-chip>
                        </v-chip-group>
                    </v-card-text>
                </v-card>
            </v-hover>
        </v-col>
    </v-scroll-x-transition>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
    inject: ['recordingData'],
    components: {
        ValidationProvider,
    },
    props: { musician: Object, availableInstruments: Array },
    data: function () {
        return {
            musicianType: '',
            path: '',
            experienceLabels: ['keine', 'mittel', 'viel'],
        };
    },
    created() {
        this.musicianType = this.getMusicianType();
        this.$emit('change:background');
        this.initBackground();
    },
    methods: {
        emitMusician() {
            this.$emit('update:musician', this.musician);
        },
        removeMusician() {
            this.$emit('remove:musician', this.musician.id);
        },
        // changes the background when selecting another instrument at instruments[0]
        changeBackground() {
            let type = this.musician.instruments[0]; // gets the picked instrument
            if (this.musicianType != type) {
                // if picked instrument is not stored instrument, set new image
                let imageNumber = Math.floor(Math.random() * 4 + 1);
                this.path =
                    'https://bandrecording-hamburg.de/assets/musician/' +
                    type +
                    '/' +
                    imageNumber +
                    '.jpg';
                this.musicianType = type;
            }
            return this.path;
        },
        // does the initial background for the card
        initBackground() {
            let imageNumber = Math.floor(Math.random() * 4 + 1);
            this.path =
                'https://bandrecording-hamburg.de/assets/musician/' +
                this.musician.instruments[0] +
                '/' +
                imageNumber +
                '.jpg';
            return this.path;
        },
        // gets the stored main instrument for the musician
        getMusicianType() {
            return this.musician.instruments[0];
        },
        getExperience() {
            return this.musician.experience;
        }, // form validation
    },
};
</script>

<style scoped>
.musician {
    width: 20%;
    overflow: hidden;
    margin-right: 0.5%;
    background-color: rgba(172, 78, 0, 0.4);
    text-align: center;
    padding: 2%;
    margin-top: 5%;
    border-top: #ff9f50 solid 1px;
    border-bottom: #ff9f50 solid 1px;
    -webkit-box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.4);
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.4);
}

.component-fade-enter-active,
.component-fade-leave-active {
    transform: translateX(-100%);
    transition: all 0.3s ease;
}
.component-fade-enter {
    transform: translateX(-100%);
    opacity: 0;
}
.component-fade-leave-to {
    transform: translateX(100%);
    opacity: 0;
}
.group {
    display: flex;
    flex: 1;
    justify-content: space-around;
}
.active_chip {
    color: white !important;
    background-color: #ff7700;
    box-shadow: 2px 4px 3px -3px rgb(0 0 0 / 20%),
        2px 3px 2px 2px rgb(0 0 0 / 14%), 2px 3px 3px 2px rgb(0 0 0 / 12%) !important;
}
.v-chip:not(.v-chip--active) {
    color: #999999;
}
</style>
