<template>
    <v-scroll-x-transition>
        <v-col cols="auto" class="px-0 px-sm-3">
            <v-hover v-slot="{ hover }">
                <v-card
                    class="pa-0 mx-auto white"
                    min-width="250px"
                    max-width="350px"
                    min-height="550px"
                    :elevation="hover ? 12 : 2"
                >
                    <v-img
                        height="200px"
                        :src="imageURL"
                        gradient="to bottom, rgba(255,255,255,.0), rgba(255,255,255,1)"
                    >
                        <v-app-bar flat color="rgba(0, 0, 0, 0)">
                            <v-spacer></v-spacer>
                            <v-icon color="primary" v-on:click="removeSong"
                                >mdi-close</v-icon
                            >
                        </v-app-bar>
                        <v-card-title style="margin-top: 50px">
                            <ValidationProvider
                                :rules="{
                                    required: true,
                                }"
                                v-slot="{ errors }"
                            >
                                <v-text-field
                                    class="ml-3"
                                    v-model="song.name"
                                    label="Wie heißt der Song?"
                                    maxlength="20"
                                    :counter=20
                                    hint="Erleichtert die Zuordnung"
                                    placeholder="Songtitel"
                                    :error-messages="errors[0]"
                                ></v-text-field>
                            </ValidationProvider>
                        </v-card-title>
                    </v-img>
                    <v-subheader
                        >Wie lang ist {{ song.name }}? (in Minuten)</v-subheader
                    >
                    <v-card-text>
                        <v-slider
                            v-model="song.length"
                            :max="2"
                            step="1"
                            :tick-labels="lengthLabels"
                            tick-size="4"
                            @input="emitSong"
                        ></v-slider>
                    </v-card-text>
                    <v-subheader class="pt-5"
                        >Welche Instrumente werden in dem Song gespielt?
                    </v-subheader>
                    <v-card-text>
                        <v-chip-group
                            column
                            multiple
                            mandatory
                            center
                            active-class="active_chip"
                            v-model="song.instruments"
                        >
                            <v-chip
                                small
                                v-for="instrument in bandInstruments"
                                :key="instrument"
                                :value="instrument"
                                @input="emitSong"
                            >
                                {{ instrumentAndName(instrument) }}
                            </v-chip>
                        </v-chip-group>
                    </v-card-text>
                </v-card>
            </v-hover>
        </v-col>
    </v-scroll-x-transition>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
    components: {
        ValidationProvider,
    },
    inject: ['recordingData'],
    props: {
        bandInstruments: Array,
        song: Object,
    },
    created() {
        this.imageURL =
            'https://bandrecording-hamburg.de/assets/songs/' +
            ((this.song.id % 8) + 1) + // number of files in the directory
            '.jpg';
    },
    data: function () {
        return {
            imageURL: '',
            lengthLabels: ['unter 3', '3 - 5', 'über 5'],
        };
    },
    methods: {
        emitSong() {
            this.$emit('update:song', this.song);
        },
        removeSong() {
            this.$emit('remove:song', this.song.id);
        },
        instrumentAndName(instrumentAndId) {
            /* TODO: I think i need to puke */
            let instrumentMusicianArray = instrumentAndId.split(':');
            let instrument = instrumentMusicianArray[0].trim();
            let musicianID = instrumentMusicianArray[1].trim();
            let name = this.recordingData.methods.getMusicianName(musicianID);
            return this.recordingData.instrumentDict[instrument] + ': ' + name;
        },
    },
};
</script>

<style scoped>
.song {
    width: 20%;
    overflow: hidden;
    margin-right: 0.5%;
    background-color: rgba(172, 78, 0, 0.4);
    text-align: center;
    padding: 2%;
    margin-top: 5%;
    border-top: #ff9f50 solid 1px;
    border-bottom: #ff9f50 solid 1px;
    -webkit-box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.4);
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.4);
}
.margin-correction {
    margin-left: 59px !important;
}
.active_chip {
    color: white !important;
    background-color: #ff7700;
    box-shadow: 2px 4px 3px -3px rgb(0 0 0 / 20%),
        2px 3px 4px 2px rgb(0 0 0 / 14%), 2px 3px 3px 2px rgb(0 0 0 / 12%) !important;
}
.v-chip:not(.v-chip--active) {
    color: #999999;
}
</style>
