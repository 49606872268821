<template>
    <v-col cols="auto" class="px-0 px-sm-3">
        <v-hover v-slot="{ hover }">
            <v-card
                class="pa-0 mx-auto white pb-10"
                min-width="320px"
                max-width="350px"
                height="965px"
                :elevation="hover ? 12 : 2"
            >
                <v-img
                    height="250px"
                    :class="isGrey()"
                    src="../../assets/post/mix/1.jpg"
                    :gradient="
                        'to bottom,' +
                        getColor() +
                        ', rgba(255,255,255,.2), rgba(255,255,255,1)'
                    "
                >
                    <v-card-title class="text pt-0" style="margin-top: 200px">
                        Mix
                        <v-tooltip bottom max-width="300">
                            <template #activator="{ on }">
                                <v-icon color="primary" small right v-on="on"
                                    >mdi-information-outline</v-icon
                                >
                            </template>
                            <span
                                >Der Mix ist ein wichtiger Schritt der
                                Nachbearbeitung und bestimmt den Gesamtsound.
                                Hier werden die einzelnen Instrumente bezüglich
                                Frequenzumfang und Dynamik bearbeitet, um einen
                                transparenten, druckvollen und modernen
                                Gesamtsound zu liefern.
                            </span>
                        </v-tooltip>
                        <v-switch
                            class="mx-3"
                            color="primary"
                            inset
                            :input-value="recordingData.data.isMix"
                            @click="recordingData.methods.toggleMix()"
                            :disabled="!recordingData.data.isEdit"
                        ></v-switch>
                    </v-card-title>
                </v-img>
                <v-subheader
                    >Welche Qualität soll der Mix haben?
                    <v-tooltip bottom max-width="300">
                        <template #activator="{ on }">
                            <v-icon color="primary" small right v-on="on"
                                >mdi-information-outline</v-icon
                            >
                        </template>
                        <span
                            >Hohe Mixqualität ermöglicht zusätzliche kreative
                            Effekte und Hallraumeinstellungen, die den Song
                            musikalisch unterstützen. Die Qualität des Mixes
                            wirkt sich auf den Aufwand der Nachbearbeitung und
                            somit auf die Preiskalkulation aus.
                        </span>
                    </v-tooltip>
                </v-subheader>
                <v-card-text>
                    <v-slider
                        v-model="recordingData.data.mixQuality"
                        :max="2"
                        step="1"
                        :tick-labels="qualityLabels"
                        :disabled="!recordingData.data.isMix"
                        :color="getColor()"
                        :track-color="getColor()"
                        tick-size="4"
                    ></v-slider>
                </v-card-text>
                <v-subheader
                    >Wie viele Revisionen sollen eingeplant werden?
                    <v-tooltip bottom max-width="300">
                        <template #activator="{ on }">
                            <v-icon color="primary" small right v-on="on"
                                >mdi-information-outline</v-icon
                            >
                        </template>
                        <span
                            >Nach Fertigstellung des Mixes hast Du die
                            Möglichkeit, Feedback und Änderungswünsche zu
                            formulieren, die daraufhin in einer kostenlosen
                            Revision eingearbeitet werden. Weitere Revisionen
                            führen zu einem Preisaufschlag und können hier
                            direkt mit eingeplant werden.</span
                        >
                    </v-tooltip>
                </v-subheader>
                <v-card-text class="pt-6">
                    <v-slider
                        v-model="recordingData.data.revisions"
                        :max="recordingData.minMaxValues.maxRevisions"
                        :min="recordingData.minMaxValues.minRevisions"
                        thumb-label="always"
                        :disabled="!recordingData.data.isMix"
                        step="1"
                        :thumb-size="28"
                        class="pl-1"
                    ></v-slider>
                </v-card-text>
                <v-divider class="mt-3"></v-divider>
                <v-card-text>
                    <v-row class="" justify="center">
                        <v-card-subtitle class="pt-0"
                            ><p
                                class="text-h6 text-center mb-1 mt-0"
                                :class="
                                    (recordingData.data.isEdit
                                        ? 'black'
                                        : 'disabled') + '--text'
                                "
                            >
                                {{ qualityName }}
                            </p>
                            <p
                                class="text-subtitle-2 text-center mb-0"
                                :class="
                                    (recordingData.data.isEdit
                                        ? 'black'
                                        : 'disabled') + '--text'
                                "
                            >
                                Mix-Zeit pro Song:
                                {{ qualityTime }}
                                {{
                                    Math.ceil(
                                        mixTime /
                                            recordingData.data.numberOfSongs
                                    )
                                }}
                                {{
                                    Math.ceil(
                                        mixTime /
                                            recordingData.data.numberOfSongs
                                    ) != 1
                                        ? 'Stunden'
                                        : 'Stunde'
                                }}
                            </p>
                        </v-card-subtitle>
                        <v-list-item
                            class="py-0 my-0"
                            dense
                            style="min-height: 20px"
                            v-for="(item, i) in bulletpoints"
                            :key="i"
                        >
                            <v-list-item-icon class="mr-0">
                                <v-icon
                                    class="pr-2"
                                    v-text="
                                        recordingData.data.isMix
                                            ? item.icon
                                            : 'mdi-close-thick'
                                    "
                                    :color="
                                        recordingData.data.isMix
                                            ? item.color
                                            : 'disabled'
                                    "
                                    small
                                ></v-icon
                            ></v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title
                                    v-text="item.text"
                                    :class="
                                        (recordingData.data.isMix
                                            ? item.textcolor
                                            : 'disabled') + '--text'
                                    "
                                >
                                </v-list-item-title>
                            </v-list-item-content>
                            <v-tooltip v-if="item.tooltip" left max-width="300">
                                <template #activator="{ on }">
                                    <v-icon
                                        :color="
                                            recordingData.data.isMix
                                                ? item.color
                                                : 'disabled'
                                        "
                                        small
                                        left
                                        v-on="on"
                                        >mdi-information-outline</v-icon
                                    >
                                </template>
                                <span v-text="item.tooltip"> </span>
                            </v-tooltip>
                        </v-list-item>
                    </v-row>
                </v-card-text>
                <v-fade-transition>
                    <v-overlay
                        v-if="hover && !recordingData.data.isEdit"
                        absolute
                        z-index="1"
                        color="primary"
                    >
                        <v-card class="mx-4 white">
                            <v-card-text class="black--text text-center">
                                Um Mix auswählen zu können muss Edit gewählt
                                sein.
                            </v-card-text>
                        </v-card>
                    </v-overlay>
                </v-fade-transition>
            </v-card>
        </v-hover>
    </v-col>
</template>

<script>
export default {
    data: function () {
        return {
            whiteColor: 'rgba(255, 255, 255, 1)',
            goldColor: 'rgba(226, 176, 7, 1)',
            platinColor: 'rgba(149, 154, 164, 1)',
            diamondColor: 'rgba(154, 197, 219)',
        };
    },
    inject: ['recordingData', 'configuratorLogic'],
    props: { qualityLabels: Array },
    methods: {
        getColor() {
            if (this.recordingData.data.isMix) {
                if (this.recordingData.data.mixQuality == 0) {
                    return this.goldColor;
                } else if (this.recordingData.data.mixQuality == 1) {
                    return this.platinColor;
                } else {
                    return this.diamondColor;
                }
            } else {
                return this.whiteColor;
            }
        },
        isGrey() {
            if (this.recordingData.data.isMix) {
                return '';
            } else {
                return 'greyscale';
            }
        },
    },
    computed: {
        bulletpoints: function () {
            let quality = this.recordingData.data.mixQuality;
            return [
                {
                    text: ' Lautstärke-Leveling',
                    icon: 'mdi-check-bold',
                    color: 'primary',
                    textcolor: 'black',
                    tooltip:
                        'Bei diesem Bearbeitungsschritt wird die Lautstärke der einzelnen Instrumente in ein ausgeglichenes Verhältnis gesetzt.',
                },
                {
                    text: 'Frequenzkorrektur',
                    icon: 'mdi-check-bold',
                    color: 'primary',
                    textcolor: 'black',
                    tooltip:
                        'Bei diesem Bearbeitungsschritt werden störende und sich überlagernde Frequenzen der Instrumente analysiert und entfernt.',
                },
                {
                    text: 'Dynamikbearbeitung',
                    icon: 'mdi-check-bold',
                    color: 'primary',
                    textcolor: 'black',
                    tooltip:
                        'Bei diesem Bearbeitungsschritt wird die Dynamik der einzelnen Instrumente angepasst.',
                },
                {
                    text: 'Referenzmix',
                    icon: quality == 0 ? 'mdi-close-thick' : 'mdi-check-bold',
                    color: quality == 0 ? 'disabled' : 'primary',
                    textcolor: quality == 0 ? 'disabled' : 'black',
                    tooltip:
                        'Bei diesem Bearbeitungsschritt wird der Sound des Mixes an einen vorher definierten Referenzsong angeglichen. Es wird dabei ein ähnliches Klangbild generiert.',
                },
                {
                    text: 'Effekteinsatz',
                    icon: quality == 0 ? 'mdi-close-thick' : 'mdi-check-bold',
                    color: quality == 0 ? 'disabled' : 'primary',
                    textcolor: quality == 0 ? 'disabled' : 'black',
                    tooltip:
                        'Bei diesem Bearbeitungsschritt werden kreative Effekte wie z.B. Reverb, Distortion und Delay musikalisch in den Mix integriert.',
                },
                {
                    text: 'Kreative Frequenzbearbeitung',
                    icon: quality <= 1 ? 'mdi-close-thick' : 'mdi-check-bold',
                    color: quality <= 1 ? 'disabled' : 'primary',
                    textcolor: quality <= 1 ? 'disabled' : 'black',
                    tooltip:
                        'Bei diesem Bearbeitungsschritt werden die charakteristischen Frequenzbereiche der einzelnen Instrumente hevorgehoben und musikalisch unterstützt.',
                },
                {
                    text: 'Musikalische Feinabstimmung',
                    icon: quality <= 1 ? 'mdi-close-thick' : 'mdi-check-bold',
                    color: quality <= 1 ? 'disabled' : 'primary',
                    textcolor: quality <= 1 ? 'disabled' : 'black',
                    tooltip:
                        'Bei diesem Bearbeitungsschritt werden Hallräume, Delayzeiten sowie EQ- und Dynamikparameter erneut überarbeitet und individuell auf einzelne Songs abgestimmt. Dadurch wird die Breiten- und Tiefenstaffelung des Mixes optimiert.',
                },
                {
                    text: 'Automation',
                    icon: quality <= 1 ? 'mdi-close-thick' : 'mdi-check-bold',
                    color: quality <= 1 ? 'disabled' : 'primary',
                    textcolor: quality <= 1 ? 'disabled' : 'black',
                    tooltip:
                        'Bei diesem Bearbeitungsschritt erfolgt eine dynamische Anpassung der Bearbeitungsparameter (z.B. Lautstärke und Panorama) im Zeitverlauf. Dadurch wird der Mix lebendiger.',
                },
            ];
        },
        qualityTime: function () {
            if (this.recordingData.data.mixQuality === 0) {
                return 'max. ';
            } else if (this.recordingData.data.mixQuality === 1) {
                return 'ca. ';
            } else {
                return 'mind. ';
            }
        },
        qualityName: function () {
            if (this.recordingData.data.mixQuality === 0) {
                return 'Dein Gold-Mix';
            } else if (this.recordingData.data.mixQuality === 1) {
                return 'Dein Platin-Mix';
            } else {
                return 'Dein Diamond-Mix';
            }
        },
        mixTime: function () {
            return this.configuratorLogic.methods
                .calculateMixTime(
                    this.recordingData.data.isMix,
                    this.recordingData.data.songs,
                    this.recordingData.data.recordingType,
                    this.recordingData.data.musicians,
                    this.recordingData.data.mixQuality
                )
                .toPrecision(2);
        },
    },
};
</script>

<style>
.greyscale {
    filter: gray; /* IE6-9 */
    -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
    filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
}
</style>
