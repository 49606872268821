<template id="back_button-tmpl">
    <v-btn
        outlined
        color="primary"
        elevation="5"
        x-large
        class="mx-auto"
        style="background-color: white"
        @click="onSubmit"
    >
        Zurück
    </v-btn>
</template>

<script>
export default {
    inject: ['configuratorState'],
    methods: {
        onSubmit() {
            this.$emit('validateBack');
        },
    },
};
</script>
