var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"px-0 px-sm-3",attrs:{"cols":"auto"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"pa-0 mx-auto white",attrs:{"min-width":"320px","max-width":"350px","height":"965px","elevation":hover ? 12 : 2}},[_c('v-img',{class:_vm.isGrey(),attrs:{"height":"250px","src":require("../../assets/post/master/1.jpg"),"gradient":'to bottom,' +
                    _vm.getColor() +
                    ', rgba(255,255,255,.2), rgba(255,255,255,1)'}},[_c('v-card-title',{staticClass:"text",staticStyle:{"margin-top":"180px"}},[_vm._v(" Master "),_c('v-tooltip',{attrs:{"bottom":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"primary","small":"","right":""}},on),[_vm._v("mdi-information-outline ")])]}}],null,true)},[_c('span',[_vm._v("Das Mastering ist der finale Schritt der Nachbearbeitung. Hierbei wird für eine gleichmäßige Lautheit aller Aufnahmen gesorgt, um den Standards der Musikindustrie gerecht zu werden. Dazu werden mithilfe hochwertigen Studioequipments alle Mixe im Frequenzspektrum sowie in der Dynanik angepasst. Außerdem werden Übergänge zwischen den Songs optimiert und die Produktion nach Wunsch in verschiedene Formate konvertiert.")])]),_c('v-switch',{staticClass:"mx-3",attrs:{"color":"primary","input-value":_vm.recordingData.data.isMaster,"disabled":!_vm.recordingData.data.isMix,"inset":""},on:{"click":function($event){return _vm.recordingData.methods.toggleMaster()}}})],1)],1),_c('v-card-text',{staticClass:"pt-2 pb-0"},[_c('v-row',[_c('v-switch',{staticClass:"pl-1",attrs:{"disabled":!_vm.recordingData.data.isMaster,"label":'Stem Mastering'},model:{value:(_vm.recordingData.data.isStem),callback:function ($$v) {_vm.$set(_vm.recordingData.data, "isStem", $$v)},expression:"recordingData.data.isStem"}}),_c('v-tooltip',{attrs:{"bottom":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"primary","small":"","right":""}},on),[_vm._v("mdi-information-outline ")])]}}],null,true)},[_c('span',[_vm._v("Beim Stem Mastering wird nicht der Mix als Ganzes, sondern in Instrumenten- gruppen (Stems) bearbeitet. Mit dieser Methode hat das Studio vielseitigere Möglichkeiten auf die einzelnen Instrumentengruppen einzugehen und diese intensiver zu bearbeiten. In der Regel wird kein Stem Mastering benötigt. Es wird empfohlen diese Einstellung nur zu wählen, wenn sie explizit erforderlich ist. ")])])],1)],1),_c('v-subheader',[_vm._v("Anzahl Stem-Mastering-Spuren: ")]),_c('v-card-text',{staticClass:"pt-6 pb-0"},[_c('v-slider',{staticClass:"pl-1",attrs:{"max":_vm.recordingData.minMaxValues.maxStems,"min":_vm.recordingData.minMaxValues.minStems,"step":"1","thumb-label":"always","thumb-size":28,"disabled":!_vm.recordingData.data.isMaster ||
                        !_vm.recordingData.data.isStem},model:{value:(_vm.recordingData.data.stems),callback:function ($$v) {_vm.$set(_vm.recordingData.data, "stems", $$v)},expression:"recordingData.data.stems"}})],1),_c('v-card-text',{staticStyle:{"padding-top":"6px"}},[_c('v-row',[_c('v-switch',{staticClass:"pl-1",attrs:{"disabled":!_vm.recordingData.data.isMaster,"label":'DDP Mastering'},model:{value:(_vm.recordingData.data.ddp),callback:function ($$v) {_vm.$set(_vm.recordingData.data, "ddp", $$v)},expression:"recordingData.data.ddp"}}),_c('v-tooltip',{attrs:{"bottom":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"primary","small":"","right":""}},on),[_vm._v("mdi-information-outline ")])]}}],null,true)},[_c('span',[_vm._v("DDP ist ein Format, welches alle Informationen Deiner Produktion in einem Paket bündelt. Presswerke setzen dies häufig voraus, um die Musikdaten, Metadaten, Pausenlängen etc. in einem Paket zu erhalten. Es wird empfohlen diese Einstellung nur zu wählen, wenn sie explizit erforderlich ist.")])])],1)],1),_c('v-divider'),_c('v-card-text',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-card-subtitle',{staticClass:"pt-0 mb-5"},[_c('p',{staticClass:"text-h6 text-center mb-1 mt-0",class:(_vm.recordingData.data.isMaster
                                    ? 'black'
                                    : 'disabled') + '--text'},[_vm._v(" Deine Master-Konfiguration ")])]),_vm._l((_vm.bulletpoints),function(item,i){return _c('v-list-item',{key:i,staticClass:"py-0 my-0",staticStyle:{"min-height":"20px"},attrs:{"dense":""}},[_c('v-list-item-icon',{staticClass:"mr-0"},[_c('v-icon',{staticClass:"pr-2",attrs:{"color":_vm.recordingData.data.isMaster
                                        ? item.color
                                        : 'disabled',"small":""},domProps:{"textContent":_vm._s(
                                    _vm.recordingData.data.isMaster
                                        ? item.icon
                                        : 'mdi-close-thick'
                                )}})],1),_c('v-list-item-content',[_c('v-list-item-title',{class:(_vm.recordingData.data.isMaster
                                        ? item.textcolor
                                        : 'disabled') + '--text',domProps:{"textContent":_vm._s(item.text)}})],1),(item.tooltip)?_c('v-tooltip',{attrs:{"left":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                        var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":_vm.recordingData.data.isMaster
                                            ? item.color
                                            : 'disabled',"small":"","left":""}},on),[_vm._v("mdi-information-outline")])]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(item.tooltip)}})]):_vm._e()],1)})],2)],1),_c('v-fade-transition',[(hover && !_vm.recordingData.data.isMix)?_c('v-overlay',{attrs:{"absolute":"","z-index":"1","color":"primary"}},[_c('v-card',{staticClass:"mx-4 white"},[_c('v-card-text',{staticClass:"black--text text-center"},[_vm._v(" Um Master auswählen zu können muss Mix gewählt sein. ")])],1)],1):_vm._e()],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }