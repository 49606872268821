<template id="back_button-tmpl">
    <v-btn
        color="primary"
        elevation="5"
        x-large
        class="mx-auto"
        @click="onSubmit"
    >
        Übersicht
    </v-btn>
</template>

<script>
export default {
    inject: ['configuratorState'],
    methods: {
        onSubmit() {
            this.$emit('validateSummary');
        },
    },
};
</script>
