// Base Prices
const data = {
    highDiscountLimit: 7500,
    mediumDiscountLimit: 5000,
    lowDiscountLimit: 2000,
    highDiscount: 0.85,
    mediumDiscount: 0.9,
    lowDiscount: 0.95,

    recordingDay: 490,
    mixDay: 350,
    producerDay: 200,
    assistantDay: 200,
    recordingHour: 61.25,
    mixHour: 50,
    editHour: 50,
    producerHour: 25,
    standardMaster: 75,
    stemMaster: 15,
    ddpMaster: 65,
    ddpMasterSong: 5,
    revision: 50,
    melodyne: 35,
};

export default {
    data,
};
