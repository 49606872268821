// Factors
const data = {
    editGoldOverdub: 0.75,
    editPlatinOverdub: 1.0,
    editDiamondOverdub: 1.4,
    editLowXPOverdub: 1.25,
    editMediumXPOverdub: 1.0,
    editHighXPOverdub: 0.8,

    editGoldLive: 0.77,
    editPlatinLive: 1.0,
    editDiamondLive: 1.65,
    editLowXPLive: 1.3,
    editMediumXPLive: 1.0,
    editHighXPLive: 0.7,

    mixGoldOverdub: 0.7,
    mixPlatinOverdub: 1.0,
    mixDiamondOverdub: 1.3,
    mixLowXPOverdub: 1.2,
    mixMediumXPOverdub: 1.0,
    mixHighXPOverdub: 0.9,

    mixGoldLive: 0.65,
    mixPlatinLive: 1.0,
    mixDiamondLive: 1.27,
    mixLowXPLive: 1.1,
    mixMediumXPLive: 1.0,
    mixHighXPLive: 0.9,

    songLengthRecording0: 0.85,
    songLengthRecording1: 1.0,
    songLengthRecording2: 1.3,

    songLengthPostOverdubShort: 0.8,
    songLengthPostOverdubNormal: 1.0,
    songLengthPostOverdubLong: 1.4,

    songLengthPostLiveShort: 0.8,
    songLengthPostLiveNormal: 1.0,
    songLengthPostLiveLong: 1.3,

    day: 1.0,
    night: 0.75,
};

export default { data };
