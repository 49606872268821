<template>
    <v-container fluid class="py-4 px-0 px-sm-4 my-auto">
        <v-card dir style="margin: auto" min-width="250px" max-width="730px">
            <v-card-title class="justify-center text-h5"
                >Art der Aufnahme</v-card-title
            >
            <v-card-text class="text-left subtitle-2"
                >Im ersten Schritt gilt es die Art der Aufnahme auszuwählen. Sie
                bestimmt das Vorgehen der gesamten Produktion. Überlege also
                gut, welche Variante am Besten zu Eurem Bandrecording passt.
            </v-card-text>
        </v-card>
        <v-container class="mx-auto px-0 px-sm-4">
            <v-row align="center" justify="center">
                <v-col
                    class="px-0 px-sm-3"
                    cols="auto"
                    v-for="(type, index) in types"
                    :key="index"
                >
                    <v-hover v-slot="{ hover }">
                        <v-card
                            class="pa-0 mx-auto white"
                            :class="
                                (type.name === recordingData.data.recordingType
                                    ? 'selection'
                                    : 'white') +
                                (index < 1
                                    ? ' ml-sm-auto mr-sm-1 mx-auto'
                                    : ' mr-sm-auto ml-sm-1 mx-auto')
                            "
                            :elevation="
                                type.name === recordingData.data.recordingType
                                    ? '24'
                                    : hover
                                    ? '12'
                                    : '1'
                            "
                            min-width="250px"
                            max-width="350px"
                            @click="setRecordingType(type.name)"
                        >
                            <v-img
                                height="300px"
                                :src="type.picture"
                                gradient="to bottom, rgba(255,255,255,.0), rgba(255,255,255,1) "
                            >
                                <v-app-bar flat color="rgba(0, 0, 0, 0)">
                                    <div
                                        :class="
                                            type.name ===
                                            recordingData.data.recordingType
                                                ? 'primary'
                                                : 'white'
                                        "
                                    ></div>
                                </v-app-bar>
                            </v-img>
                            <v-subheader>
                                <span class="text-h5 pt-4 d-inline-block"
                                    >{{ type.name }} Recording
                                </span>
                            </v-subheader>
                            <v-card-text>
                                <span> {{ type.description }} </span>
                            </v-card-text>
                            <v-row align="center" justify="center" class="py-4">
                                <v-btn
                                    color="primary"
                                    elevation="0"
                                    class="mx-auto"
                                >
                                    Auswählen
                                </v-btn>
                            </v-row>
                        </v-card>
                    </v-hover>
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>

<script>
export default {
    inject: ['configuratorState', 'recordingData'],
    created() {
        this.$emit('change:background');
        window.scrollTo(0, 0);
    },
    data: function () {
        return {
            types: [
                {
                    name: 'Live',
                    description:
                        'Beim Live Recording werden alle Musiker gemeinsam aufgenommen. Dadurch wird eine bessere Dynamik und Energie der Band eingefangen. Um bei dieser Art der Aufnahme das Optimum herauszuholen, sollte die Band gut eingespielt sein. Aufgrund der simultanen Aufnahme aller Instrumente ist eine Nachbearbeitung hier nur eingeschränkt möglich.',
                    picture:
                        'https://bandrecording-hamburg.de/assets/recordingtype/live.jpg',
                },
                {
                    name: 'Overdub',
                    description:
                        'Beim Overdub Recording werden alle Musiker nacheinander aufgenommen. Dadurch ist ein besserer Fokus auf einzelne Instrumente möglich. Kleinere Fehler eines Musikers können hier leichter ausgeglichen werden, da in der Nachbearbeitung individuelle Korrekturen möglich sind. Für eine radiogeeignete Qualität ist diese Art der Aufnahme daher empfehlenswert. ',
                    picture:
                        'https://bandrecording-hamburg.de/assets/recordingtype/overdub.jpg',
                },
            ],
        };
    },
    methods: {
        setRecordingType(type) {
            this.recordingData.data.recordingType = type;
            this.configuratorState.methods.goNext();
        },
    },
};
</script>

<style scoped>
.v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    position: absolute;
    width: 100%;
}
</style>
