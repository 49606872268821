var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-4 mt-auto contact px-0 px-sm-4",style:({
        backgroundImage: _vm.backgroundRender,
    }),attrs:{"fluid":""}},[_c('h1',{staticClass:"text-center text-sm-h4 text-h5 mt-12 mb-8"},[_vm._v(" Du hast Fragen? Schreib uns einfach... ")]),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[(!_vm.messageSent)?_c('v-card',{staticClass:"pa-0 mx-auto",attrs:{"max-width":"800px","min-width":"250px"}},[_c('v-img',{attrs:{"height":"30vh","src":require("../assets/bg.jpg"),"gradient":"to bottom, rgba(255,255,255,.0), rgba(255,255,255,1)"}}),_c('v-row',{staticClass:"pa-5",attrs:{"dense":"","justify":"space-between"}},[_c('v-col',[_c('validation-provider',{attrs:{"name":"Name","rules":"required|max:30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":30,"error-messages":errors,"label":"Name","required":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"E-mail","required":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Kommentar","rules":"required|max:600"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('v-textarea',{attrs:{"counter":600,"error-messages":errors,"label":"Kommentar","required":""},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}})]}}],null,true)})],1)],1)],1):_vm._e(),(!_vm.messageSent)?_c('v-container',{staticClass:"mt-4",staticStyle:{"justify-content":"center","display":"flex"}},[_c('v-btn',{attrs:{"color":"primary","type":"submit","large":"","disabled":invalid}},[_vm._v(" Absenden ")])],1):_vm._e()],1),(_vm.messageSent)?_c('v-card',{staticClass:"pa-0 mx-auto",attrs:{"max-width":"800px","min-width":"250px"}},[_c('v-simple-table',{staticClass:"mt-6"},[_c('thead',{staticStyle:{"background-color":"#64b450"}},[_c('tr',[_c('th',[_c('h4',{staticClass:"title text-center font-weight-bold",staticStyle:{"color":"white"}},[_vm._v(" Vielen Dank! Deine Nachricht wurde erfolgreich verschickt. ")])])])])])],1):_vm._e()]}}])}),_c('v-container',{staticClass:"mt-4",staticStyle:{"justify-content":"center","display":"flex"}},[(_vm.messageSent)?_c('HomeButtonComponent'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }