<template>
    <v-footer color="primary">
        <v-row justify="center" class="pa-0">
            <v-btn
                v-for="page in pages"
                :key="page.title"
                :to="page.to"
                color="white"
                text
                rounded
                class="my-2"
            >
                {{ page.title }}
            </v-btn>
            <v-col class="primary py-4 text-center white--text" cols="12">
                {{ new Date().getFullYear() }} —
                <strong>Bandrecording Hamburg</strong>
            </v-col>
        </v-row>
    </v-footer>
</template>

<script>
export default {
    data: () => ({
        pages: [
            { title: 'Home', icon: 'mdi-home', to: '/' },
            { title: 'Configurator', icon: 'mdi-tune', to: '/configurator' },
            { title: 'Impressum', icon: 'mdi-file-document', to: '/imprint' },
            { title: 'Datenschutz', icon: 'mdi-account-lock', to: '/privacy' },
            { title: 'Kontakt', icon: 'mdi-email', to: '/contact' },
        ],
    }),
};
</script>
