<template>
    <v-card class="pa-0 mx-auto" style="width: 100%">
        <v-list dense class="mt-6">
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-subtitle class="text-h6"
                        >Preisübersicht</v-list-item-subtitle
                    >
                </v-list-item-content>
            </v-list-item>
            <v-divider> </v-divider>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-subtitle class="text-subtitle-1"
                        >Aufnahme</v-list-item-subtitle
                    >
                </v-list-item-content>
                <v-list-item-content>
                    <v-list-item-subtitle class="subtitle-1 text-right">
                        {{
                            currency.format(recordingData.prices.recordingPrice)
                        }}
                    </v-list-item-subtitle></v-list-item-content
                >
            </v-list-item>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-subtitle class="text-subtitle-1"
                        >Nachbearbeitung</v-list-item-subtitle
                    >
                </v-list-item-content>
                <v-list-item-content>
                    <v-list-item-subtitle class="subtitle-1 text-right">
                        {{ currency.format(recordingData.prices.postPrice) }}
                    </v-list-item-subtitle></v-list-item-content
                >
            </v-list-item>
            <v-list-item v-if="recordingData.prices.discount !== 0">
                <v-list-item-content>
                    <v-list-item-subtitle class="text-subtitle-1">
                        Rabatt
                        <v-tooltip bottom max-width="300">
                            <template #activator="{ on }">
                                <v-icon color="primary" small v-on="on"
                                    >mdi-information-outline-outline</v-icon
                                >
                            </template>
                            <span
                                ><b>Rabatte:</b><br />
                                Ab 2.000,- Netto Euro gibt es 5% Rabatt.<br />
                                Ab 5.000,- Netto Euro gibt es 10% Rabatt.<br />
                                Ab 7.500,- Netto Euro gibt es 15% Rabatt.<br />
                            </span>
                        </v-tooltip>
                    </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content>
                    <v-list-item-subtitle class="subtitle-1 text-right">
                        -{{ currency.format(recordingData.prices.discount) }}
                    </v-list-item-subtitle></v-list-item-content
                >
            </v-list-item>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-subtitle class="text-subtitle-1">
                        Nettopreis</v-list-item-subtitle
                    >
                </v-list-item-content>
                <v-list-item-content>
                    <v-list-item-subtitle class="subtitle-1 text-right">
                        {{
                            currency.format(recordingData.prices.discountPrice)
                        }}
                    </v-list-item-subtitle></v-list-item-content
                >
            </v-list-item>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-subtitle class="text-subtitle-1">
                        Mehrwertsteuer (19%)</v-list-item-subtitle
                    >
                </v-list-item-content>
                <v-list-item-content>
                    <v-list-item-subtitle class="subtitle-1 text-right">
                        {{ currency.format(recordingData.prices.mwst) }}
                    </v-list-item-subtitle></v-list-item-content
                >
            </v-list-item>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title class="text-subtitle-1">
                        Gesamtpreis</v-list-item-title
                    >
                </v-list-item-content>
                <v-list-item-content>
                    <v-list-item-title class="subtitle-1 text-right">
                        {{ currency.format(recordingData.prices.bruttoPrice) }}
                    </v-list-item-title></v-list-item-content
                >
            </v-list-item>
        </v-list>
    </v-card>
</template>
<script>
export default {
    inject: ['recordingData'],
    data: () => ({
        currency: new Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency: 'EUR',
        }),
    }),
};
</script>
