<template>
    <v-card class="pa-0 mx-auto">
        <v-img
            height="30vh"
            src="../../../assets/bg.jpg"
            gradient="to bottom, rgba(255,255,255,.0), rgba(255,255,255,1)"
        >
        </v-img>
        <v-row dense justify="space-between"
            ><v-col :cols="$vuetify.breakpoint.xsOnly ? '10' : 'auto'">
                <v-subheader class="title">Zusatzoptionen:</v-subheader>
            </v-col>

            <v-col cols="auto">
                <v-subheader class="text-sm-body-1 text-xs-caption">
                    Producer Support
                    <v-tooltip bottom max-width="300">
                        <template #activator="{ on }">
                            <v-icon color="primary" small right v-on="on"
                                >mdi-information-outline</v-icon
                            >
                        </template>
                        <span>
                            Hierbei unterstützt Euch zusätzlich zum Techniker
                            ein Produzent bei der Aufnahme. Dieser kann mit
                            seiner langjährigen Erfahrung die musikalische
                            Komposition eines Songs professionell unterstützen,
                            wie z.B. die Songstruktur oder Instrumentenauswahl
                            zu überprüfen. Außerdem steht er Euch bei der Wahl
                            einer geeigneten Aufnahmetechnik sowie als kreative
                            Unterstützung beim gesamten Aufnahmeprozess zur
                            Seite.
                        </span>
                    </v-tooltip>
                    <v-switch
                        class="pl-3"
                        color="primary"
                        inset
                        dense
                        :input-value="recordingData.data.isProducer"
                        @click="recordingData.methods.toggleProducer()"
                    ></v-switch>
                </v-subheader>
            </v-col>

            <v-col cols="auto">
                <v-subheader class="text-sm-body-1 text-xs-caption">
                    Flex Discount
                    <v-tooltip bottom max-width="300">
                        <template #activator="{ on }">
                            <v-icon color="primary" small right v-on="on"
                                >mdi-information-outline</v-icon
                            >
                        </template>
                        <span
                            >Mit dieser Option ermöglicht Ihr es dem Studio die
                            Aufnahmetermine flexibler zu planen und Euch
                            womöglich auch mal am späten Abend oder in der Nacht
                            aufzunehmen. Durch diese Flexibilität kann das
                            Studio eine bessere Auslastung erreichen und Euch
                            einen Rabatt gewähren.</span
                        >
                    </v-tooltip>
                    <v-switch
                        color="primary"
                        class="pl-3"
                        inset
                        dense
                        :input-value="recordingData.data.isNight"
                        @click="recordingData.methods.toggleNight()"
                    ></v-switch>
                </v-subheader>
            </v-col>
        </v-row>
    </v-card>
</template>
<script>
export default {
    inject: ['recordingData'],
};
</script>
